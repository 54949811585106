import { styled } from '@mui/system'
import { observer } from 'mobx-react-lite'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { ThemeProvider, Stack, Box } from '@mui/material'
import { storeElement } from 'GlobalStores/StoreElement'
import CardMenu from 'Components/Elements/UserAccountMenu/CardMenu'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { useEffect } from 'react'
import Breadcrumb from 'Components/Elements/UserAccountMenu/Breadcrumb'
import { useSizeWindow } from './../../Helpers/GlobalHelpers'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import { IconSpinner } from 'Components/Elements/Spinner/IconSpinner'
import { storeSettingsElement } from 'GlobalStores/Settings/StoreSettingsElement'
import { reaction, runInAction } from 'mobx'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { ThailandTemplate } from 'Components/Template/ThailandTemplate'
import ReferralLoginWrapper from './Referral/ReferralLoginWrapper'

const UserAccount = observer(props => {
    const { width } = useSizeWindow()

    useEffect(() => {
        runInAction(() => {
            if (width <= 834) {
                storeSettingsElement.cardTitle.titleStep1 = ''
            }
        })

        if (storeCountry.Country3() === 'THA') {
            setFontFamily(StoreTranslate.CurrentLanguage())
        }

        const disposerLanguageAxios = reaction(
            () => StoreTranslate.CurrentLanguage(),
            (n, p) => {
                if (n !== p) {
                    if (storeCountry.Country3() === 'THA') {
                        setFontFamily(n)
                    }
                }
            }
        )

        storeElement.theme = ThailandTemplate

        return () => {
            disposerLanguageAxios()
        }
    }, [])

    const setFontFamily = currentLang => {
        MainTemplate.typography.fontFamily = currentLang === 'EN' ? 'Poppins!important' : 'NotoSansThai!important'
        Object.keys(MainTemplate.typography).map((key, index) => {
            // MainTemplate.typography[key].fontFamily = n === 'EN' ? 'Poppins' : 'NotoSansThai'

            if (MainTemplate.typography[key].fontFamily) {
                MainTemplate.typography[key].fontFamily = currentLang === 'EN' ? 'Poppins!important' : 'NotoSansThai!important'
            }
        })
        if (MainTemplate.overrides) {
            MainTemplate.overrides.MuiCssBaseline['@global'].body.fontFamily = currentLang === 'EN' ? 'Poppins!important' : 'NotoSansThai!important'
        } else {
            MainTemplate.overrides = {
                MuiCssBaseline: {
                    '@global': {
                        body: {}
                    }
                }
            }
            MainTemplate.overrides.MuiCssBaseline['@global'].body.fontFamily = currentLang === 'EN' ? 'Poppins!important' : 'NotoSansThai!important'
        }
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            <ReferralLoginWrapper>
                {StoreAuth.IsAuthorized() === true ? (
                    <UserAccountWrapper>
                        <Stack height={'100%'} sx={{ flex: 1 }}>
                            <Breadcrumb display={width <= 834 ? 'none' : ''} />
                            <BlockWrapper flexDirection={'row'} gap={width > 834 ? '30px' : ''} mt={width > 834 ? '25px' : ''}>
                                <CardMenu />
                                <Stack gap="20px">
                                    <UserChildLayoutStyled
                                        className={storeElement.userLayout.className || storeSettingsElement.userLayout.className || ''}
                                        height={/invite|setting/.test(window.location.pathname) ? 'fit-content' : '100%'}>
                                        {props.children}
                                    </UserChildLayoutStyled>
                                    <UserChildLayoutStyled>
                                        {props.secondChildren}
                                    </UserChildLayoutStyled>
                                    <UserChildLayoutStyled>
                                        {props.thirdChildren}
                                    </UserChildLayoutStyled>
                                </Stack>
                            </BlockWrapper>
                        </Stack>
                    </UserAccountWrapper>
                ) : (
                    <Stack minHeight={'90vh'} justifyContent={'center'} alignItems={'center'}>
                        <IconSpinner />
                    </Stack>
                )}
            </ReferralLoginWrapper>
        </ThemeProvider>
    )
})

const BlockWrapper = styled(Stack)`
    display: flex;
    flex-direction: column;
    flex: 1;

    @media screen and (min-width: 900px) {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }

    // @media screen and (min-width: 1024px) {
    //     display: grid;
    //     grid-template-columns: 430px 2fr;
    // }

    @media screen and (min-width: 1366px) {
        display: grid;
        grid-template-columns: 430px 860px;
    }
`

const UserAccountWrapper = styled(Stack)`
    background: #d1d9e2;
    min-height: calc(100vh - (74px));

    @media screen and (min-width: 1024px) {
        padding: 30px 30px 40px 30px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
`

const UserChildLayoutStyled = styled(Stack)`
    position: relative;
    display: none;
    background-color: #ffffff;
    border-radius: 12px;
    width: 100%;

    &.feelgreat--orderhistory {
        background-color: transparent;
        box-shadow: none;
        padding: 0;
    }

    @media screen and (min-width: 900px) {
        display: block;
    }
`

export default UserAccount
