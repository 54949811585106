import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import StorePaymentStep from 'GlobalStores/Cores/Elements/StorePaymentStep'
import Email from 'GlobalStores/Email'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import StoreUser from 'GlobalStores/User/StoreUser'
import { get } from 'lodash'
import { makeAutoObservable, toJS, transaction } from 'mobx'
import HttpHelper from 'Services/HttpHelper'
// import Email from './Emails/Email'
import { PaymentAPIs } from './PaymentServices/PaymentAPI'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import storeReferralCheckout from '../StoreReferralCheckout'
import storeUser from 'GlobalStores/User/StoreUser'
import storeFormat from 'GlobalStores/Format/StoreFormat'

const HYDRA_URL = /localhost/.test(window.location.href) ? 'https://hydra.unicity.net/v5a' : 'https://hydra.unicity.net/v5a'
const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

class Payment {
    uuid = ''
    products = null
    detailPage = null

    hydra = null
    lambda = null
    paymentId = null
    ushop = null
    email = null

    ushopTHToken = null

    autoship = null

    newBaId = ''

    PaymentStep = null

    orderDataToUrl = ''
    enrollId = ''

    referralId = '108357166'

    paymentCybersourceData = 'test'

    emailConfigs = {
        from: 'Unicity <no-reply@unicity.com>',
        subject: 'Order confirmation',
        subjectAutoship: 'Autoship confirmation',
        emailTemplate: 'u22_general_shopping',
        AUS: {
            emailTemplate: 'feelgreat_email_aus',
            emailAutoshipTemplate: 'feelgreat_email_autoship_aus',
            csEmail: /localhost/.test(window.location.href) ? 'khanitnan.jansit@unicity.com' : 'cs.aus@unicity.com',
            templateData: {},
            languages: []
        },
        SGP: {
            emailTemplate: 'feelgreat_email_aus',
            emailAutoshipTemplate: 'feelgreat_email_autoship_aus',
            csEmail: /localhost/.test(window.location.href) ? 'khanitnan.jansit@unicity.com' : 'contact.sg@unicity.com',
            templateData: {},
            languages: []
        },
        MYS: {
            emailTemplate: 'feelgreat_email_aus',
            emailAutoshipTemplate: 'feelgreat_email_autoship_aus',
            csEmail: /localhost/.test(window.location.href) ? 'khanitnan.jansit@unicity.com' : 'malaysia.customerservice@unicity.com',
            templateData: {},
            languages: []
        },
        templateAutoshipData: {
            autoshipNumber: '',
            shipToName: '',
            orderDate: '',
            startMonth: '',
            shipToAddress: '',
            phone: '',
            items: []
        }
    }

    smsConfigs = {
        AUS: {
            code: '61[phone]',
            text: `Your Unicity Order #[order_id] - AUD[price] has been processed. To check on the status, log in or email us at ${this.emailConfigs.AUS.csEmail}`
        },
        SGP: {
            code: '65[phone]',
            text: `Your Unicity Order #[order_id] - SGD[price] has been processed. To check on the status, log in or email us at ${this.emailConfigs.SGP.csEmail}`
        },
        MYS: {
            code: '60[phone]',
            text: `Your Unicity Order #[order_id] - MYR[price] has been processed. To check on the status, log in or email us at ${this.emailConfigs.MYS.csEmail}`
        }
    }

    GetPaymentId() {
        return this.paymentId
    }

    async PreparePostData(postData = null) {
        if (!postData) return false
        try {
            this.paymentId = postData.gateway_ref_id
            this.email = postData.formatData.email

            if (postData.formatData.autoship) {
                this.autoship = postData.formatData.autoship
            }
            return true
        } catch (error) {
            console.log(error)
        }
    }

    async PreparePaymentData() {
        try {
            const response = await PaymentAPIs.CreatePaymentData(this.uuid)
            this.hydra = response.data.hydra
            this.paymentId = response.data.payment_id
            this.ushop = response.data.ushop
            this.email = response.data.email

            if (response.data.autoship) {
                this.autoship = response.data.autoship
            }
            return { success: true, data: response }
        } catch (error) {
            let responseError = `${get(error, 'message', '')}`
            if (get(error, 'response.data.error.error_message', null)) {
                responseError += ` [${get(error, 'response.data.error.error_message', '')}]`
            }
            if (get(error, 'response.data.error.message', null)) {
                responseError += ` [${get(error, 'response.data.error.message', '')}]`
            }

            if (get(error, 'response.data.error.error.message', null)) {
                responseError += ` [${get(error, 'response.data.error.error.message', '')}]`
            }

            storeReferralCheckout.SetErrors([responseError])
            throw error
        }
    }

    async PreparePaymentLambdaData() {
        try {
            const response = await PaymentAPIs.CreatePaymentLambdaData(this.uuid)
            this.hydra = response.data.hydra

            this.orderData = {
                ...response.data.hydra,
                id: {
                    unicity: ''
                },
                customer: {
                    id: {
                        unicity: StoreAuth.GetId()
                    }
                }
            }
            this.lambda = {
                uuid: this.uuid,
                type: 'feelGreat',
                source_path: window.location.href,
                ba_id: StoreAuth.GetId(),
                token: StoreAuth.GetToken(),
                gateway_success_url: 'www.google.com',
                gateway_error_url: 'www.google.com',
                gateway_name: 'a',
                payments: []
            }
            // this.paymentId = response.data.payment_id
            this.ushop = response.data.data
            this.email = response.data.email
            if (response.data.autoship) {
                this.autoship = response.data.autoship
            }

            StorePaymentStep.SetPaymentStep('payment', 1)

            return { success: true, data: response }
        } catch (error) {
            let responseError = `${get(error, 'message', '')}`
            if (get(error, 'response.data.error.error_message', null)) {
                responseError += ` [${get(error, 'response.data.error.error_message', '')}]`
            }
            if (get(error, 'response.data.error.message', null)) {
                responseError += ` [${get(error, 'response.data.error.message', '')}]`
            }

            if (get(error, 'response.data.error.error.message', null)) {
                responseError += ` [${get(error, 'response.data.error.error.message', '')}]`
            }

            StorePaymentStep.SetErrors([responseError])
            StorePaymentStep.SetPaymentStep('payment', -1)
            throw error
        }
    }

    async PaymentToCybersource() {
        // console.info('Pay to Cybersource')
        // try {
        //     const response = await PaymentAPIs.PayToCybersource(this.uuid)
        //     console.log(response.data)
        //     return {
        //         success: true,
        //         data: response.data,
        //     }
        // } catch(e) {
        //     console.error(e)
        // }
    }

    async PostToLambda() {
        console.info('Posting to Lambda')
        let response = null
        try {
            let dataToLambda = toJS(this.lambda)
            const paymentData = get(this.hydra, 'transactions.items[0]')
            dataToLambda = {
                ...dataToLambda,
                payments: [
                    {
                        ...get(paymentData, 'methodDetails', {}),
                        method: get(paymentData, 'method', '').replace('CreditCard', 'creditCard'),
                        amount: get(this.ushop, 'total_price')
                    }
                ]
            }
            response = await PaymentAPIs.CreateLambdaOrder(dataToLambda, StoreAuth.GetToken())

            if (!response.data.success) {
                StorePaymentStep.SetPaymentStep('createOrder', -1)
                let responseError = [`${get(response.data, 'response_desc', '')}`]
                StorePaymentStep.SetErrors([responseError])
                return response
            }

            this.paymentId = get(response.data, 'payment_id', '')

            this.orderData = { ...this.orderData, ...response.data }
            StorePaymentStep.SetPaymentStep('createOrder', 1)

            // set autoship number
            if (get(this.orderData, 'customer.autoorders.items[0]', null)) {
                this.autoshipNumber = get(this.orderData, 'customer.autoorders.items[0].id.unicity', null)
            }

            // create autoship
            if (this.autoship) {
                await this.Autoship()
            }
            return response
        } catch (error) {
            StorePaymentStep.SetPaymentStep('createOrder', -1)
            let responseError = `${get(error, 'message', '')}`
            if (get(error, 'response.data.error_message', null)) {
                responseError += ` [${get(error, 'response.data.error_message', '')}]`
            }
            if (get(error, 'response.data.message', null)) {
                responseError += ` [${get(error, 'response.data.message', '')}]`
            }
            StorePaymentStep.SetErrors([responseError])
        }
    }

    async PostToHydra(options) {
        console.info('Posting to Hydra')
        let response = null

        try {
            const dataToHydra = toJS(this.hydra)
            response = await PaymentAPIs.CreateOrder(dataToHydra, StoreAuth.GetToken())
            this.orderDataToUrl = `${HYDRA_URL}/customers/me/orders`

            if (response) {
                this.orderData = response.data
                this.orderDataHeader = response.headers
                this.orderDataCurl = response.request
                StorePaymentStep.SetPaymentStep('createOrder', 1)
                if (get(this.orderData, 'customer.autoorders.items[0]', null)) {
                    this.autoshipNumber = get(this.orderData, 'customer.autoorders.items[0].id.unicity', null)
                }

                if (this.autoship) {
                    await this.Autoship()
                }
            }
        } catch (error) {
            StorePaymentStep.SetPaymentStep('createOrder', -1)
            let responseError = `${get(error, 'message', '')}`
            if (get(error, 'response.data.error.error_message', null)) {
                responseError += ` [${get(error, 'response.data.error.error_message', '')}]`
            }
            if (get(error, 'response.data.error.message', null)) {
                responseError += ` [${get(error, 'response.data.error.message', '')}]`
            }
            StorePaymentStep.SetErrors([responseError])
        }
    }

    async PostToHydraWorldPayRequestDDC() {
        console.info('Posting to Hydra WorldPay Get DDC')
        let response = null
        try {
            const card = StoreCheckout.creditCard.find(item => item.label === 'card_number')
            response = await PaymentAPIs.RequestWorldPayDDC(card.value)
            if (response) {
                StoreCheckout.SetWorldPayReq({
                    ddcHTML: response.data.ddcHTML.replace(/\r\n/g, ''),
                    sessionId: response.data.sessionId
                })
                StorePaymentStep.SetPaymentStep('worldPayReq', 1)
            }
        } catch (error) {
            StorePaymentStep.SetPaymentStep('worldPayReq', -1)
            let responseError = `${get(error, 'message', '')}`
            if (get(error, 'response.data.error.error_message', null)) {
                responseError += ` [${get(error, 'response.data.error.error_message', '')}]`
            }
            if (get(error, 'response.data.error.message', null)) {
                responseError += ` [${get(error, 'response.data.error.message', '')}]`
            }
            StorePaymentStep.SetErrors([responseError])
        }
    }

    async PostToHydraWorldPay(options) {
        console.info('Posting to Hydra WorldPay')
        let response = null

        try {
            const dataToHydra = toJS(this.hydra)

            response = await PaymentAPIs.CreateWorldPayOrder(dataToHydra, StoreAuth.GetToken())
            this.orderDataToUrl = `${HYDRA_URL}/customers/me/orders`
            StoreCheckout.SetWorldPayResponse(true)
            if (response) {
                let methodDetails = response.data.transactions.items[0].methodDetails

                if (!!methodDetails.challengeHTML) {
                    StoreCheckout.SetWorldPayReq({
                        challengeHTML: methodDetails.challengeHTML.replace(/\r\n/g, '')
                    })

                    // This event to waiting for hydra done their step

                    for (let index = 0; index >= 0; index++) {
                        console.log('still waiting user fulfilled an OTP ', toJS(StoreCheckout.countPathChange))
                        if (StoreCheckout.countPathChange >= 2) {
                            break
                        }
                        // Delay before the next retry (optional)
                        await new Promise(resolve => setTimeout(resolve, 1000)) // 1 second delay
                    }
                }

                let orderStutus = await this.GetHydraOrderDetailWithRetry(response.data.id.unicity, 3)
                if (orderStutus === 'Paid') {
                    this.orderData = response.data
                    this.orderDataHeader = response.headers
                    this.orderDataCurl = response.request

                    StorePaymentStep.SetPaymentStep('createOrder', 1)
                    if (get(this.orderData, 'customer.autoorders.items[0]', null)) {
                        this.autoshipNumber = get(this.orderData, 'customer.autoorders.items[0].id.unicity', null)
                    }
                    if (this.autoship) {
                        await this.AutoshipWorldPay()
                    }
                } else {
                    StorePaymentStep.SetPaymentStep('createOrder', -1)
                    let responseError = `Problem with payment`
                    StorePaymentStep.SetErrors([responseError])
                }
            }
        } catch (error) {
            StorePaymentStep.SetPaymentStep('createOrder', -1)
            let responseError = `${get(error, 'message', '')}`
            if (get(error, 'response.data.error.error_message', null)) {
                responseError += ` [${get(error, 'response.data.error.error_message', '')}]`
            }
            if (get(error, 'response.data.error.message', null)) {
                responseError += ` [${get(error, 'response.data.error.message', '')}]`
            }
            StorePaymentStep.SetErrors([responseError])
        }
    }

    async GetHydraOrderDetailWithRetry(orderNumber, maxRetries) {
        let orderStatus = 'waiting'
        for (let retry = 0; retry < maxRetries; retry++) {
            const response = await PaymentAPIs.GetHydraOrderDetail(orderNumber, StoreAuth.GetToken())
            if (response.data.length) {
                if (response.data[0].paymentStatus === 'Paid') {
                    orderStatus = response.data[0].paymentStatus
                    break
                }
            }

            // Delay before the next retry (optional)
            await new Promise(resolve => setTimeout(resolve, 1500)) // 1 second delay
        }
        return orderStatus
    }

    async PostToHydra3Ds() {
        console.info('Posting to Hydra3Ds')
        let response = null

        try {
            const dataToHydra = toJS(this.hydra)
            response = await PaymentAPIs.CreateOrder(dataToHydra, StoreAuth.GetToken())
            this.orderDataToUrl = `${HYDRA_URL}/customers/me/orders`

            if (response) {
                // this.orderData = response.data
                // this.orderDataHeader = response.headers
                // this.orderDataCurl = response.request
                console.log('response hydra 3ds', response)
            }
        } catch (error) {
            StorePaymentStep.SetPaymentStep('createOrder', -1)
            let responseError = `${get(error, 'message', '')}`
            if (get(error, 'response.data.error.error_message', null)) {
                responseError += ` [${get(error, 'response.data.error.error_message', '')}]`
            }
            if (get(error, 'response.data.error.message', null)) {
                responseError += ` [${get(error, 'response.data.error.message', '')}]`
            }
            StorePaymentStep.SetErrors([responseError])
        }
    }

    async Autoship() {
        console.info('Creating Autoship')
        try {
            const data = toJS(this.autoship)

            data.transactions = this.hydra.transactions

            data.transactions.items = data.transactions.items.filter(item => (item.method || '').toLowerCase() === 'creditcard')

            const response = await PaymentAPIs.CreateAutoship(data, StoreAuth.GetToken())
            this.autoshipNumber = response.data.id.unicity
            this.autoshipResponseData = response.data
            StorePaymentStep.SetPaymentStep('autoship', 1)

            // Update Autoship log for lambda payment
            await this.UpdateAutoshipLogLambdaPayment(response.data.id.unicity, data, response.headers, response.data)

            // await this.UpdateAutoshipLog(response.data.id.unicity, data, response.headers, response.data)
        } catch (error) {
            StorePaymentStep.SetPaymentStep('autoship', -1)
            console.log('Autoship error', error)

            throw error
        }
    }

    async AutoshipWorldPay() {
        console.info('Creating Autoship World Pay')
        try {
            let data = toJS(this.autoship)

            data.transactions = this.hydra.transactions

            data.transactions = this.hydra.transactions

            data.transactions.items = data.transactions.items.filter(item => item.method === 'CreditCard')

            console.log('data.transactions.items', toJS(data.transactions.items))

            if (data.transactions.items.length) {
                const tsItem = data.transactions.items[0]
                data.transactions.items[0] = {
                    type: 'AuthorizeAndCapture',
                    method: 'CreditCard',
                    amount: 'this.terms.total',
                    methodDetails: {
                        payer: tsItem.methodDetails.payer,
                        creditCardNumber: tsItem.methodDetails.creditCardNumber,
                        creditCardExpires: tsItem.methodDetails.creditCardExpires,
                        creditCardSecurityCode: tsItem.methodDetails.creditCardSecurityCode
                    },
                    billToAddress: tsItem.billToAddress,
                    billToEmail: tsItem.billToEmail
                }
            }

            const response = await PaymentAPIs.CreateAutoship(data, StoreAuth.GetToken())
            this.autoshipNumber = response.data.id.unicity
            this.autoshipResponseData = response.data
            StorePaymentStep.SetPaymentStep('autoship', 1)

            // Update Autoship log for lambda payment
            await this.UpdateAutoshipLogLambdaPayment(response.data.id.unicity, data, response.headers, response.data)

            // await this.UpdateAutoshipLog(response.data.id.unicity, data, response.headers, response.data)
        } catch (error) {
            StorePaymentStep.SetPaymentStep('autoship', -1)
            console.log('Autoship error', error)

            throw error
        }
    }

    async AutoshipTHA() {
        console.info('Creating Autoship')
        try {
            const data = toJS(this.autoship)

            data.transactions = this.hydra.transactions
            data.transactions.items = data.transactions.items.filter(item => item.method === 'CreditCard')

            const response = await PaymentAPIs.CreateAutoship(data, StoreAuth.GetToken())
            this.autoshipNumber = response.data.id.unicity
            this.autoshipResponseData = response.data
        } catch (error) {
            console.log('Autoship error', error)

            throw error
        }
    }

    async SendEmailTHA() {
        try {
            const sendEmail = new Email()

            const data = toJS(this.autoship)
            const shipToName = data.shipToName.firstName
            const userID = toJS(StoreAuth.GetId())
            // Should be sent from BE
            const display_address = get(storeFormat, 'data.ushop.u23.formatShipping.address', {})
            sendEmail
                .SetToEmail(data.shipToEmail)
                .SetOrderId(this.autoshipNumber + '')
                .SetMemberId(userID)
                .SetShipTo(shipToName, display_address)
                .SetProductItems(this.email.products_email)
                .SetShippingType('delivery')

            if (this.autoship) {
                sendEmail.SetAutoship(this.autoshipNumber)
            }

            console.log('sendEmail', toJS(sendEmail))
            if (StoreUser.CustomerData().ProfilePicture()) {
                sendEmail.SetProfilePicture(StoreUser.CustomerData().ProfilePicture('original'))
            }
            sendEmail.Send(false, 'feelGreatReferral')
        } catch (error) {
            console.error('SEND EMAIL ERROR', error)
        }
    }

    GetBodyEmailTHA() {
        try {
            const sendEmail = new Email()

            const data = toJS(this.autoship)
            const shipToName = data.shipToName.firstName
            const userID = toJS(StoreAuth.GetId())
            // Should be sent from BE
            const display_address = get(storeFormat, 'data.ushop.u23.formatShipping.address', {})
            sendEmail
                .SetToEmail(data.shipToEmail)
                .SetOrderId(this.autoshipNumber + '')
                .SetMemberId(userID)
                .SetShipTo(shipToName, display_address)
                .SetProductItems(this.email.products_email)
                .SetShippingType('delivery')

            if (this.autoship) {
                sendEmail.SetAutoship(this.autoshipNumber)
            }

            if (StoreUser.CustomerData().ProfilePicture()) {
                sendEmail.SetProfilePicture(StoreUser.CustomerData().ProfilePicture('original'))
            }
            return sendEmail.Body(false, 'feelGreatReferral')
        } catch (error) {
            console.error('SEND EMAIL ERROR', error)
        }
    }

    SendEmail() {
        try {
            const sendEmail = new Email()

            const fullNameEnglish = StoreUser.CustomerData().GetNativeName(this.orderData.shipToName, 'fullName')
            const fullNameNative = StoreUser.CustomerData().GetNativeName(this.orderData.shipToName, 'nativeName', false)

            const isUseBothOfFullName = fullNameEnglish.replace(/ /g).toLowerCase().trim() !== fullNameNative.replace(/ /g).toLowerCase().trim()
            const shipToName = `${fullNameEnglish}${isUseBothOfFullName ? ` ,${fullNameNative}` : ''}`

            sendEmail
                .SetToEmail(this.orderData.shipToEmail)
                .SetOrderId(this.orderData.id.unicity)
                .SetMemberId(this.orderData.customer.id.unicity)
                .SetShipTo(shipToName, this.ushop.display_address)
                .SetProductItems(this.email.products_email)
                .SetShippingType('delivery')

            if (this.autoship) {
                sendEmail.SetAutoship(this.autoshipNumber)
            }

            if (StoreUser.CustomerData().ProfilePicture()) {
                sendEmail.SetProfilePicture(StoreUser.CustomerData().ProfilePicture('original'))
            }
            sendEmail.Send()
        } catch (error) {
            console.error('SEND EMAIL ERROR', error)
        }
    }

    SendEmailAutoShipLambdaPayment() {
        try {
            const sendEmail = new Email()

            const fullNameEnglish = StoreUser.CustomerData().GetNativeName(this.orderData.shipToName, 'fullName')
            const fullNameNative = StoreUser.CustomerData().GetNativeName(this.orderData.shipToName, 'nativeName', false)

            const isUseBothOfFullName = fullNameEnglish.replace(/ /g).toLowerCase().trim() !== fullNameNative.replace(/ /g).toLowerCase().trim()
            const shipToName = `${fullNameEnglish}${isUseBothOfFullName ? ` ,${fullNameNative}` : ''}`

            sendEmail
                .SetToEmail(this.orderData.shipToEmail)
                .SetMemberId(this.orderData.customer.id.unicity)
                .SetShipTo(shipToName, this.ushop.display_address)
                .SetProductItems(this.email.products_email)
                .SetShippingType('delivery')
                .SetAutoship(this.autoshipNumber)

            if (StoreUser.CustomerData().ProfilePicture()) {
                sendEmail.SetProfilePicture(StoreUser.CustomerData().ProfilePicture('original'))
            }
            const isLambda = true
            sendEmail.Send(isLambda)
        } catch (error) {
            console.error('SEND EMAIL ERROR', error)
        }
    }

    SendEmailAutoship() {
        // console.info('Sending Autoship email')
        // try {
        //     if (!this.autoshipResponseData) {
        //         this.autoshipResponseData = get(this.hydra, 'customer.autoorders.items[0]')
        //     }
        //     let shipToAddress = get(this.autoshipResponseData, 'shipToAddress', {})
        //     let mainAddress = ''
        //     if (Country.isMatchedCountry(['AU'])) {
        //         mainAddress = `${shipToAddress.address1}, ${shipToAddress.city}, ${shipToAddress.state}, ${StoreCountry.CountryCapitalize()}, ${shipToAddress.zip}`
        //     } else if (Country.isMatchedCountry(['SG'])) {
        //         mainAddress = `${shipToAddress.address1}, ${shipToAddress.city}, ${shipToAddress.zip}`
        //     }
        //     const date = new Date(get(this.orderData, 'dateCreated'))
        //     const startMonth = `${monthNames[date.getMonth() + 1]} ${date.getFullYear()}`
        //     const autoshipOrderDate = `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`
        //     const dataEmail = {
        //         from: this.emailConfigs.from,
        //         to: get(this.autoshipResponseData, 'shipToEmail', ''),
        //         subject: this.emailConfigs.subjectAutoship,
        //         templateID: this.email.autoship.templateName,
        //         bcc: `unicity.dev2018@gmail.com;${this.emailConfigs[StoreCountry.Country3()].csEmail}`,
        //         templateData: {
        //             languages: this.email.autoship.body.languages
        //         }
        //     }
        //     let profilePicture = this.email.order.body.languages[0].profilePicture
        //     if (StoreAuth.GetId()) {
        //         profilePicture = get(StoreUser.CustomerData().ProfilePicture(), 'sizes[0].media', '')
        //     }
        //     dataEmail.templateData.languages[0].profilePicture = profilePicture
        //     dataEmail.templateData.languages[0].summaries[0].odd.fieldValue = this.autoshipNumber
        //     dataEmail.templateData.languages[0].summaries[0].even.fieldValue = autoshipOrderDate
        //     dataEmail.templateData.languages[0].summaries[1].odd.fieldValue = startMonth
        //     dataEmail.templateData.languages[0].summaries[1].even.fieldValue = mainAddress
        //     dataEmail.templateData.languages[0].products = this.OrderProducts()
        //     dataEmail.templateData = JSON.stringify(dataEmail.templateData)
        //     PaymentAPIs.PostEmail(dataEmail)
        // } catch (error) {
        //     loge('SEND AUTOSHIP EMAIL ERROR', error)
        // }
    }

    SendSms() {
        console.info('Sending SMS')
        try {
            const phone = get(this.orderData, 'shipToPhone')
            const formatphone = ['AUS', 'NZL', 'MYS'].includes(StoreCountry.Country3()) ? phone.slice(1) : phone
            const dataSms = {
                to: this.smsConfigs[StoreCountry.Country3()].code.replace('[phone]', formatphone),
                text: this.smsConfigs[StoreCountry.Country3()].text
                    .replace('[order_id]', get(this.orderData, 'id.unicity'))
                    .replace('[price]', get(this.orderData, 'terms.total') || get(this.ushop, 'total_price'))
                    .replace('[pv]', get(this.orderData, 'terms.pv') || get(this.ushop, 'total_pv'))
            }
            PaymentAPIs.PostSms(dataSms)
        } catch (error) {
            console.warn('send sms warning', error)
        }
    }

    async UpdateToLog() {
        console.info('Updating to Log')
        try {
            let orderData = toJS(this.orderData)
            let marskedCreditCard = toJS(this.hydra)

            marskedCreditCard.transactions = {
                items: orderData.transactions.items
            }

            if (marskedCreditCard.customer && marskedCreditCard.customer.autoorders) {
                if (marskedCreditCard.customer.autoorders.items[0]) {
                    marskedCreditCard.customer.autoorders.items = marskedCreditCard.transactions.items
                }
            }

            const postData = {
                payments_id: this.paymentId,
                payment_status: 'success',
                order_number: get(this.orderData, 'id.unicity', ''),
                approval_code: get(this.orderData, 'transactions.items[0].authorization', ''),
                payment_method1: 'creditCard',
                payment_amount1: 'this.terms.total', // ค่าแบบนี้เลย
                payment_method2: '',
                payment_amount2: '',
                hydra_curl: this.toCurl({ url: this.orderDataToUrl, dataRaw: JSON.stringify(marskedCreditCard) }),
                hydra_response_header: JSON.stringify(this.orderDataHeader),
                hydra_response_body: JSON.stringify(orderData)
            }

            if (!this.detailPage.isEnroll) {
                postData.dist_id = get(this.orderData, 'customer.id.unicity', '')
                postData.dist_status = get(this.orderData, 'customer.type', '')
            } else {
                postData.enroll_new_id = get(this.orderData, 'customer.id.unicity', '')
            }

            await PaymentAPIs.UpdateLog(postData)
            StorePaymentStep.SetPaymentStep('updateLog', 1)
        } catch (error) {
            StorePaymentStep.SetPaymentStep('updateLog', -1)
            throw error
        }
    }

    async UpdateAutoshipLog(autoshipId, data, responseHeader, responseBody) {
        console.info('Updaing Autoship Log')
        let orderData = toJS(this.orderData)
        let marskedCreditCard = toJS(data)
        marskedCreditCard.transactions.items = orderData.transactions.items

        const dataRaw = {
            payments_id: this.paymentId,
            autoship_number: autoshipId,
            hydra_curl: this.toCurl({
                url: `${HYDRA_URL}/customers/me/autoorders`,
                dataRaw: JSON.stringify(marskedCreditCard)
            }),
            hydra_response_header: JSON.stringify(responseHeader),
            hydra_response_body: JSON.stringify(responseBody)
        }

        try {
            await PaymentAPIs.CreateAutoshipLog(dataRaw)
        } catch (error) {
            console.error(error)
        }
    }

    async UpdateAutoshipLogLambdaPayment(autoshipId, data, responseHeader, responseBody) {
        console.info('Updaing Autoship Log')
        let orderData = toJS(this.orderData)
        let marskedCreditCard = toJS(data)
        marskedCreditCard.transactions.items = orderData.transactions.items

        const dataRaw = {
            payments_id: this.paymentId,
            autoship_number: autoshipId,
            hydra_curl: this.toCurl({
                url: `${HYDRA_URL}/customers/me/autoorders`,
                dataRaw: JSON.stringify(marskedCreditCard)
            }),
            hydra_response_header: JSON.stringify(responseHeader),
            hydra_response_body: JSON.stringify(responseBody)
        }
        try {
            await PaymentAPIs.UpdateLambdaAutoshipLog(dataRaw)
        } catch (error) {
            console.error(error)
        }
    }

    async UpdateCMILog(payment_status = 'waiting', payment_method) {
        console.info('Updating CMI Log')
        try {
            let orderData = toJS(this.orderData)
            let hydraData = toJS(this.hydra)

            const postData = {
                payments_id: this.paymentId,
                payment_status: payment_status,
                order_number: get(this.orderData, 'id.unicity', ''),
                approval_code: '',
                payment_method1: payment_method,
                payment_amount1: 'this.terms.total', // ค่าแบบนี้เลย
                payment_method2: '',
                payment_amount2: '',
                hydra_curl: this.toCurl({ url: this.orderDataToUrl, dataRaw: JSON.stringify(hydraData) }),
                hydra_response_header: JSON.stringify(this.orderDataHeader),
                hydra_response_body: JSON.stringify(orderData)
            }

            if (!this.detailPage.isEnroll) {
                postData.dist_id = get(this.orderData, 'customer.id.unicity', '')
                postData.dist_status = get(this.orderData, 'customer.type', '')
            } else {
                postData.enroll_new_id = get(this.orderData, 'customer.id.unicity', '')
            }

            await PaymentAPIs.UpdateLog(postData)
            StorePaymentStep.SetPaymentStep('updateLog', 1)
        } catch (error) {
            StorePaymentStep.SetPaymentStep('updateLog', -1)
            throw error
        }
    }

    async Login(baId) {
        try {
            const response = await PaymentAPIs.CustomerLogin(baId, this.hydra.customer.password.value)
            await PaymentAPIs.MakeCustomerInternational(baId)
            StoreAuth.token = response.data.token
        } catch (error) {
            console.error('error login', error)
            throw error
        }
    }

    OrderProducts() {
        const items = []
        const type = get(this.ushop, 'shipment_purchas_option', 'oneTime') === 'subscribe' ? 'subscribe' : 'oneTime'
        const orderItems = JSON.parse(get(this.ushop, 'items'))
        const lines = toJS(this.orderData.lines.items)

        Object.keys(this.products).map(product => {
            let item = orderItems.find(p => p.item_code === this.products[product][type].item_code)
            let itemName = this.pickups.find(item => item.value === product)
            let line = lines.find(line => line.item.id.unicity === this.products[product][type].item_code)

            if (item) {
                items.push({
                    productImage: this.products[product][type].image_url,
                    productName: `FeelGreat-${itemName.text}`,
                    productPrice: `${item.qty} ea | ${line.terms.priceEach * item.qty} ${this.orderData.currency}`
                })
            }
        })

        return items
    }

    toCurl = ({
        method = `POST`,
        url,
        headers = {
            'Content-Type': `application/json`
        },
        dataRaw
    }) => {
        const curlLines = [
            `curl --location --request ${method} '${HttpHelper.Proxy({ originalUrl: url })}'`,
            ...Object.keys(headers).map(key => `--header '${key}: ${headers[key]}'`),
            `--data-raw '${dataRaw}'`
        ]

        return curlLines.join(` `)
    }

    SubmitOneTimePayment = async ({ payments = null, type = '' }) => {
        storeReferralCheckout.paymentErrors = []
        const hostname = window.location.origin
        const postbackUrl = {
            success: `{{hostname}}/{{country}}/payment-response/success`,
            error: `{{hostname}}/{{country}}/payment-response/failed`
        }
        const successUrl = postbackUrl.success.replace('{{hostname}}', hostname).replace('{{country}}', StoreCountry.CountryLowerCase())
        const errorUrl = postbackUrl.error.replace('{{hostname}}', hostname).replace('{{country}}', StoreCountry.CountryLowerCase())

        const query = new URLSearchParams(document.location.search)

        const data = {
            uuid: query.get('uuid'),
            gateway_success_url: successUrl,
            gateway_error_url: errorUrl,
            source_path: window.location.href,
            type: 'feelGreatReferral'
        }

        if (StoreAuth.isAuthorized) {
            data.ba_id = StoreAuth.GetId()
            data.token = StoreAuth.GetToken()
        } else {
            data['order_type'] = 'enroll'
        }
        if (payments) {
            data['payments'] = payments
        }

        if (type === 'qr') {
            data['gateway_name'] = '2c2p'
        }

        try {
            const result = await PaymentAPIs.CreatePaymentDataForHostedGateway(data)

            if (result.data.success === false) {
                let error = result.data.error
                let responseError = `${get(error, 'message', '')}`
                if (get(error, 'response.data.error.error_message', null)) {
                    responseError += ` [${get(error, 'response.data.error.error_message', '')}]`
                }
                if (get(error, 'response.data.error.message', null)) {
                    responseError += ` [${get(error, 'response.data.error.message', '')}]`
                }
                storeReferralCheckout.SetErrors([responseError, `UUID : ${storeReferralCheckout.GetUuid()}`])
            } else {
                return result.data
            }
        } catch (error) {
            console.log(error)
            let responseError = `${get(error, 'message', '')}`
            if (get(error, 'response.data.error.error_message', null)) {
                responseError += ` [${get(error, 'response.data.error.error_message', '')}]`
            }
            if (get(error, 'response.data.error.message', null)) {
                responseError += ` [${get(error, 'response.data.error.message', '')}]`
            }
            storeReferralCheckout.SetErrors([responseError, `UUID : ${storeReferralCheckout.GetUuid()}`])
        }
    }

    constructor(uuid, detailPage, paymentStep) {
        this.uuid = uuid
        this.detailPage = detailPage
        this.PaymentStep = paymentStep

        makeAutoObservable(this)
    }
}

export default Payment
