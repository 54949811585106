import { FormControl, FormControlLabel, Grid, RadioGroup, Stack, ThemeProvider, Typography } from '@mui/material'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { observer } from 'mobx-react-lite'
import { DefaultButton, DefaultInput } from '../Forms'
import { useEffect, useState } from 'react'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { autorun, reaction, toJS } from 'mobx'
import storeReferralCheckout from '../../Stores/StoreReferralCheckout'
import styled from '@emotion/styled'
import ModalSearch from '../Forms/ModalSearch/ModalSearch'
import CountryBox from '../Forms/CountryBox/CountryBox'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import storeAuth from 'GlobalStores/User/StoreAuth'
import StoreReferralCart from '../../Stores/StoreReferralCart'
import StorePeriods from 'GlobalStores/StorePeriods'
import { TaxInvoiceApis } from './TaxInvoiceApis'
import Errors from '../Errors'
import { capitalize } from 'lodash'
import { getBrowserOS } from 'Helpers/Bowser'
import storeUser from 'GlobalStores/User/StoreUser'
import storeFormAddress from '../../Stores/StoreFormAddress'
import { storeElement } from 'GlobalStores/StoreElement'
import RadioButton from '../Forms/RadioButton/RadioButton'
import { isSomething } from 'Utils/Utils'
import { ReactComponent as LockIcon } from '../Forms/Assets/Lock.svg'
import ProvinceSelect from '../Forms/ProvinceSelect/ProvinceSelect'
import { useNavigate } from 'react-router-dom'

const TaxInvoice = observer(props => {
    const translate = useTranslate()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)

    const inputs = [
        'fullNameTh',
        'fullNameEn',
        'company_name',
        'company_name_en',
        'branch_number',
        'zip_code',
        'province',
        'district',
        'sub_district',
        'street',
        'house_number'
    ]

    useEffect(() => {
        storeReferralCheckout.invoiceFormsErrors = []
        autorun(disposer => {
            if (storeCheckout.GetShippingForm().length > 0 && !isSomething(storeReferralCheckout.GetUuid())) {
                if (storeReferralCheckout.invoiceType === 'individual') {
                    initIndividualForm()
                } else {
                    initCorporateForm()
                }

                disposer.dispose()
            }
        })

        const disposerRectionTaxType = reaction(
            () => storeReferralCheckout.invoiceType,
            newValue => {
                storeReferralCheckout.invoiceFormsErrors = []
                if (!isSomething(storeReferralCheckout.GetUuid())) {
                    if (newValue === 'individual') {
                        initIndividualForm(false)
                    } else {
                        initCorporateForm(false)
                    }
                }
            }
        )

        initIndividualForm()
        initCorporateForm()

        return () => {
            disposerRectionTaxType()
        }
    }, [])

    const initIndividualForm = (isClearValue = true) => {
        let forms = []
        if (storeReferralCheckout.invoiceIndividualForms.length === 0) {
            forms = toJS(storeCheckout.GetShippingForm())
            forms.map(item => {
                item.value = ''
                let restore = storeReferralCheckout.invoiceForms.find(item2 => item2.key === item.key)
                if (restore) {
                    item.value = restore.value
                }
                return item
            })

            forms.push({
                key: 'fullNameEn',
                args: null,
                label: 'fullname_en',
                placeholder: '',
                type: 'text',
                value: ''
            })

            let rearrange = []

            inputs.map(item => {
                let restore = forms.find(item2 => item2.key === item)

                if (restore) {
                    if (restore.key === 'fullName') {
                        restore.key = 'fullNameTh'
                    }
                    rearrange.push(restore)
                }
            })
            rearrange.unshift({
                key: 'fullNameTh',
                args: null,
                label: 'fullname',
                placeholder: '',
                type: 'text',
                value: ''
            })
            storeReferralCheckout.invoiceIndividualForms = rearrange
        }
    }

    const initCorporateForm = (isClearValue = true) => {
        // localObserver.individualForm = toJS(storeReferralCheckout.invoiceForms)
        let forms = []
        if (storeReferralCheckout.invoiceCorporateForms.length === 0) {
            forms = toJS(storeCheckout.GetShippingForm())
            forms = forms.filter(item => item.key !== 'fullNameEn' && item.key !== 'fullNameTh')
            forms.unshift({
                key: 'branch_number',
                type: 'BranchSelect',
                value: 'main_office',
                args: null,
                label: 'branch_number'
            })

            forms.unshift({
                key: 'company_name_en',
                type: 'text',
                value: '',
                args: null,
                label: 'company_name_en'
            })
            forms.unshift({
                key: 'company_name',
                type: 'text',
                value: '',
                args: null,
                label: 'company_name'
            })

            forms.map(item => {
                item.value = ''
                let restore = storeReferralCheckout.invoiceCorporateForms.find(item2 => item2.key === item.key)
                if (restore) {
                    item.value = restore.value
                }
                if (item.key === 'branch_number') {
                    item.value = 'main_office'
                }

                return item
            })

            let rearrange = []

            inputs.map(item => {
                let restore = forms.find(item2 => item2.key === item)
                if (restore) {
                    rearrange.push(restore)
                }
            })

            storeReferralCheckout.invoiceCorporateForms = rearrange
        }
    }

    const SaveTaxInvoice = async () => {
        storeReferralCheckout.invoiceFormsErrors = []
        let data = toJS(storeReferralCheckout.GetTaxInvoiceValues())
        let payerNo = ''
        delete data.email
        delete data.phone

        if (storeReferralCheckout.invoiceType === 'individual') {
            data.government_id = storeReferralCheckout.invoiceGovernmentId
            payerNo = data.government_id
        } else {
            data.tax_id = storeReferralCheckout.invoiceTaxId
            payerNo = data.tax_id
        }

        const postData = {
            medium: 'Internet',
            agent: 'uFeelgreat',
            platform: getBrowserOS().name,
            type: !storeAuth.isAuthorized ? 'enroll' : 'shop',
            items: JSON.stringify(toJS(StoreReferralCart.getCartItems())),
            period: StorePeriods.periods.entryPeriod,
            tax_invoice: 1,
            tax_invoice_type: capitalize(storeReferralCheckout.invoiceType),
            tax_invoice_zip_code: data.zip_code,
            tax_invoice_province: data.province,
            tax_invoice_district: data.district,
            tax_invoice_sub_ditrict: data.sub_district,
            tax_invoice_street: data.street,
            tax_invoice_house_no: data.house_number,
            selectAddressHistory: storeFormAddress.typeFormAddress
        }

        if (storeReferralCheckout.invoiceType === 'corporate') {
            postData.tax_invoice_branch_no = data.branch_number
            postData.tax_invoice_company_name = data.company_name
            postData.tax_invoice_company_name_en = data.company_name_en
            postData.tax_invoice_payer_no = payerNo
        } else {
            postData.tax_invoice_gov_id = payerNo
            postData.tax_invoice_full_name = data.fullNameTh
            postData.tax_invoice_full_name_en = data.fullNameEn
        }

        if (storeAuth.isAuthorized) {
            postData.ba_status = storeAuth.userStatus
            postData.login_id = storeAuth.GetId()
            postData.login_name = storeUser.CustomerData().Name()
            postData.login_name_native = storeUser.CustomerData().NativeName()
            postData.token = storeAuth.GetToken()
        }

        try {
            setIsLoading(true)
            const response = await TaxInvoiceApis.ValidateTaxInvoice(postData)
            if (response.data.success === false) {
                let errors = response.data.Message_v2
                errors.map(item => {
                    storeReferralCheckout.invoiceFormsErrors.push(translate(item))
                })
            } else {
                storeReferralCheckout.invoiceFormsErrors = []
                storeReferralCheckout.invoiceInformation = postData
                storeReferralCheckout.requestTaxInvoice = true
                navigate('/')
            }
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
        }
    }

    const taxIdFormatter = id => {
        const number = id.trim().replace(/[^0-9]/g, "");
        if (number.length < 1) return number
        if (number.length < 6) return number.replace(/(\d{1})(\d{1})/, "$1-$2")
        if (number.length < 11) return number.replace(/(\d{1})(\d{4})(\d{1})/, "$1-$2-$3")
        if (number.length < 13) return number.replace(/(\d{1})(\d{4})(\d{5})(\d{1})/, "$1-$2-$3-$4")
        return number.replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, "$1-$2-$3-$4-$5")
    }

    return (
        <ThemeProvider theme={storeElement.theme}>
            <TaxInvoiceWrapper>
                <Stack className="sub-wrapper">
                    <Stack>
                        <Stack
                            sx={{
                                padding: {
                                    xs: '30px 20px',
                                    md: '30px'
                                }
                            }}>
                            <Typography variant="h2">{translate('request_for_full_tax_invoice')}</Typography>
                        </Stack>
                        <Stack
                            padding={{
                                xs: '0px 25px',
                                md: '0px 30px'
                            }}>
                            <FormControl>
                                <RadioGroup>
                                    <RadioGroupStackStyled>
                                        <FormControlLabel
                                            value="individual"
                                            control={<RadioButton />}
                                            label={
                                                <Typography
                                                    variant="h4"
                                                    style={{
                                                        color: storeReferralCheckout.invoiceType === 'individual' ? '#003B6F' : '#8A9BB0'
                                                    }}>
                                                    {translate('individual')}
                                                </Typography>
                                            }
                                            checked={storeReferralCheckout.invoiceType === 'individual'}
                                            onClick={() => (storeReferralCheckout.invoiceType = 'individual')}
                                            style={{
                                                color: storeReferralCheckout.invoiceType === 'individual' ? '#003B6F' : '#8A9BB0'
                                            }}
                                        />
                                        <FormControlLabel
                                            value="corporate"
                                            control={<RadioButton />}
                                            label={
                                                <Typography
                                                    variant="h4"
                                                    style={{
                                                        color: storeReferralCheckout.invoiceType === 'corporate' ? '#003B6F' : '#8A9BB0'
                                                    }}>
                                                    {translate('corporate')}
                                                </Typography>
                                            }
                                            checked={storeReferralCheckout.invoiceType === 'corporate'}
                                            onClick={() => (storeReferralCheckout.invoiceType = 'corporate')}
                                            style={{
                                                color: storeReferralCheckout.invoiceType === 'corporate' ? '#003B6F' : '#8A9BB0'
                                            }}
                                        />
                                    </RadioGroupStackStyled>
                                </RadioGroup>
                            </FormControl>
                        </Stack>
                        <Stack
                            direction={{
                                xs: 'column',
                                sm: 'row'
                            }}
                            justifyContent={{
                                xs: 'flex-start'
                            }}
                            gap={{
                                xs: '10px',
                                sm: '10px',
                                md: '40px'
                            }}
                            padding={{
                                xs: '20px',
                                sm: '20px',
                                md: '25px 30px'
                            }}
                            bgcolor="#E9F1FA"
                            mt={{
                                xs: '25px'
                            }}
                            alignItems={{
                                xs: 'flex-start',
                                sm: 'center'
                            }}>
                            <Stack width={'max-content'} style={{ textWrap: 'nowrap' }}>
                                <Typography variant="h3">
                                    {storeReferralCheckout.invoiceType === 'individual' ? translate('government_id') : translate('taxpayer_id')}:
                                </Typography>
                            </Stack>
                            <Stack width={'100%'}>
                                <CustomInputWithEndorment
                                    label={null}
                                    type={'tel'}
                                    value={
                                        storeReferralCheckout.invoiceType === 'individual'
                                            ? taxIdFormatter(storeReferralCheckout.invoiceGovernmentId)
                                            : taxIdFormatter(storeReferralCheckout.invoiceTaxId)
                                    }
                                    onChange={e => {
                                        if (storeReferralCheckout.invoiceType === 'individual' && e.target.value.replace(/-/g, '').length <= 13) {
                                            if (/[0-9]/.test(e.target.value) || e.target.value === '') {
                                                storeReferralCheckout.invoiceGovernmentId = e.target.value.replace(/-/g, '')
                                            }
                                        } else if (storeReferralCheckout.invoiceType === 'corporate' && e.target.value.replace(/-/g, '').length <= 15) {
                                            if (/[0-9]/.test(e.target.value) || e.target.value === '') {
                                                storeReferralCheckout.invoiceTaxId = e.target.value.replace(/-/g, '')
                                            }
                                        }
                                    }}
                                    sx={{ '.MuiInputBase-input': { background: '#FFFFFF' } }}
                                    inputProps={{ style: { fontSize: '16px', color: '#000000' } }}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack
                        paddingTop={{
                            xs: '30px 0px',
                            sm: '20px 0px',
                            md: '30px 0px'
                        }}>
                        <Stack
                            paddingTop={'25px'}
                            paddingLeft={{
                                xs: '20px',
                                md: '30px'
                            }}
                            paddingBottom={'25px'}>
                            <Typography variant="h3">{translate('tax_invoice_address')}:</Typography>
                        </Stack>
                        <FormStack
                            padding={{
                                xs: '0px 20px',
                                md: '0px 30px'
                            }}
                            className={`invoice-form ${storeReferralCheckout.invoiceType === 'corporate' ? 'tax-invoice-corporate-form' : ''}`}>
                            <Grid container marginBottom={'0px!important'}>
                                {storeReferralCheckout[
                                    storeReferralCheckout.invoiceType.toLowerCase() === 'individual' ? 'invoiceIndividualForms' : 'invoiceCorporateForms'
                                ].map((item, index) => {
                                    if (!inputs.includes(item.key)) return null
                                    if (item.type === 'CountryBox') {
                                        return (
                                            <Grid item key={item.key}>
                                                <CountryBox
                                                    label={translate('country')}
                                                    value={
                                                        StoreTranslate.CurrentLanguage() === 'EN'
                                                            ? storeCountry.CountryCapitalize()
                                                            : storeCountry.CountryNative()
                                                    }
                                                />
                                            </Grid>
                                        )
                                    } else if (item.type === 'BranchSelect') {
                                        return (
                                            <Grid item key={item.key}>
                                                {/* <BranchSelect label={translate(item.key)} /> */}
                                                <CustomInputWithEndorment
                                                    label={translate(item.key)}
                                                    type="customx"
                                                    disabled
                                                    value={translate('head_office')}
                                                    onChange={e => {
                                                        if (item.args?.regx) {
                                                            const regex = new RegExp(item.args.regx)
                                                            if (regex.test(e.target.value)) {
                                                                storeReferralCheckout.SetInvoiceValue(item.key, e.target.value)
                                                            }
                                                        } else {
                                                            storeReferralCheckout.SetInvoiceValue(item.key, e.target.value)
                                                        }
                                                    }}
                                                    endAdornment={<LockIcon />}
                                                />
                                            </Grid>
                                        )
                                    } else {
                                        if (item.key === 'zip_code') {
                                            return (
                                                <Grid item key={item.key}>
                                                    <ModalSearch
                                                        label={translate(item.key)}
                                                        options={[]}
                                                        value={storeReferralCheckout.GetInvoiceValue(item.key)}
                                                        onChange={e => {
                                                            let allowNumber = e.target.value.replace(/[^0-9]/g, '')
                                                            storeReferralCheckout.SetInvoiceValue(item.key, allowNumber)
                                                        }}
                                                        onSet={value => {
                                                            if (StoreTranslate.CurrentLanguage() === 'EN') {
                                                                storeReferralCheckout.SetInvoiceValue('zip_code', value.zip)
                                                                storeReferralCheckout.SetInvoiceValue('province', value.city_roman)
                                                                storeReferralCheckout.SetInvoiceValue('district', value.sub1_roman)
                                                                storeReferralCheckout.SetInvoiceValue('sub_district', value.sub2_roman)
                                                            } else {
                                                                storeReferralCheckout.SetInvoiceValue('zip_code', value.zip)
                                                                storeReferralCheckout.SetInvoiceValue('province', value.city_native)
                                                                storeReferralCheckout.SetInvoiceValue('district', value.sub1_native)
                                                                storeReferralCheckout.SetInvoiceValue('sub_district', value.sub2_native)
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            )
                                        } else if (item.key === 'province') {
                                            return (
                                                <Grid item key={`${item.key}-${storeReferralCheckout.invoiceType}`}>
                                                    <ProvinceSelect
                                                        provinceKey="tax_invoice_province"
                                                        label={translate(item.key)}
                                                        value={storeReferralCheckout.GetInvoiceValue('province')}
                                                    />
                                                </Grid>
                                            )
                                        } else {
                                            return (
                                                <Grid item key={item.key}>
                                                    <DefaultInput
                                                        label={translate(item.key)}
                                                        type="text"
                                                        value={storeReferralCheckout.GetInvoiceValue(item.key)}
                                                        onChange={e => {
                                                            if (item.args?.regx) {
                                                                const regex = new RegExp(item.args.regx)
                                                                if (regex.test(e.target.value)) {
                                                                    storeReferralCheckout.SetInvoiceValue(item.key, e.target.value)
                                                                }
                                                            } else {
                                                                storeReferralCheckout.SetInvoiceValue(item.key, e.target.value)
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            )
                                        }
                                    }
                                })}
                            </Grid>
                            <Stack borderRadius={'8px'} bgcolor={'#FFF1DC'} padding="14px 20px" mb="20px" textAlign={'center'}>
                                <Typography variant="h5" color="#003B6F">
                                    {translate('tax_invoice_warning')}
                                </Typography>
                            </Stack>
                        </FormStack>

                        {storeReferralCheckout.invoiceFormsErrors.length > 0 && (
                            <FormStack
                                mb="40px"
                                padding={{
                                    xs: '0px 20px',
                                    md: '0px 30px'
                                }}>
                                <Errors list={storeReferralCheckout.invoiceFormsErrors} />
                            </FormStack>
                        )}
                        <FormStack
                            mb="30px"
                            padding={{
                                xs: '0px 20px',
                                md: '0px 30px'
                            }}
                            className="invoice-form-actions">
                            <Grid container>
                                <Grid
                                    item
                                    sx={{ textAlign: { xs: 'center', sm: 'left' }, marginTop: { xs: '15px', sm: '0' } }}
                                    alignItems={'center'}
                                    display={'flex'}
                                    justifyContent={{ xs: 'center', sm: 'flex-start' }}
                                    disabled={isLoading}>
                                    <Typography
                                        variant="h3"
                                        sx={{ textDecoration: 'underline', fontWeight: 400, cursor: 'pointer' }}
                                        onClick={() => {
                                            storeReferralCheckout.invoiceFormsErrors = []
                                            navigate('/')
                                        }}>
                                        {translate('cancel')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <DefaultButton
                                        onClick={() => SaveTaxInvoice()}
                                        disabled={isLoading}
                                        isloading={isLoading.toString()}
                                        titleText={
                                            <Typography variant="h3" sx={{ color: '#fff' }}>
                                                {translate('save')}
                                            </Typography>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </FormStack>
                    </Stack>
                </Stack>
            </TaxInvoiceWrapper>
        </ThemeProvider>
    )
})

const TaxInvoiceWrapper = styled(Stack)`
    display: flex;
    align-items: center;
    background-color: #d1d9e2;
    padding: 20px 15px;
    min-height: calc(100vh - 197px);

    @media screen and (min-width: 700px) {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .sub-wrapper {
        background-color: #ffffff;
        width: 100%;
        fill: #fff;
        stroke-width: 1px;
        stroke: #f1f5f8;
        box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.05);
        border-radius: 12px;
        @media screen and (min-width: 700px) {
            width: 80%;
        }
        @media screen and (min-width: 1024px) {
            width: 700px;
        }
    }
`

const CustomInputWithEndorment = styled(DefaultInput)`
    .MuiInputAdornment-root {
        position: absolute;
        right: 8px;
        width: 20px;
        height: 20px;
    }

    .MuiIconButton-root {
        margin-right: 0px;
        padding-right: 0px;
        padding-left: 0px;
        width: 10px;
        height: 13px;
    }
    /* .MuiInputBase-input {
        &.Mui-disabled {
            -webkit-text-fill-color: #000000;
        }
    } */
`

const FormStack = styled(Stack)`
    &.invoice-form {
        .MuiGrid-root {
            width: 100%;
            margin-bottom: 20px;
            @media screen and (min-width: 600px) {
                justify-content: space-between;
            }
            &.MuiGrid-item {
                @media screen and (min-width: 600px) {
                    width: calc(50% - 10px);
                }
            }
        }
        &.tax-invoice-corporate-form {
            .MuiGrid-root {
                &.MuiGrid-item {
                    @media screen and (min-width: 600px) {
                        width: calc(50% - 10px);
                    }
                }
            }
        }
    }
    &.invoice-form-actions {
        .MuiGrid-root {
            justify-content: center;
            align-items: center;
            flex-direction: column-reverse;
            @media screen and (min-width: 600px) {
                flex-direction: row;
                justify-content: space-between;
            }
            &.MuiGrid-item {
                width: 100%;
                @media screen and (min-width: 600px) {
                    width: calc(50% - 10px);
                }
            }
        }
    }
`

const RadioGroupStackStyled = styled(Stack)`
    justify-content: space-between;
    flex-direction: row;

    .MuiRadio-root {
        padding: 0px;
        padding-right: 10px;
    }

    .MuiFormControlLabel-root {
        margin-left: 0px;
    }

    @media screen and (min-width: 600px) {
        justify-content: flex-start;
        grid-column-gap: 120px;
        column-gap: 120px;
        gap: 120px;
    }
`

export default TaxInvoice
