import { InputAdornment, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ReactComponent as Lock } from './Assets/Union.svg'
import DefaultInput from '../DefaultInput'
import styled from '@emotion/styled'
import Flag from 'Components/Elements/Flag'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'

const CountryBox = observer(props => {
    return (
        <UInputText
            disabled={true}
            value={props.value}
            variant="outlined"
            label={
                <Typography variant="label" color="rgba(21, 56, 98, 0.5)">
                    <T>{props.label}</T>
                </Typography>
            }
            InputProps={{
                autoComplete: 'new-password',
                autoCorrect: 'off',
                spellCheck: 'false',
                disabled: true,
                startAdornment: (
                    <InputAdornment position="start">
                        <Flag />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end" style={{ width: '10px', height: '13px' }}>
                        <Lock />
                    </InputAdornment>
                ),
                className: 'country-box'
            }}
            inputlabelprops={{
                shrink: true
            }}
            inputProps={{ 'aria-autocomplete': 'none', autoComplete: 'new-password', spellCheck: 'false' }}
        />
    )
})

const UInputText = styled(DefaultInput)`
    &.MuiFormControl-root.MuiTextField-root {
        width: 100%;

        .MuiInputBase-root {
            border: 1px solid rgba(153, 188, 223, 0.25);
            background-color: #f8fafc !important;
            border-radius: 6px;
            height: 50px;
            .MuiInputBase-input {
                padding-left: 8px;
                color: #153862;
                padding: 0;
            }

            .MuiOutlinedInput-notchedOutline,
            .MuiTypography-label {
                display: none;

                legend {
                    display: none;
                }
            }

            .MuiOutlinedInput-notchedOutline {
            }
        }
        .MuiTypography-label {
            display: none;
        }
    }
`

export default CountryBox
