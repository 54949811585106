import { Box, Stack, ThemeProvider, Typography } from '@mui/material'
import { MainTemplate } from 'Components/Template/MainTemplate'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import { storeElement } from 'GlobalStores/StoreElement'
import { observer } from 'mobx-react-lite'

const ShowError = observer(({ list = [], children, toTranslate = true, noTranslate }) => {
    return (
        <ThemeProvider theme={storeElement.theme}>
            <Stack bgcolor={'#FFE7EB'} pt="15px" pb="15px" justifyContent={'center'} borderRadius="12px" mt="4px">
                {children
                    ? children
                    : list.map((item, key) => {
                          const isLast = list.length - 1 === key
                          return (
                              <Box textAlign={'center'} pl="30px" pr="30px" key={key}>
                                  <Box borderBottom={isLast ? '0' : '1px dashed red'} pb={!isLast ? '15px' : '0'} mb={!isLast ? '15px' : '0'}>
                                      {item.english || item.native ? (
                                          <Typography variant="error">
                                              {(toTranslate === true) & (item.english.indexOf('_') > -1) ? (
                                                  <T>{StoreTranslate.UseLanguage(item)}</T>
                                              ) : (
                                                  StoreTranslate.UseLanguage(item)
                                              )}
                                          </Typography>
                                      ) : (
                                          <Typography variant="error">{noTranslate ? item : <T>{item}</T>}</Typography>
                                      )}
                                  </Box>
                              </Box>
                          )
                      })}
            </Stack>
        </ThemeProvider>
    )
})

export default ShowError