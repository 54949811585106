import { Box, Grid, ThemeProvider, Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { observer } from 'mobx-react-lite'
import { customTheme } from './PersonalTheme'
import { DefaultButton, DefaultTextFields } from '../Forms'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isEmail, isUnicityID } from 'Helpers/StringHelpers'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import SuccessPaper from './SuccessPaper'
import { useSizeWindow } from 'Helpers/GlobalHelpers'

const validationEmailSchema = yup.object({
    emailAddress: yup.string('Enter your emailAddress').test('test-name', 'Enter Valid Email or UnicityID', value => {
        if (!isEmail(value) && !isUnicityID(value)) return false
        return true
    })
})

const EditEmail = observer(props => {
    const { width } = useSizeWindow()
    const translate = useTranslate()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [updateSuccess, setUpdateSuccess] = useState(false)
    const { userData } = props

    const formik = useFormik({
        initialValues: {
            emailAddress: userData.email || ''
        },
        validationSchema: validationEmailSchema,
        onSubmit: async values => {
            setLoading(true)
            const [res, err] = await StoreAuth.updateUserDetails(values)
            setLoading(false)
            if (err) alert(err)
            if (res) {
                setUpdateSuccess(true)
            }
        }
    })

    useEffect(() => {
        // if (updateSuccess) {
        //     setTimeout(() => {
        //         goBack()
        //     }, 3000)
        // }
    }, [updateSuccess])

    const goBack = () => {
        navigate(-1)
    }

    return !updateSuccess ? (
        <ThemeProvider theme={customTheme}>
            <div style={width >= 830 ? { margin: '0 60px' } : {}}>
                <Typography variant="h3" fontWeight={600} textAlign="center" mb={'0px'}>
                    <T>change_email</T>
                </Typography>
                <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
                    <Grid container mt={'44px'} rowSpacing={'16px'} columnSpacing={'30px'}>
                        <Grid item xs={12}>
                            <Box noValidate sx={{ maxWidth: '100%' }}>
                                <DefaultTextFields
                                    inputFontSize={width > 830 ? '24px' : '16px'}
                                    type="text"
                                    disabled
                                    label={translate('current_email')}
                                    value={userData.email}
                                />
                            </Box>
                            <Box mt={'20px'} noValidate sx={{ maxWidth: '100%' }}>
                                <DefaultTextFields
                                    inputFontSize={width > 830 ? '24px' : '16px'}
                                    label={translate('new_email')}
                                    autoComplete="off"
                                    id="emailAddress"
                                    name="emailAddress"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
                                    helpertext={formik.touched.emailAddress && formik.errors.emailAddress}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container mt={'40px'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <Grid
                            item
                            // xs={6}
                            // sm={6}
                            sx={{
                                display: 'flex',
                                justifyContent: 'end',
                                alignItems: 'center',
                                width: width <= 834 ? '100%' : '344px'
                            }}>
                            <DefaultButton
                                disabled={!(formik.isValid && formik.dirty && !loading)}
                                type="submit"
                                fullWidth={true}
                                titleText={translate('save')}
                                isloading={loading.toString()}
                                backgroundcolor={'#003B6F'}
                                width={'100%'}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sm={6}
                            mt={'20px'}
                            sx={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center'
                            }}>
                            <Typography
                                variant="action"
                                fontSize={16}
                                fontFamily={500}
                                onClick={() => {
                                    if (!loading) {
                                        goBack()
                                    }
                                }}
                                sx={{ cursor: 'pointer' }}>
                                <T>cancel</T>
                            </Typography>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </ThemeProvider>
    ) : (
        <div style={{ margin: '0 60px' }}>
            <SuccessPaper title={translate('check_your_email')} desc={translate('check_your_email_description')} />
        </div>
    )
})

export default EditEmail
