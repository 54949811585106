import { ServerEnv } from 'Configs/ServerEnv'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import HttpHelper from 'Services/HttpHelper'

export const GetFeelGreatProduct = async userStatus => {
    let options = {}
    if (userStatus) {
        options.params = {
            baStatus: userStatus
        }
    }

    const countriesUseProductsV4 = []
    countriesUseProductsV4.push('THA')

    const isUseProductV4 = countriesUseProductsV4.includes(StoreCountry.Country3())

    // const qs = new URLSearchParams(window.location.search)
    // if (qs.get('env') === 'dev' || /localhost/.test(window.location.href) || /fg-dev/.test(window.location.href)) {
    //     severEnv = ServerEnv.MemberCalls2Dev()
    // }
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/products-v2/feelGreat/${isUseProductV4 ? 'v4' : 'v3'}/publish/${StoreCountry.Country3()}`

    return await HttpHelper.Get({ url, options })
}
