import { observer } from 'mobx-react-lite'
import StoreLoader from 'GlobalStores/Cores/Elements/StoreLoader'
import { Box, LinearProgress, linearProgressClasses, Stack, ThemeProvider } from '@mui/material'
import styled from '@emotion/styled'
import './loading-bar.scss'
import { storeElement } from 'GlobalStores/StoreElement'

const LoadingBar = observer(props => {
    return (
        <ThemeProvider theme={storeElement.theme}>
            <BarLoaderStyled
                display={StoreLoader.IsWebReady() ? 'none' : 'flex'}
                justifyContent="center"
                alignItems={'center'}
                width={window.innerWidth}
                height={window.innerHeight}
                className={`${StoreLoader.IsWebReady() ? 'fade-out' : ''}`}
                zIndex={StoreLoader.IsWebReady() ? -1 : 9}>
                <Box maxWidth={'200px'} width="200px">
                    <BorderLinearProgress variant="determinate" value={StoreLoader.GetPercentSummary()} />
                </Box>
            </BarLoaderStyled>
        </ThemeProvider>
    )
})

const BarLoaderStyled = styled(Stack)`
    position: absolute;
    background-color: white;
    .fade-out {
        animation: fadeout 1s;
        opacity: 0;
        z-index: -1;
    }
`

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#5a8fc4'
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main
    }
}))

export default LoadingBar
