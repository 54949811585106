import { defaults, isString } from 'Utils/Utils'

/** Make a case-insensitive compare betweenn two string.
 * Only accept `String` type. Any other type of value the result always return`false`.
 * @param {string} stringA - first value of string.
 * @param {string} stringB - value of string need to compare with.
 * @return {boolean}
 * @example
 * caseInsensitiveCompare('CreditCard', 'creditcard') // true
 * caseInsensitiveCompare('Actived', 'Terminated') // false */
const caseInsensitiveCompare = (stringA, stringB) => {
    if (isString(stringA) && isString(stringB)) {
        return stringA.localeCompare(stringB, undefined, { sensitivity: 'accent' }) === 0
    } else {
        return false
    }
}
/** An alias version of `caseInsensitiveCompare()`.
 *
 * Make a case-insensitive compare betweenn two string.
 * Only accept `String` type. Any other type of value the result always return`false`.
 * @param {string} stringA - first value of string.
 * @param {string} stringB - value of string need to compare with.
 * @return {boolean}
 * @example
 * isEqualText('CreditCard', 'creditcard') // true
 * isEqualText('Actived', 'Terminated') // false */
const isEqualText = (stringA, stringB) => caseInsensitiveCompare(stringA, stringB)

/** Search partial string value from string target then return boolean result.
 * @param {string} text
 * @param {string} find
 * @return {boolean}
 */
const findString = (text, find, isMatchWholeWord = false) => {
    return defaults(text, '').search(isMatchWholeWord ? `/${find}\b/` : `${find}`) !== -1
}

/** Convert all value to string lowerCase
 * @param {*} value
 * @returns
 */
const lowerCase = value => defaults(value, '').toString().toLowerCase()

const isEmail = (value = null) => {
    // eslint-disable-next-line
    const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return emailRegex.test(value)
}

const isPhoneNumber = (value = null) => {
    // eslint-disable-next-line
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    return phoneRegExp.test(value)
}

const isUnicityID = (value = null) => {
    const numberRegex = /^()?\d{8,10}$/
    return numberRegex.test(value)
}

const formatPhoneNumber = (phoneNumber = '') => {
    // Get the input value
    let newFormatPhoneNumber = phoneNumber.replace(/\D/g, '')
    // Add the hyphens in the desired format
    if (newFormatPhoneNumber.length >= 3 && newFormatPhoneNumber.length <= 5) {
        newFormatPhoneNumber = newFormatPhoneNumber.replace(/(\d{3})(\d{0,3})/, '$1-$2')
    } else if (newFormatPhoneNumber.length >= 6 && newFormatPhoneNumber.length <= 8) {
        newFormatPhoneNumber = newFormatPhoneNumber.replace(/(\d{3})(\d{3})(\d{0,4})/, '$1-$2-$3')
    } else if (newFormatPhoneNumber.length > 8) {
        newFormatPhoneNumber = newFormatPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
    }

    // Update the input value
    return newFormatPhoneNumber
}

const isThaiAlphabet = (value = null) => {
    return /[ก-ฮ]/.test(value)
}

const isThaiWord = (value = null) => {
    return /^[ก-๙]+$/.test(value)
}

export { isEqualText, findString, lowerCase, isEmail, isPhoneNumber, isUnicityID, formatPhoneNumber, isThaiAlphabet, isThaiWord }
