import axios from 'axios'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import HttpHelper from 'Services/HttpHelper'
const domain = 'https://member-calls2.unicity.com'

const getFeelGreatProductsV3 = (balStatus = null) => {
    let queryStrings = []
    if (balStatus) {
        queryStrings.push(`baStatus=${balStatus}`)
    }

    const url = `${domain}/products-v2/feelGreat/v3/publish/${StoreCountry.Country3()}${queryStrings.length ? '?' + queryStrings.join('&') : ''}`

    return axios.get(HttpHelper.Proxy({ originalUrl: url }), {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const getReferralCodes = token => {
    const url = 'https://hydra.unicity.net/v5a/customers/me/referralcodes'
    return axios.get(HttpHelper.Proxy({ originalUrl: url }), {
        headers: {
            Authorization: `Bearer ${token}`,
            'X-APPLICATION': 'asia.ble.th'
        }
    })
}

const feelGreateAPI = { getFeelGreatProductsV3, getReferralCodes }

export default feelGreateAPI
