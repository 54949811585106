import React, { useEffect } from 'react'
import { ReactComponent as SuccessIcon } from './Assets/success.svg'
import { observer } from 'mobx-react-lite'
import { Stack, Typography, Box } from '@mui/material'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { runInAction } from 'mobx'
import StoreSubScriptions from 'GlobalStores/StoreSubScriptions'
import { storeSettingsElement } from 'GlobalStores/Settings/StoreSettingsElement'

const SkipSuccess = observer(props => {
    const translate = useTranslate()

    useEffect(() => {
        runInAction(() => {
            storeSettingsElement.cardTitle.titleStep1 = <T>subscriptions</T>
            storeSettingsElement.cardTitle.titleStep2 = <T>skip_next_delivery</T>
            storeSettingsElement.cardTitle.titleStep3 = <T>success</T>
            storeSettingsElement.settingCard.padding = '50px 40px 20px 40px'
            storeSettingsElement.settingCard.titleAlign = 'center'
        })

        return () => {
            runInAction(() => {
                storeSettingsElement.cardTitle.titleStep1 = ''
                storeSettingsElement.cardTitle.titleStep2 = ''
                storeSettingsElement.cardTitle.titleStep3 = ''
                storeSettingsElement.settingCard.padding = ''
                storeSettingsElement.settingCard.titleAlign = ''
            })
        }
    }, [])

    const date = StoreSubScriptions.GetNextDateDelivery()
    const dateFormat = new Date(date).toLocaleDateString('en-uk', { year: 'numeric', month: 'short', day: 'numeric' })

    return (
        <>
            <Stack justifyContent="center" flexDirection="row">
                <Typography variant="title3">
                    <T>skip_next_delivery</T>
                </Typography>
            </Stack>

            <Stack alignItems={'center'} justifyContent={'center'} textAlign="center" mt="190px">
                <SuccessIcon />

                <Box padding="0px 50px" mt="40px">
                    <Stack mb="15px" gap="10px">
                        <Typography variant="title">
                            <T>success_skip_delivery_title</T>
                        </Typography>

                        <Typography variant="title">
                            <T>success_skip_delivery_title2</T>
                        </Typography>
                    </Stack>

                    <Typography variant="body3" textAlign={'center'} mb="40px" color="#023764" lineHeight="24px">
                        {translate('success_skip_delivery_content').replace('{{date}}', dateFormat)}
                    </Typography>
                </Box>
            </Stack>
        </>
    )
})

export default SkipSuccess
