import React from 'react'
import Dialog from '@mui/material/Dialog'
import { Box, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ReactComponent as CloseIcon } from './Assets/close-icon.svg'
import StoreSubScriptions from 'GlobalStores/StoreSubScriptions'
import ChangeFlavor from '../Elements/ChangeFlavor'
import SendNow from '../Elements/SendNow'
import SkipNextDelivery from '../Elements/SkipNextDelivery'
import ChangeDate from '../Elements/ChangeDate'
import ChangePayment from '../Elements/ChangePayment'
import { useEffect } from 'react'
import { CreditCardConfigs } from 'Components/Elements/Forms/CreditCard/CreditCardConfigs'
import { runInAction } from 'mobx'
import ChangeAddress from '../Elements/ChangeAddress'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import ChangeProduct from './ChangeProduct'

const CustomDialogV2 = observer(props => {
    const { openDialog, setOpenDialog, type, products, dateOfmonth, flavorQTY, skipDate } = props

    const { width } = useSizeWindow()

    useEffect(() => {
        if (StoreCheckout.GetCreditCardForm().length === 0) {
            runInAction(() => (StoreCheckout.creditCard = CreditCardConfigs.Form))
        }
    }, [])

    useEffect(() => {
        runInAction(() => {
            StoreSubScriptions.fontSize = 12

            ClearState()
        })

        return () => {
            runInAction(() => {
                StoreSubScriptions.fontSize = ''
            })
        }
    }, [])

    const ClearState = () => {
        StoreCheckout.GetShippingForm().map(item => {
            return StoreCheckout.SetCheckoutFormValue(item.key, '')
        })
    }

    const ClearCredit = () => {
        StoreCheckout.GetCreditCardForm().map(item => {
            return StoreCheckout.SetCreditCard(item, '')
        })
    }

    const handleClose = () => {
        setOpenDialog(false)

        if (width <= 834) {
            window.scrollTo(0, StoreSubScriptions.scrollY)
        }

        if (type === 'change_flavor') {
            StoreSubScriptions.SetSelectedFlavor('')
        } else if (type === 'change_payment') {
            ClearCredit()
        } else if (type === 'change_address') {
            ClearState()
        }
    }

    const handleGetOrder = () => {
        props.getOrder()
    }

    return (
        <>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
                sx={{
                    '& .MuiDialog-container': {
                        '& .MuiPaper-root': {
                            width: '100%',
                            maxWidth: '430px',
                            borderRadius: '8px'
                        },

                        '& 	.MuiDialog-paper': {
                            margin: '10px'
                        }
                    }
                }}>
                <TopSection>
                    <IconClose onClick={handleClose} className="pointer-fg" />
                </TopSection>

                <Wrapper>
                    {type === 'change_flavor' && (
                        <ChangeFlavor
                            handleClose={handleClose}
                            handleGetOrder={handleGetOrder}
                            products={products}
                            dateOfmonth={dateOfmonth}
                            flavorQTY={flavorQTY}
                        />
                    )}

                    {type === 'change_product' && <ChangeProduct handleClose={handleClose} handleGetOrder={handleGetOrder} products={products} />}

                    {type === 'send_now' && <SendNow handleClose={handleClose} handleGetOrder={handleGetOrder} products={products} dateOfmonth={dateOfmonth} />}

                    {type === 'skip_next_delivery' && (
                        <SkipNextDelivery handleClose={handleClose} handleGetOrder={handleGetOrder} products={products} skipDate={skipDate} />
                    )}

                    {type === 'change_date' && (
                        <ChangeDate handleClose={handleClose} handleGetOrder={handleGetOrder} products={products} dateOfmonth={dateOfmonth} />
                    )}

                    {type === 'change_payment' && <ChangePayment handleClose={handleClose} handleGetOrder={handleGetOrder} products={products} />}

                    {type === 'change_address' && <ChangeAddress handleClose={handleClose} handleGetOrder={handleGetOrder} products={products} />}
                </Wrapper>
            </Dialog>
        </>
    )
})

const TopSection = styled(Box)`
    position: absolute;
    top: 10px;
    right: 14px;

    .pointer-fg {
        cursor: pointer;
    }
`

const IconClose = styled(CloseIcon)`
    path {
        stroke: #153862;
    }
`

const Wrapper = styled(Box)`
    padding: 30px 35px;

    @media (max-width: 428px) {
        padding: 30px 20px;
    }
`

export default CustomDialogV2
