import { makeAutoObservable, toJS } from 'mobx'
import StoreReferralFormat from './StoreReferralFormat'
import { get } from 'lodash'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import storeUser from 'GlobalStores/User/StoreUser'
import StoreReferralProducts from './StoreReferralProducts'
import storeAuth from 'GlobalStores/User/StoreAuth'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import storeReferral from './StoreReferral'

class StoreReferralCart {
    items = []
    totalPrice = 0

    summary = {
        items: [],
        sub_total: 0,
        shipping: 0,
        total: 0,
        saving: {
            member_discount: 0,
            subscription_discount: 0
        }
    }

    increaseQty(id, qty = 1) {
        const selectItem = this.items.find(item => item.id === id)
        const isSingle = selectItem.type === 'single'
        const qty_sellable = get(selectItem, ['options', isSingle ? 'onetime' : StoreReferralFormat.paymentOptionSelected, 'qty'])
        this.items = this.items.map(item => {
            if (item.id === id) {
                if (item.qty < qty_sellable) {
                    item.qty += qty
                }
            }
            return item
        })
    }

    decreaseQty(id, qty = 1) {
        const selectItem = this.items.find(item => item.id === id)
        const isSingle = selectItem.type === 'single'
        const itemsToRemove = []
        this.items = this.items.map(item => {
            if (item.id === id) {
                if (item.qty > 1) {
                    item.qty -= qty
                } else {
                    if (isSingle) {
                        if (item.qty > 0) {
                            item.qty -= qty
                        }
                    }
                }
                if (item.qty === 0) {
                    itemsToRemove.push(item.id)
                }
            }

            return item
        })

        itemsToRemove.forEach(id => {
            this.removeItem(id)
        })
    }

    assignQty(id, qty = 1) {
        this.items = this.items.map(item => {
            const isSingle = item.type === 'single'
            const qty_sellable = get(item, ['options', isSingle ? 'onetime' : StoreReferralFormat.paymentOptionSelected, 'qty'])
            if (item.id === id) {
                if (qty < qty_sellable) {
                    item.qty = qty
                } else {
                    item.qty = qty_sellable
                }
            }
            return item
        })
    }

    removeItem(id) {
        this.items = this.items.filter(item => item.id !== id)
    }

    addItem(item) {
        this.items.push(item)
    }

    getTotalItems() {
        let total = 0
        this.items.forEach(item => {
            total += item.qty
        })
        return total
    }

    getTotalPrice() {
        let total = 0

        const paymentOption = StoreReferralFormat.paymentOptionSelected

        this.items.forEach(item => {
            const isSingle = item.type === 'single'
            total += get(item, ['options', isSingle ? 'onetime' : paymentOption, 'price', this.hasDiscount() ? 'member' : 'retail'], 0) * item.qty
        })

        return total
    }

    getCartItems() {
        const items = this.items.map(item => {
            const isSingle = item.type === 'single'
            const item_code = get(item, ['options', StoreReferralFormat.paymentOptionSelected, 'item_code'], '')
            const qty = item.qty

            return {
                item_code: item_code,
                qty: qty,
                option: StoreReferralFormat.paymentOptionSelected
            }
        })

        return items
    }

    getTotalPV() {
        let total = 0

        const paymentOption = StoreReferralFormat.paymentOptionSelected

        this.items.forEach(item => {
            const isSingle = item.type === 'single'
            total += get(item, ['options', isSingle ? 'onetime' : paymentOption, 'pv'], 0) * item.qty
        })
        return total
    }

    hasDiscount() {
        const statusAllow = storeCountry.GetAllowDiscountStatus()
        const baStatus = storeUser.GetBaStatus()
        const hasReferral = false

        // TODO:
        // const hasReferral = storeReferral.HasReferral()

        if (statusAllow.includes(baStatus) || (hasReferral && baStatus !== 'C')) {
            return true
        }
        return false
    }

    TotalQty() {
        let total = 0
        this.items.forEach(item => {
            total += item.qty
        })
        return total
    }

    CartSummary = () => {
        this.summary = {
            items: [],
            sub_total: 0,
            shipping: 0,
            total: 0,
            saving: {
                member_discount: 0,
                subscription_discount: 0
            }
        }
        const hasDiscount = ['A', 'M'].includes(storeReferral?.referralData?.shortStatus) || ['A', 'M'].includes(storeAuth?.userStatus)
        const selectLang = StoreTranslate.IsEnglish() ? 'english' : 'native'
        const productItems = this.getCartItems()
        const allProduct = toJS(StoreReferralProducts.products)

        const findItemByCode = (itemCode, type = 'onetime') => {
            // onetime, subscribe
            return allProduct.find(item => {
                return item.options[type].item_code === itemCode
            })
        }

        const updatedCartItems = productItems.map(cartItem => {
            const foundItemOnetime = findItemByCode(cartItem.item_code, cartItem.option)
            const foundItemSubscribe = findItemByCode(cartItem.item_code, cartItem.option)

            if (foundItemOnetime && cartItem.option === 'onetime') {
                let item = get(foundItemOnetime, ['options', foundItemOnetime.type === 'single' ? 'onetime' : StoreReferralFormat.paymentOptionSelected])
                let member_discount = item.price.retail - item.price.member

                const priceShown = get(item, ['price', hasDiscount ? 'member' : 'retail'], 0)

                this.summary.saving.member_discount += member_discount * cartItem.qty
                this.summary.sub_total += Number(priceShown) * cartItem.qty

                return {
                    ...cartItem,
                    id: Number(foundItemOnetime.id),
                    name: item.item_name[selectLang],
                    price: priceShown
                }
            }

            if (foundItemSubscribe && cartItem.option === 'subscribe') {
                let item = get(foundItemSubscribe, ['options', foundItemSubscribe.type === 'single' ? 'onetime' : StoreReferralFormat.paymentOptionSelected])
                const priceShown = get(item, ['price', hasDiscount ? 'member' : 'retail'], 0)

                let item_onetime = get(foundItemSubscribe, ['options', 'onetime'])
                let member_discount = item_onetime.price.retail - item_onetime.price.member

                this.summary.sub_total += Number(priceShown) * cartItem.qty

                this.summary.saving.member_discount += member_discount * cartItem.qty
                this.summary.saving.subscription_discount += (item_onetime.price.member - item.price.member) * cartItem.qty

                return {
                    ...cartItem,
                    id: Number(foundItemSubscribe.id),
                    name: item.item_name[selectLang],
                    price: priceShown
                }
            }
        })

        if (this.summary.sub_total < 1500) {
            this.summary.shipping += 40
        }

        this.summary.items = updatedCartItems
        return this.summary
    }


    HasSystemTag = () => {
        return this.items.some(item => item?.options[StoreReferralFormat.paymentOptionSelected]?.system_tags?.length > 0)
    }

    CheckOutofStockCartItems() {
        // return true mean out of stock

        if (this.items?.length > 0) {

            return this.items.some(item => {
                const optionSelected = item?.options?.[StoreReferralFormat.paymentOptionSelected]
                const isFoundOOSTag = optionSelected?.system_tags?.some(tag => tag.name === 'out_of_stock')
                return isFoundOOSTag
            })

        } else {
            return false
        }
    }

    GetOutOfStockItems() {
        return this.items.filter(item => {
            const optionSelected = item?.options?.[StoreReferralFormat.paymentOptionSelected]
            const isFoundOOSTag = optionSelected?.system_tags?.some(tag => tag.name === 'out_of_stock')
            return isFoundOOSTag
        })
    }

    IsOutOfStockItem(itemId) {
        const targetItem = this.items.find(item => item.id === itemId)
        if (targetItem) {
            return targetItem?.options[StoreReferralFormat.paymentOptionSelected]?.system_tags?.some(tag => tag.name === "out_of_stock")
        } else {
            return false
        }
    }


    constructor() {
        makeAutoObservable(this)
    }
}

export default new StoreReferralCart()
