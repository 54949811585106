import React, { useEffect, useState } from 'react'
import { ReactComponent as FaceIcon } from '../Assets/icon-face-yellow.svg'
import { observer } from 'mobx-react-lite'
import { Stack, Typography, styled, Box } from '@mui/material'
import { DefaultButton } from '../../Forms'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as SpinnerIcon } from '../Assets/button-icon-spinner.svg'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { runInAction } from 'mobx'
import { storeSettingsElement } from 'GlobalStores/Settings/StoreSettingsElement'

const CancelSubscriptionsMobile = observer(props => {
    const [loadingButton, setLoadingButton] = useState(false)
    const [loading, setLoading] = useState(false)

    const translate = useTranslate()
    const navigate = useNavigate()

    useEffect(() => {
        runInAction(() => {
            storeSettingsElement.cardTitle.titleStep1 = <T>subscriptions</T>
            storeSettingsElement.settingCard.padding = '40px 30px'
        })

        return () => {
            runInAction(() => {
                storeSettingsElement.cardTitle.titleStep1 = ''
                storeSettingsElement.settingCard.padding = ''
            })
        }
    }, [])

    const handleSkipNextDelivery = () => {
        setLoadingButton(true)
        setTimeout(() => {
            navigate('/user/subscriptions/skip-delivery')
            setLoadingButton(false)
        }, 1500)
    }

    const handleCancelDelivery = () => {
        setLoading(true)
        setTimeout(() => {
            navigate('/user/subscriptions/reason-cancel-subscriptions')
        }, 1500)
    }

    return (
        <Box position="relative">
            {(loading || loadingButton) && <CardDisable />}
            <Stack justifyContent="center" flexDirection="row">
                <Typography variant="title3" textAlign="center">
                    <T>header_cancel_subscription</T>
                </Typography>
            </Stack>

            <Stack alignItems={'center'} justifyContent={'center'} textAlign="center" mt="60px">
                <FaceIcon />

                <Box mt="40px" mb="40px">
                    <Box mb="20px">
                        <Typography variant="title2" color="#023764">
                            <T>cancel_subscription_title</T>
                        </Typography>
                    </Box>

                    <Typography variant="caption5" textAlign={'center'} color="#023764">
                        <T>cancel_subscription_content</T>
                    </Typography>
                </Box>

                <DefaultButton
                    type="submit"
                    fullWidth={true}
                    titleText={translate('skip_next_delivery_button')}
                    width="100%"
                    onClick={() => handleSkipNextDelivery()}
                    isloading={loadingButton.toString()}
                    fontSize="20px"
                    fontWeight="400"
                />

                <Typography onClick={() => handleCancelDelivery()} variant="link" color="#003B6F" fontWeight={500} mt="30px" height="30px">
                    {loading ? <ButtonIconSpinner /> : <T>take_to_cancel</T>}
                </Typography>
            </Stack>
        </Box>
    )
})

const CardDisable = styled(Box)`
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffff;
    opacity: 65%;
    border-radius: 12px;
    z-index: 1;
`

const ButtonIconSpinner = styled(SpinnerIcon)`
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    circle {
        stroke: #079ff4;
    }
    path {
        stroke: #c7ddfb;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`

export default CancelSubscriptionsMobile
