import SelectCountryModal from './SelectCountryModal'
import { AppBar, Box, Container, ThemeProvider, Toolbar } from '@mui/material'
import { observer } from 'mobx-react-lite'
import StoreCountry from '../../../GlobalStores/Cores/County/StoreCountry'
import { MainTemplate } from '../../Template/MainTemplate'
import { ReactComponent as LogoIcon } from './Assets/logo-wo-tm.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { indexOf } from 'lodash'
import { useProfile } from './HeaderHook'
import ProfileButton from './ProfileButton'
import { storeElement } from 'GlobalStores/StoreElement'
import storeReferral from 'Components/Pages/Referral/Thailand/Stores/StoreReferral'

const headerHasShowdowOnPages = ['/products', '/checkout']

const Header = observer(() => {
    const navigate = useNavigate()
    const location = useLocation()
    // const flags = require.context('./Assets/flags', true)

    const [showShadow, setShowShadow] = useState(false)

    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const profile = useProfile()

    useEffect(() => {
        if (indexOf(headerHasShowdowOnPages, location.pathname) !== -1) {
            setShowShadow(true)
        } else {
            setShowShadow(false)
        }
    }, [location])

    const Padding = width => {
        if (width < 900) {
            return 20
        } else if (width >= 900 && width < 1920) {
            return 30
        } else {
            return 0
        }
    }

    const onLogoClick = () => {
        if (/login/.test(window.location.pathname)) {
            if (storeReferral.HasReferral()) {
                navigate('/')
            } else {
                navigate('/login')
            }
        } else {
            navigate('/')
        }
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            <AppBar position="relative" color={'secondary'} className={`${showShadow ? 'has-shadow' : ''}`} id="header">
                <Container
                    maxWidth="xl"
                    style={{
                        paddingLeft: Padding(storeElement.dynamicWidth),
                        paddingRight: Padding(storeElement.dynamicWidth),
                        height: '75px',
                        alignItems: 'center',
                        display: 'flex'
                    }}>
                    <Toolbar
                        disableGutters
                        className="logo"
                        style={{ height: '75px', paddingTop: '0px', paddingBottom: '0px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <h1
                            style={{
                                position: 'absolute',
                                left: '-1000px',
                                visibility: 'hidden'
                            }}>
                            Unicity
                        </h1>
                        <Box sx={{ flexGrow: 1, height: '100%' }} display="flex" alignItems={'center'}>
                            <LogoIcon onClick={() => onLogoClick()} style={{ cursor: 'pointer' }} />
                        </Box>

                        <Box sx={{ flexGrow: 0 }} alignItems="center" display={'flex'}>
                            <img
                                src={StoreCountry.GetCountryFlag()}
                                alt={StoreCountry.CountryCapitalize()}
                                style={{ marginRight: '15px', cursor: 'pointer', borderRadius: '3px' }}
                                onClick={handleOpen}
                            />

                            {/* {!!profile.userData && (
                                <Typography
                                    variant="h6"
                                    mr={'15px'}
                                    sx={{
                                        color: '#003764',
                                        fontSize: '10px !important',
                                        fontWeight: 600
                                    }}>
                                    {NumberHelpers.currency({
                                        number: StoreCheckout.TotalPrice(),
                                        currency: StoreCountry.GetCurrencyCode()
                                    })}
                                </Typography>
                            )} */}
                            <ProfileButton userData={profile.userData} />
                            <SelectCountryModal open={open} handleClose={handleClose} />
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </ThemeProvider>
    )
})

export default Header
