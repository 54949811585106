import InvitePage from 'Components/Pages/Invite/InvitePage'
import OrderHistoryPage from 'Components/Pages/OrderHistory/OrderHistoryPage'
import SubscriptionsPage from 'Components/Pages/Subscriptions/SubscriptionsPage'
import UserAccount from 'Components/Pages/UserAccount'
import PersonalDetailsWrapper from 'Components/Elements/Settings/Wrapper'

export const UserAccoutRoutes = {
    path: 'user',
    element: <UserAccount />,
    children: [
        {
            path: 'orderhistory',
            element: <OrderHistoryPage />
            // children: [
            //     {
            //         path: ':order_id',
            //         element: <OrderHistoryPage />
            //     }
            // ]
        },
        {
            path: 'orderhistory/:order_id',
            element: <OrderHistoryPage />
        },
        {
            path: 'subscriptions/*',
            element: <SubscriptionsPage />
        },
        {
            path: 'invite',
            element: <InvitePage />
        },
        {
            path: 'settings/*',
            element: <PersonalDetailsWrapper />
        }
    ]
}
