import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import { makeAutoObservable } from 'mobx'
import StoreReferralCart from './StoreReferralCart'
import storeFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'

class StoreCredit {
    availableBalance = 0
    creditType = null
    currency = null
    creditUsed = 0

    data = []

    cashCouponForm = {
        type: 'AuthorizeAndCapture',
        method: 'PocketAdjustment',
        methodDetails: {
            pocketCode: 'CashCoupon'
        },
        amount: 0
    }
    arBalanceForm = {
        type: 'AuthorizeAndCapture',
        method: 'StoreCredit',
        amount: 0
    }

    loading = false

    async Init({ id, token }) {
        try {
            this.loading = true
            const { data } = await FeelGreatAPI.GetCreditAndArBalance(id, token)

            this.availableBalance = data.availableBalance
            this.creditType = data.creditType
            this.currency = data.currency
            this.creditUsed = storeFeelGreat.IsAllowProductCredit() ? data.availableBalance : 0
            this.loading = false
        } catch (e) {
            // console.log(e)
            this.loading = false
        }
    }

    GetCreditData() {
        const total = StoreReferralCart.getTotalPrice()

        if (!storeFeelGreat.IsAllowProductCredit())
            return {
                availableBalance: this.availableBalance,
                creditType: this.creditType,
                currency: this.currency,
                creditUsed: 0
            }

        if (total && total < this.creditUsed) {
            return {
                availableBalance: this.availableBalance,
                creditType: this.creditType,
                currency: this.currency,
                creditUsed: total
            }
        }
        return {
            availableBalance: this.availableBalance,
            creditType: this.creditType,
            currency: this.currency,
            creditUsed: this.creditUsed
        }
    }

    SetCreditUsed(value) {
        this.creditUsed = Number(value)
    }

    ProductCreditUsed = () => {
        const productCredit = this.GetCreditData()

        if (productCredit.creditType === 'cashCoupon') {
            return { ...this.cashCouponForm, amount: productCredit.creditUsed }
        }
        if (productCredit.creditType === 'arBalance') {
            return { ...this.arBalanceForm, amount: productCredit.creditUsed }
        }
    }

    constructor() {
        makeAutoObservable(this)
    }
}

const storeCredit = new StoreCredit()
export default storeCredit
