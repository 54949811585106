import { get } from 'lodash'
import { makeAutoObservable, runInAction } from 'mobx'
import StoreReferralCart from './StoreReferralCart'
import StoreReferralFormat from './StoreReferralFormat'

class StoreReferralProducts {
    products = []
    defaultProducts = []

    isShowProducts = false

    isInit = false

    Init(data) {
        const productList = get(data, 'productList.items')
        StoreReferralFormat.paymentOptionSelected = get(data, 'purchaseOptionDefault', 'onetime')

        if (!productList) return

        runInAction(() => {
            this.products = productList
            const itemDefault = data.productList.defaultItem
            itemDefault.qty = 1
            // Dev only
            // itemDefault.options.subscribe.system_tags = [
            //     {
            //         "id": 13,
            //         "created_at": "2022-04-26T13:39:58.000Z",
            //         "created_by": "script",
            //         "updated_at": "2022-12-02T07:26:28.000Z",
            //         "updated_by": "Adminkarlo",
            //         "name": "out_of_stock",
            //         "is_system_tags": true,
            //         "sorting": null,
            //         "style": {
            //             "color": "#555555",
            //             "background_color": "#f47373"
            //         },
            //         "text": {
            //             "english": "LOW STOCK",
            //             "native": "เหลือน้อย"
            //         }
            //     }
            // ]
            StoreReferralCart.items = []
            StoreReferralCart.addItem(itemDefault)
            this.isInit = true
        })
    }

    GetIsInit() {
        return this.isInit
    }

    GetProducts() {
        return this.products
    }

    GetProductByItemCode(itemCode) {
        return this.products.find(product => product.id === itemCode)
    }

    SetProducts(products) {
        this.products = products
    }

    SetShowProducts(value) {
        this.isShowProducts = value
    }

    constructor() {
        makeAutoObservable(this)
    }
}

export default new StoreReferralProducts()
