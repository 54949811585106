import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import storeAuth from 'GlobalStores/User/StoreAuth'
import storeUser from 'GlobalStores/User/StoreUser'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import { makeAutoObservable, runInAction, toJS } from 'mobx'
import StoreReferralCart from './StoreReferralCart'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { capitalize, get } from 'lodash'
import { getOSName } from 'Helpers/Bowser'
import { PaymentAPIs } from './Checkout/PaymentServices/PaymentAPI'
import storeReferralCheckout from './StoreReferralCheckout'
import { storeElement } from 'GlobalStores/StoreElement'
import storeReferral from './StoreReferral'
import { isSomething } from 'Utils/Utils'
import StorePeriods from 'GlobalStores/StorePeriods'

class StoreFormAddress {
    typeFormAddress = 'addressHistory'
    displayMainAddress = {}
    addressHistorySelected = {}
    listAddress = []
    changeAddress = false
    loading = false
    passedToOrderSummary = false
    isInit = false

    constructor() {
        makeAutoObservable(this)
    }

    UpdateDisplayMainAddress() {
        this.displayMainAddress = this.GetAddressHistorySelected()
    }

    GetDisplayShipToName() {
        return this.displayMainAddress?.ushopShipToName
    }

    GetDisplayMainAddress() {
        return this.displayMainAddress?.displayAddress
    }

    GetDataMainAddress() {
        return this.displayMainAddress
    }

    GetListHistoryAddress() {
        return this.listAddress
    }

    SetAddressHistorySelected(obj) {
        this.addressHistorySelected = obj
    }

    GetAddressHistorySelected() {
        return this.addressHistorySelected
    }

    async GetAddressHistory() {
        runInAction(() => {
            this.loading = true
            storeElement.showCardFrontDrop = true
        })
        try {
            if (storeAuth.IsAuthorized()) {
                const response = await FeelGreatAPI.getAddressHistory()

                if (response.data) {
                    if (this.GetAddressHistorySelected().addressHref) {
                        this.displayMainAddress = this.GetAddressHistorySelected()
                    } else {
                        this.displayMainAddress = response.data.mainAddress
                    }

                    const filterItemsByCountry = response.data.items?.filter(item => item.shipToAddress?.country === storeCountry.Country2())

                    runInAction(() => {
                        if (filterItemsByCountry.length === 0) {
                            this.typeFormAddress = 'new'
                        }
                        this.listAddress = filterItemsByCountry
                        this.loading = false
                        storeElement.showCardFrontDrop = false
                    })
                }
            }
        } catch (err) {
            runInAction(() => {
                this.loading = false
                storeElement.showCardFrontDrop = false
            })
            console.log(err)
        }
    }

    async SaveAddressHistory(form) {
        try {
            const data = {
                shipToAddress: {
                    address1: `${form.house_number} ${form.street}`,
                    address2: `${form.sub_district} ${form.district}`,
                    city: form.province,
                    country: storeCountry.Country2(),
                    state: '',
                    zip: form.zip
                },
                shipToName: {
                    fullName: form.fullname,
                    firstName: form.fullname,
                    lastName: '',
                }
            }

            await FeelGreatAPI.saveAddressHistory(data.shipToName, data.shipToAddress)
        } catch (error) {
            console.log('ERROR ADD ADDRESS HISTORY', error)
        }
    }

    BindingTaxInvoice(isStatusM = false) {
        const taxInvoiceValue = toJS(storeReferralCheckout.GetTaxInvoiceValues())
        const dataTaxInvoice = {}
        dataTaxInvoice.tax_invoice = 1
        if (!storeAuth.IsAuthorized() || get(storeUser.CustomerData(), 'userData.taxTerms.taxId', '').length === 0 || isStatusM) {
            dataTaxInvoice.tax_invoice_type = capitalize(storeReferralCheckout.invoiceType)
            dataTaxInvoice.tax_invoice_zip_code = taxInvoiceValue.zip_code
            dataTaxInvoice.tax_invoice_province = taxInvoiceValue.province
            dataTaxInvoice.tax_invoice_district = taxInvoiceValue.district
            dataTaxInvoice.tax_invoice_sub_ditrict = taxInvoiceValue.sub_district
            dataTaxInvoice.tax_invoice_street = taxInvoiceValue.street
            dataTaxInvoice.tax_invoice_house_no = taxInvoiceValue.house_number

            if (storeReferralCheckout.invoiceType === 'individual') {
                dataTaxInvoice.tax_invoice_gov_id = storeReferralCheckout.invoiceGovernmentId
                dataTaxInvoice.tax_invoice_full_name = taxInvoiceValue.fullNameTh
                dataTaxInvoice.tax_invoice_full_name_en = taxInvoiceValue.fullNameEn
            }

            if (storeReferralCheckout.invoiceType === 'corporate') {
                dataTaxInvoice.tax_invoice_branch_no = taxInvoiceValue.branch_number
                dataTaxInvoice.tax_invoice_company_name = taxInvoiceValue.company_name
                dataTaxInvoice.tax_invoice_company_name_en = taxInvoiceValue.company_name_en
                dataTaxInvoice.tax_invoice_payer_no = storeReferralCheckout.invoiceTaxId
            }
        }

        return dataTaxInvoice
    }

    BindingReferralData() {
        const referralData = {
            referral_id: get(storeReferral.GetReferral(), 'baId', '').toString(),
            referral_name: JSON.stringify(storeReferral.DisplayName())
        }
        return referralData
    }

    async SubmitShippingForm(fromQR = false) {
        try {
            let periodResponse = isSomething(StorePeriods.periods) ? StorePeriods.periods : await FeelGreatAPI.GetPeriod(storeCountry.Country2())
            const productItems = StoreReferralCart.getCartItems()

            const formatType = 'shop'
            storeCheckout.CheckOutInstance().FormToFormat(formatType, storeCheckout.GetShippingForm())
            const formCheckout = storeCheckout.InitializeFormShippingData(formatType)

            const defaultDataValidate = {
                type: formatType,
                items: JSON.stringify(productItems),
                login_id: get(storeUser.CustomerData().userData, 'id.unicity', ''),
                login_name: storeUser.CustomerData().GetNativeName(storeUser.userData.humanName, 'fullName'),
                login_name_native: storeUser.CustomerData().GetNativeName(storeUser.userData.humanName, 'nativeName'),
                ba_status: get(storeAuth, 'userStatus', ''),
                period: periodResponse.entryPeriod || periodResponse.data.entryPeriod,
                medium: 'Internet',
                agent: 'uFeelgreat',
                platform: getOSName(),
                email: formCheckout.email,
                phone: formCheckout.phone,
                token: formCheckout.token,
                shipment_options: 'delivery',
                selectAddressHistory: this.typeFormAddress,
                fullname_en: formCheckout.fullname_en
            }

            let skipValidateTaxInvoice = true

            if (this.typeFormAddress === 'addressHistory') {
                const fullname = get(this.GetDataMainAddress(), 'ushopShipToName', '')

                const data = {
                    fullname: fullname,
                    shipToAddress: get(this.GetDataMainAddress(), 'shipToAddress', '')
                }
                Object.assign(defaultDataValidate, data)
            }

            if (this.typeFormAddress === 'new') {
                Object.assign(defaultDataValidate, formCheckout)
            }

            if (storeReferralCheckout.requestTaxInvoice === true) {
                const taxInvoiceData = this.BindingTaxInvoice(get(storeUser.GetBaStatus() === 'M'))
                Object.assign(defaultDataValidate, taxInvoiceData)
            }

            delete defaultDataValidate.address
            delete defaultDataValidate.city
            delete defaultDataValidate.state
            delete defaultDataValidate.country
            delete defaultDataValidate.uuid

            let responseCreateUuid = await PaymentAPIs.CreateUuid(defaultDataValidate, storeCountry.Country3(), skipValidateTaxInvoice)

            if (responseCreateUuid.data.success === true) {
                if (this.typeFormAddress === 'new' && !fromQR) {
                    await this.SaveAddressHistory(formCheckout)
                }
                runInAction(() => {
                    storeReferralCheckout.SetUuid(responseCreateUuid.data.uuid)
                    this.passedToOrderSummary = true
                })
            }

            if (responseCreateUuid) {
                return responseCreateUuid.data
            }
        } catch (err) {
            // console.log('ERROR SubmitShippingForm', err)
            throw err
        }
    }

    async SubmitCreateAccountForm() {
        try {
            const periodResponse = await FeelGreatAPI.GetPeriod(storeCountry.Country2())
            const productItems = StoreReferralCart.getCartItems()

            const formatType = 'enroll'
            storeCheckout.CheckOutInstance().FormToFormat(formatType, storeCheckout.GetCheckoutForms())
            storeCheckout.CheckOutInstance().FormToFormat('shop', storeCheckout.GetCheckoutForms())
            const formCheckout = storeCheckout.InitializeFormShippingData(formatType)

            const defaultDataValidate = {
                type: formatType,
                items: JSON.stringify(productItems),
                period: periodResponse.data.entryPeriod,
                medium: 'Internet',
                agent: 'uFeelgreat',
                platform: getOSName(),
                shipment_options: 'delivery'
            }

            if (storeReferral.HasReferral() && get(storeReferral.GetReferral(), 'baId', '')) {
                Object.assign(defaultDataValidate, this.BindingReferralData())
            }

            if (storeReferralCheckout.requestTaxInvoice === true) {
                if (storeReferralCheckout.requestTaxInvoice === true) {
                    const taxInvoiceData = this.BindingTaxInvoice(false)
                    Object.assign(defaultDataValidate, taxInvoiceData)
                }
            }

            Object.assign(defaultDataValidate, formCheckout)

            delete defaultDataValidate.fullName
            // delete defaultDataValidate.fullname
            delete defaultDataValidate.address
            delete defaultDataValidate.city
            delete defaultDataValidate.state
            delete defaultDataValidate.country
            delete defaultDataValidate.uuid

            let responseCreateAccountAndUuid = await PaymentAPIs.CreateAccountAndUuid(defaultDataValidate, storeCountry.Country3())

            if (responseCreateAccountAndUuid.data.success === true) {
                await storeAuth.Login({ unicityID: responseCreateAccountAndUuid.data.ba_id, password: formCheckout.password })
                storeAuth.baId = responseCreateAccountAndUuid.data.ba_id
                storeAuth.baStatus = responseCreateAccountAndUuid.baStatus
                storeAuth.password = formCheckout.password
                storeAuth.isLogin = true

                runInAction(() => {
                    storeReferralCheckout.SetUuid(responseCreateAccountAndUuid.data.uuid)
                    this.passedToOrderSummary = true
                })
            }

            if (responseCreateAccountAndUuid) {
                return responseCreateAccountAndUuid.data
            }
        } catch (err) {
            console.log('ERROR SubmitCreateAccountForm', err)
        }
    }

    async SubmitToCreateUUID() {
        try {
            let periodResponse = isSomething(StorePeriods.periods) ? StorePeriods.periods : await FeelGreatAPI.GetPeriod(storeCountry.Country2())
            const productItems = StoreReferralCart.getCartItems()

            const formatType = 'enroll'
            storeCheckout.CheckOutInstance().FormToFormat(formatType, storeCheckout.GetCheckoutForms())
            storeCheckout.CheckOutInstance().FormToFormat('shop', storeCheckout.GetCheckoutForms())
            const formCheckout = storeCheckout.InitializeFormShippingData(formatType)

            const defaultDataValidate = {
                type: formatType,
                items: JSON.stringify(productItems),
                period: isSomething(StorePeriods.periods) ? StorePeriods.periods.entryPeriod : periodResponse.data.entryPeriod,
                medium: 'Internet',
                agent: 'uFeelgreat',
                platform: getOSName(),
                shipment_options: 'delivery',
                selectAddressHistory: 'new'
            }

            if (storeReferral.HasReferral() && get(storeReferral.GetReferral(), 'baId', '')) {
                Object.assign(defaultDataValidate, this.BindingReferralData())
            }

            if (storeReferralCheckout.requestTaxInvoice === true) {
                const taxInvoiceData = this.BindingTaxInvoice(false)
                Object.assign(defaultDataValidate, taxInvoiceData)
            }

            Object.assign(defaultDataValidate, formCheckout)

            delete defaultDataValidate.fullName
            // delete defaultDataValidate.fullname
            delete defaultDataValidate.address
            delete defaultDataValidate.city
            delete defaultDataValidate.state
            delete defaultDataValidate.country
            delete defaultDataValidate.uuid

            let responseCreateAccountAndUuid = await PaymentAPIs.CreateUuid(defaultDataValidate, storeCountry.Country3(), true)

            if (responseCreateAccountAndUuid.data.success === true) {
                runInAction(() => {
                    storeReferralCheckout.SetUuid(responseCreateAccountAndUuid.data.uuid)
                    this.passedToOrderSummary = true
                })
            }

            if (responseCreateAccountAndUuid) {
                return responseCreateAccountAndUuid.data
            }
        } catch (err) {
            console.log('ERROR SubmitCreateAccountForm', err)
        }
    }

    async SubmitCreateAccountOnly({ debug = null }) {
        try {
            const periodResponse = await FeelGreatAPI.GetPeriod(storeCountry.Country2())

            const formatType = 'enroll'
            storeCheckout.CheckOutInstance().FormToFormat(formatType, storeCheckout.GetCheckoutForms())
            storeCheckout.CheckOutInstance().FormToFormat('shop', storeCheckout.GetCheckoutForms())
            const formCheckout = storeCheckout.InitializeFormShippingData(formatType)

            let defaultDataValidate = {
                type: formatType,
                period: periodResponse.data.entryPeriod,
                medium: 'Internet',
                agent: 'uFeelgreat',
                platform: getOSName(),
                shipment_options: 'delivery',
                fullname: formCheckout.fullname,
                password: formCheckout.password,
                email: formCheckout.email,
                phone: formCheckout.phone,
                uuid: storeReferralCheckout.GetUuid(),
                district: formCheckout.district,
                street: formCheckout.street,
                house_number: formCheckout.house_number,
                province: formCheckout.province,
                zip: formCheckout.zip,
                sub_district: formCheckout.sub_district
            }

            // Tax Invoice Part
            if (storeReferralCheckout.requestTaxInvoice) {
                const dataTaxInvoice = toJS(storeReferralCheckout.GetTaxInvoiceValues())

                let payerNo = ''
                if (storeReferralCheckout.invoiceType === 'individual') {
                    dataTaxInvoice.government_id = storeReferralCheckout.invoiceGovernmentId
                    payerNo = dataTaxInvoice.government_id
                } else {
                    dataTaxInvoice.tax_id = storeReferralCheckout.invoiceTaxId
                    payerNo = dataTaxInvoice.tax_id
                }
                let requestTaxInvoiceData = {
                    tax_invoice: 1,
                    tax_invoice_type: capitalize(storeReferralCheckout.invoiceType),
                    tax_invoice_zip_code: dataTaxInvoice.zip_code,
                    tax_invoice_province: dataTaxInvoice.province,
                    tax_invoice_district: dataTaxInvoice.district,
                    tax_invoice_sub_ditrict: dataTaxInvoice.sub_district,
                    tax_invoice_street: dataTaxInvoice.street,
                    tax_invoice_house_no: dataTaxInvoice.house_number
                }
                if (storeReferralCheckout.invoiceType === 'corporate') {
                    requestTaxInvoiceData.tax_invoice_branch_no = dataTaxInvoice.branch_number
                    requestTaxInvoiceData.tax_invoice_company_name = dataTaxInvoice.company_name
                    requestTaxInvoiceData.tax_invoice_company_name_en = dataTaxInvoice.company_name_en
                    requestTaxInvoiceData.tax_invoice_payer_no = payerNo
                } else {
                    requestTaxInvoiceData.tax_invoice_gov_id = payerNo
                    requestTaxInvoiceData.tax_invoice_full_name = dataTaxInvoice.fullName
                    requestTaxInvoiceData.tax_invoice_full_name_en = dataTaxInvoice.fullNameEn
                }
                defaultDataValidate = { ...defaultDataValidate, ...requestTaxInvoiceData }
            }
            // Tax Invoice Part

            if (storeReferral.HasReferral() && get(storeReferral.GetReferral(), 'baId', '')) {
                Object.assign(defaultDataValidate, this.BindingReferralData())
            }

            let responseCreateAccountAndUuid = await PaymentAPIs.CreateAccountAndUpdateUuid(defaultDataValidate, storeCountry.Country3(), debug)

            if (responseCreateAccountAndUuid) {
                return responseCreateAccountAndUuid.data
            }
        } catch (err) {
            console.log('ERROR SubmitCreateAccountForm', err)
        }
    }
}

const storeFormAddress = new StoreFormAddress()
export default storeFormAddress
