import { makeAutoObservable } from 'mobx'

class StorePeriods {
    periods = {}

    constructor() {
        makeAutoObservable(this)
    }
}

export default new StorePeriods()
