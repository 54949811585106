import { Stack, ThemeProvider } from '@mui/material'
import { DefaultButton } from 'Components/Elements/Forms'
import { MainTemplate } from 'Components/Template/MainTemplate'
import Email from 'GlobalStores/Email'
import { storeElement } from 'GlobalStores/StoreElement'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

const Test = observer(props => {
    return <Stack>
        Test 
    </Stack>
    // const [loader, setLoader] = useState(false)

    // const send = () => {
    //     try {
    //         setLoader(true)
    //         const sendEmail = new Email()

    //         const fullNameEnglish = 'eb ba'
    //         const fullNameNative = ''

    //         const isUseBothOfFullName = fullNameEnglish.trim() !== fullNameNative.trim()
    //         const shipToName = `${fullNameEnglish}${!isUseBothOfFullName ? ', ' + fullNameNative : ''}`

    //         sendEmail
    //             .SetToEmail('khanitnan.jansit@unicity.com')
    //             .SetOrderId(mockup.id.unicity)
    //             .SetMemberId(mockup.customer.id.unicity)
    //             .SetShipTo(shipToName, 'asdasdf asdf asdafdaf asdf')
    //             .SetProductItems(product)
    //             .SetShippingType('delivery')
    //             .SetAutoship(12345)

    //         if (StoreUser.CustomerData().ProfilePicture()) {
    //             sendEmail.SetProfilePicture(StoreUser.CustomerData().ProfilePicture('original'))
    //         }

    //         sendEmail.Send()
    //         setLoader(false)
    //     } catch (e) {
    //         setLoader(false)
    //     }
    // }

    // return (
    //     <ThemeProvider theme={storeElement.theme}>
    //         <Stack minHeight={'50vh'} alignItems="center" justifyContent={'center'}>
    //             <DefaultButton onClick={send} isloading={loader.toString()} titleText="test send email" style={{ width: 200 }} />
    //         </Stack>
    //     </ThemeProvider>
    // )
})

const product = {
    onetime: { flavor_0: { qty: 1, price: 195 }, flavor_1: { qty: 1, price: 195 } },
    subscribe: { flavor_0: { qty: 1, price: 170 }, flavor_1: { qty: 1, price: 170 } }
}

const mockup = {
    market: 'AU',
    notes: '542a74bf-8992-4f36-88b0-1386c1cbc75b|800001606|https://fg.unicity.com/australia/checkout/review?uuid=542a74bf-8992-4f36-88b0-1386c1cbc75b',
    shippingMethod: {
        type: 'Expedited',
        href: 'https://hydra.unicity.net/v5a/shippingmethods?type=Expedited',
        location: '',
        warehouse: { id: { unicity: '7' } }
    },
    shipToAddress: { country: 'AU', state: 'VIC', city: 'Box Hill', zip: '3128', address1: 'Via Media', address2: '' },
    shipToEmail: 'ldt14@unicity.com',
    shipToName: { firstName: 'thinh le', lastName: '' },
    shipToPhone: '8569745698',
    shipToTime: null,
    source: { agent: 'uFeelGreat', campaign: null, medium: 'Template', platform: 'Windows', referrer: null, version: null },
    type: 'Autoship',
    customer: {
        enroller: {
            href: 'https://hydra.unicity.net/v5a/customers/f0cbfab7062ed827a4e700264e473f1d',
            id: { unicity: 2 },
            humanName: { fullName: 'Referral Program' },
            email: 'cs@unicity.com'
        },
        href: 'https://hydra.unicity.net/v5a/customers/cc5a849659940a71eb800729c4c52e5a4b510a377c320dd3c587d5b14b32b2db',
        humanName: { firstName: 'thinh', lastName: 'le', fullName: 'thinh le', 'fullName@en': '' },
        id: { unicity: 100245165 },
        sponsor: {
            href: 'https://hydra.unicity.net/v5a/customers/f0cbfab7062ed827a4e700264e473f1d',
            id: { unicity: 2 },
            humanName: { fullName: 'Referral Program', firstName: 'Referral', lastName: 'Program' },
            email: 'cs@unicity.com'
        },
        status: 'Active',
        taxTerms: {},
        type: 'Customer'
    },
    lines: {
        items: [
            {
                item: { href: 'https://hydra.unicity.net/v5a/items/b85ad088d8f3f57cbc3099ce846397d4', id: { unicity: '34534' } },
                quantity: 1,
                terms: { priceEach: 236, subtotal: 236, discount: { amount: 0 } },
                quantityDetails: { quantityBackordered: 0 }
            }
        ]
    },
    terms: {
        discount: { amount: 0 },
        freight: { amount: 0 },
        period: '2023-01',
        pv: 90,
        subtotal: 236,
        tax: { amount: 0 },
        taxableTotal: 0,
        total: 236,
        discountDisplay: { amount: 0 }
    },
    dateCreated: '2023-01-16T11:11:43-07:00',
    currency: 'AUD',
    giftReceipt: false,
    taxedAs: 'goods',
    added_lines: { items: [] },
    id: { unicity: '65-416185365' },
    href: 'https://hydra.unicity.net/v5a/orders/35e960ce25f719c6d12819cc35daab6c1df564317a5b884bbac2fb1b89d5523c',
    transactions: {
        items: [
            {
                amount: 236,
                billToAddress: { country: 'AU' },
                billToEmail: null,
                billToName: null,
                billToPhone: null,
                installments: null,
                method: 'CreditCard',
                methodDetails: {
                    creditCardExpires: '2025-07',
                    payer: 'thinh',
                    payerId: null,
                    creditCardNumberMasked: '441770xxxxxx5974',
                    creditCardNetwork: 'visa'
                },
                type: 'AuthorizeAndCapture',
                index: 1,
                authorization: '7979312669',
                id: { teller: 2028410 }
            }
        ],
        aggregate: { amount: 236 }
    }
}

export default Test
