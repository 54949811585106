import { createTheme } from '@mui/material'

const palette = {
    primary: {
        main: '#0B3B6F'
    },
    secondary: {
        main: '#33628C',
        disabled: '#FFFFFF'
    },
    muted: {
        main: '#8A9BB0'
    },
    info: {
        main: '#0BA4D4'
    },
    strike: {
        main: '#FF820E'
    },
    link: {
        main: '#153862'
    },
    background: {
        default: '#D1D9E2',
        main: '#D1D9E2'
    },
    button: {
        main: 'rgba(7, 159, 244, 1)',
        disabled: 'rgba(204, 222, 239, 1)'
    },
    black: {
        main: '#000000'
    },
    light: {
        main: '#FFFFFF'
    },
    dark: {
        main: '#000000'
    }
}

export const DefaultReferralTheme = createTheme({
    palette: palette,
    typography: {
        fontFamily: 'NotoSansThai, Popppins',
        h2: {
            fontSize: '24px',
            fontWeight: 700,
            color: palette.primary.main,
            fontStyle: 'normal',
            lineHeight: '28px'
        },
        h3: {
            fontSize: '16px',
            fontWeight: 700,
            color: palette.primary.main,
            lineHeight: '16px'
        },
        h4: {
            fontSize: '16px',
            fontWeight: 400,
            color: palette.primary.main,
            lineHeight: 'normal'
        },
        h5: {
            fontSize: '14px',
            fontWeight: 400,
            color: palette.primary.main,
            lineHeight: 'normal'
        },
        body1: {
            fontSize: '12px',
            fontWeight: 400,
            color: palette.primary.main,
            lineHeight: 'normal',
            fontFamily: 'NotoSansThai, Popppins'
        },
        body2: {
            fontFamily: 'NotoSansThai, Popppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '18px',
            color: '#0B3B61',
            '@media (max-width: 430px)': {
                fontSize: '16px'
            }
        },
        body3: {
            fontFamily: 'NotoSansThai, Popppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            color: '#0B3B61',
            '@media (max-width: 430px)': {
                fontSize: '14px'
            }
        },
        caption: {
            fontSize: '12px',
            fontWeight: 400,
            color: palette.primary.main,
            lineHeight: 'normal',
            fontFamily: 'NotoSansThai, Popppins'
        },
        caption2: {
            fontSize: '12px',
            fontWeight: 400,
            color: palette.primary.main,
            lineHeight: 'normal',
            fontFamily: 'NotoSansThai, Popppins'
        },
        caption3: {
            fontFamily: 'NotoSansThai, Popppin',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            color: '#023764',
            '@media (max-width: 430px)': {
                fontSize: '14px'
            }
        },
        caption4: {
            fontFamily: 'NotoSansThai, Popppin',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '14px',
            color: '#6687A2'
        },
        caption5: {
            fontFamily: 'NotoSansThai, Popppin',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '18px',
            color: '#003B6F',
            lineHeight: '25px'
        },
        caption7: {
            fontFamily: 'NotoSansThai, Popppin',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '10px',
            color: '#ffffff',
            opacity: 1
        },
        caption8: {
            fontFamily: 'NotoSansThai, Popppin',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '24px'
        },
        muted: {
            fontSize: '10px',
            fontWeight: 400,
            color: palette.muted.main,
            lineHeight: 'normal',
            fontFamily: 'NotoSansThai, Popppins'
        },
        strike: {
            fontSize: '10px',
            fontWeight: 400,
            color: palette.strike.main,
            lineHeight: 'normal',
            fontFamily: 'NotoSansThai, Popppins',
            textDecoration: 'line-through'
        },
        link: {
            fontWeight: 700,
            fontFamily: 'NotoSansThai, Popppins',
            color: palette.link.main,
            textDecoration: 'underline'
        },
        link2: {
            fontWeight: 500,
            fontSize: '14px',
            color: palette.link.main,
            textDecoration: 'underline'
        },
        error: {
            fontFamily: 'NotoSansThai, Popppins',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            color: '#FE5D7C'
        },
        label: {
            fontFamily: 'NotoSansThai, Popppins',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '14px!important'
        },
        link4: {
            fontFamily: 'NotoSansThai, Popppins',
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '24px'
        },
        title: {
            fontFamily: 'NotoSansThai, Popppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '18px',
            color: '#023764',
            lineHeight: '27px'
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    '&.has-shadow': {
                        boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)'
                    }
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    borderRadius: 12,
                    '.MuiCardContent-root': {
                        padding: '20px'
                    },
                    '&.products--product': {
                        borderRadius: '8px',
                        padding: '20px'
                    },
                    '&.card--with-picture': {
                        border: 'none',
                        boxShadow: 'none',
                        borderRadius: 0,
                        '@media (max-width: 768px)': {
                            paddingLeft: 15,
                            paddingRight: 15
                        }
                    },
                    '&.card--form': {
                        border: 'none',
                        boxShadow: '0px 1px 2px rgba(14, 35, 56, 0.2)',
                        borderRadius: 12
                    },
                    '&.card--products': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none'
                    },
                    '&.card--payment': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none'
                    },
                    '&.card--menu': {
                        backgroundColor: '#003b6f',
                        borderRadius: 12,
                        minHeight: 200,
                        height: 'fit-content'
                    }
                }
            }
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    width: '380px',
                    boxShadow: 'none',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    borderRadius: '8px',
                    background: '#5A8FC3',
                    boxShadow: 'none',
                    fontFamily: 'NotoSansThai, Popppins!important',
                    '&:hover': {
                        boxShadow: 'none'
                    },
                    '&.Mui-disabled': {
                        color: '#CCCCCC'
                    }
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none'
                    }
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: '#E9F1FA'
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    '&.MuiFromControlLabel-label': {
                        fontFamily: 'NotoSansThai, Popppins'
                    }
                }
            }
        },
        MuiPopper: {
            styleOverrides: {
                root: {
                    '.MuiPaper-root': {
                        '.MuiTypography-root': {
                            textAlign: 'left',
                            '&.MuiTypography-caption': {
                                fontSize: '14px'
                            }
                        }
                    }
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '.MuiInputBase-input': {
                        fontSize: '16px!important',
                        color: '#000000!important'
                    }
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    fontFamily: 'NotoSansThai, Popppins'
                }
            }
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    fontFamily: 'NotoSansThai, Popppins'
                }
            }
        }
    }
})
