import storeReferral from 'Components/Pages/Referral/Thailand/Stores/StoreReferral'
import StoreReferralProducts from 'Components/Pages/Referral/Thailand/Stores/StoreReferralProducts'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import StoreFeelGreat from 'GlobalStores/FeelGreat/StoreFeelGreat'
import StoreProducts from 'GlobalStores/StoreProducts'
import StoreSubScriptions from 'GlobalStores/StoreSubScriptions'
import { autorun, makeAutoObservable, runInAction, toJS } from 'mobx'

let order = {
    cart_message: { native: '', english: '' },
    cart_limit_qty: { native: '3', english: '3' },
    cart_message_2: {
        native: 'Not for Resale',
        english: 'Not for Resale'
    },
    cart_message_3: {
        native: 'The products listed on Unicity Express are not intended for resale. By submitting your order, you certify that you are purchasing the products for personal use only. ',
        english:
            'The products listed on Unicity Express are not intended for resale. By submitting your order, you certify that you are purchasing the products for personal use only. '
    },
    minimum_set_qty: { native: '3', english: '3' },
    cart_limit_price: { native: '700', english: '700' },
    minimum_item_qty: { native: '10', english: '10' },
    cart_limit_qty_total: { native: '', english: '' },
    cart_warning_qty_total: { native: '', english: '' },
    cart_warning_price_total: { native: '', english: '' },
    cart_warning_qty_per_item: { native: '', english: '' }
}

class StoreLoader {
    dictionary = false
    formatApi = true

    isWebReady = false

    mainApiLoaded = {
        country: false,
        settings: false,
        refreshToken: false
    }

    orderApiReady = false
    /**
     * -1 = not used
     * 0 = not started
     * 1 = starting
     * 2 = loaded
     * 3 = failed
     */
    orderApiProgress = {
        submitPayment: 0,
        hydraCreateAccount: 0,
        login: 0,
        createOrder: 0,
        createAutoship: 0
    }

    orderApiProgressKeyToDictionary = {
        submitPayment: 'processing_payment',
        hydraCreateAccount: 'processing_membership',
        login: 'logging_in',
        createOrder: 'preparing_order',
        createAutoship: 'processing_subscription',
    }

    IsApiReady() {
        return this.orderApiReady
    }

    GetOrderApisPercentSummary() {
        let percent = 0
        let totalMainApi = 0
        Object.keys(this.orderApiProgress).map(key => {
            if (this.orderApiProgress[key] !== -1) {
                totalMainApi++
            }
        })
        Object.keys(this.orderApiProgress).map(key => {
            if (this.orderApiProgress[key] === 2) {
                percent += 100 / totalMainApi
            }
        })

        return percent
    }

    GetOrderApisPercentBuffer() {
        let percent = 0
        let totalMainApi = 0
        Object.keys(this.orderApiProgress).map(key => {
            if (this.orderApiProgress[key] !== -1) {
                totalMainApi++
            }
        })
        Object.keys(this.orderApiProgress).map(key => {
            if (this.orderApiProgress[key] === 1) {
                percent += 10 / totalMainApi
            }
        })

        return percent
    }

    IsOrderApisHasFailed() {
        let hasFailed = false
        Object.keys(this.orderApiProgress).map(key => {
            if (this.orderApiProgress[key] === 3) {
                hasFailed = true
            }
        })

        return hasFailed
    }

    SetApiLoaded(key, value) {
        this.mainApiLoaded[key] = value
    }

    IsMainApiLoaded(key) {
        return this.mainApiLoaded[key]
    }

    IsWebReady() {
        return this.isWebReady
    }

    GetPercentSummary() {
        let percent = 0
        let totalMainApi = Object.keys(this.mainApiLoaded).length
        Object.keys(this.mainApiLoaded).map(key => {
            if (this.mainApiLoaded[key] === true) {
                percent += 100 / totalMainApi
            }
        })

        return percent
    }

    SetLoadFormatApi(load) {
        runInAction(() => {
            this.formatApi = load
        })
    }

    IsFormatApiLoading() {
        return this.formatApi
    }

    InitAfterLoadSetting(settingResponseData, subscriptionConfigData) {
        // settingResponseData['order'] = order
        if (StoreFeelGreat.loginFrom === 'referral') {
            let products = {}
            settingResponseData.productList.items.map((item, key) => {
                products[`flavor_${key}`] = item.options
            })
            StoreProducts.Init(settingResponseData.productList.flavors, products)
        } else {
            let products = {}
            settingResponseData.productList.items.map((item, key) => {
                products[`flavor_${key}`] = item.options
            })
            StoreProducts.Init(settingResponseData.flavors, products)

            // older version
            // StoreProducts.Init(settingResponseData.flavors, settingResponseData.products)
        }
        StoreFeelGreat.Init(settingResponseData)
        StoreCheckout.Init(settingResponseData)
        StoreSubScriptions.Init(settingResponseData)
        StoreReferralProducts.Init(settingResponseData)
        storeReferral.SetSubscriptionConfigData(subscriptionConfigData)
    }

    constructor() {
        this.mainApiLoaded['dictionary'] = false
        makeAutoObservable(this)
        autorun(disposer => {
            if (this.GetPercentSummary() >= 100) {
                setTimeout(() => {
                    runInAction(() => {
                        this.isWebReady = true
                    })
                }, 1000)
                disposer.dispose()
            }
        })
    }
}

export default new StoreLoader()
