import loadable from '@loadable/component'
import { ThemeProvider } from '@mui/material'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import { storeElement } from 'GlobalStores/StoreElement'

const ReferralQRPaymentPage = () => {
    return (
        <ThemeProvider theme={storeElement.theme}>
            <DynamicCountry />
        </ThemeProvider>
    )
}

const DynamicCountry = loadable(() => import(`./${storeCountry.CountryCapitalize()}/QRPaymentPage`))

export default ReferralQRPaymentPage
