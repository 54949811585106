import ResetPassword from 'Components/Pages/ResetPassword'
import ReferralCheckoutPage from '../ReferralCheckoutPage'
import ReferralMainPage from '../ReferralMainPage'
import PaymentResponseSuccessPage from '../ReferralPaymentResponseSuccessPage'
import ReferralQRPaymentPage from '../ReferralQRPaymentPage'
import TaxInvoice from './Components/TaxInvoice/TaxInvoice'
import Login from 'Components/Pages/Login'
import { Navigate } from 'react-router-dom'
import ResetPasswordSuccess from 'Components/Pages/ResetPasswordSuccess'
import { Stack } from '@mui/material'
import Test from 'Components/Pages/Test'

export const ThaiReferralRouter = [
    {
        path: '/',
        element: <ReferralMainPage />,
        children: [
            {
                path: ':customerId',
                element: <ReferralMainPage />
            },
            {
                path: 'checkout/payment',
                element: <ReferralCheckoutPage />
            },
            {
                path: 'checkout/qrpayment',
                element: <ReferralQRPaymentPage />
            },
            {
                path: 'tax-invoice',
                element: <TaxInvoice />
            },
            {
                path: 'payment-response/success',
                element: <PaymentResponseSuccessPage />
            },
            {
                path: 'reset-password',
                element: <ResetPassword />
            },
            {
                path: 'reset-password-success',
                element: <ResetPasswordSuccess type={'success'} />
            },
            {
                path: 'reset-password-error',
                element: <ResetPasswordSuccess type={'error'} />
            },
            {
                path: 'products',
                element: <Navigate to={'/'} replace />
            },
            {
                path: 'login',
                element: <Login nosignup={true} />,
                children: []
            }
        ]
    },
    {
        path: 'reset-password',
        element: <ResetPassword />
    },
    {
        path: 'reset-password-success',
        element: <ResetPasswordSuccess type={'success'} />
    },
    {
        path: 'reset-password-error',
        element: <ResetPasswordSuccess type={'error'} />
    },
    {
        path: '*',
        element: (
            <Stack bgcolor={'#d2d9e1'} minHeight={'calc(100vh - 137px)'}>
                <Navigate to="/" />
            </Stack>
        ),
        children: []
    }
]
