import { Box, Modal, Stack, Typography, styled } from '@mui/material';
import { DefaultButton } from 'Components/Elements/Forms';
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { observer, useLocalObservable } from 'mobx-react-lite';
import userAPI from 'GlobalStores/User/APIs';
import Errors from 'Components/Pages/Referral/Thailand/Components/Errors';
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import StoreAuth from 'GlobalStores/User/StoreAuth';

const ModalConfirmDeleteAccount = observer(() => {

    const localObserver = useLocalObservable(() => ({
        open: false,
        error: {
            isShow: false,
            messages: []
        },
        confirmLoading: false
    }))

    const translate = useTranslate()
    const navigate = useNavigate()

    const handleOpen = () => {
        localObserver.error.isShow = false
        localObserver.error.messages = []
        localObserver.confirmLoading = false

        localObserver.open = true
    }

    const handleClose = () => {
        if (localObserver.confirmLoading === true) return
        localObserver.open = false
    };

    const handleConfirm = async () => {
        localObserver.confirmLoading = true

        // 0. Clear error
        localObserver.error.isShow = false
        localObserver.error.messages = []

        // 1. Prepare the data
        const reqData = {
            baId: StoreAuth.GetId(),
            countryCode3: StoreCountry.Country3(),
            hydraToken: StoreAuth.GetToken(),
        }

        // 2. Req API
        try {
            await userAPI.requestDeleteAccount(reqData)
            localObserver.confirmLoading = false
            navigate("delete-account-success")

        } catch (error) {
            console.log('requestDeleteAccount', error)
            localObserver.confirmLoading = false
            const errorMessages = error?.response?.data?.error?.messages || ["Delete Account is failed"]
            localObserver.error.isShow = true
            localObserver.error.messages = errorMessages
        }
    }
    return (
        <>
            <DefaultButton
                titleText={translate('delete_account_btn')}
                sx={{
                    width: '172px',
                    height: '35px',
                    fontSize: '14px',
                    backgroundColor: '#5A8FC4',
                    color: '#fff',
                    py: '9px',
                    px: '32px',
                    ":hover": { backgroundColor: '#5A8FC4' }
                }}

                onClick={handleOpen}
            />

            <Modal
                open={localObserver.open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <ModalBox>
                    <Stack
                        display={'block'}
                        position={'absolute'}
                        top={'15px'}
                        right={'15px'}
                        height={'10px'}
                        lineHeight={1}
                        onClick={handleClose}
                        sx={{ cursor: 'pointer' }}
                    >
                        <CloseIcon fill="#153862" fontSize='15px' />
                    </Stack>

                    <Stack justifyContent={"center"} alignItems={"center"} pb={'30px'}>
                        <Typography variant="h1" component="h1" fontSize={"24px"} lineHeight={'25px'} color={"#0B3B61"} fontWeight={"700"}>
                            <T>confirm_delete_account</T>
                        </Typography>
                    </Stack>


                    {localObserver.error.isShow ?
                        (
                            <Stack pb="15px">
                                <Errors list={localObserver.error.messages} />
                            </Stack>
                        ) : null
                    }


                    <Stack pb={'20px'}>
                        <DefaultButton
                            titleText={
                                <Typography variant="caption" component="span" fontSize={"20px"} fontWeight={"600"} color={"#fff"}>
                                    <T>yes</T>
                                </Typography>

                            }
                            sx={{
                                width: '100%',
                                height: '48px',
                                backgroundColor: '#5A8FC4',
                                py: '9px',
                                ":hover": { backgroundColor: '#5A8FC4' }
                            }}
                            isloading={(localObserver.confirmLoading).toString()}
                            disabled={localObserver.confirmLoading}
                            onClick={handleConfirm}
                        />
                    </Stack>

                    <Stack alignItems={"center"}>
                        <Typography variant="caption" component="span"
                            sx={{
                                color: "#003B6F",
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '24px',
                                textDecoration: "underline",
                                textUnderlineOffset: "2px",
                                cursor: "pointer"
                            }}
                            onClick={handleClose}
                        >
                            <T>cancel</T></Typography>
                    </Stack>

                </ModalBox>
            </Modal>
        </>
    )
})

export default ModalConfirmDeleteAccount


const ModalBox = styled(Box)`
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 335px;
    background-color: #fff;
    padding: 40px 20px;
    border-radius: 12px;

    &:focus-visible {
        outline: none;
    }
   

    @media screen and (min-width: 768px) {
      width: 430px;
      padding: 40px 40px;

    }

`