import loadable from '@loadable/component'
import { Stack, ThemeProvider } from '@mui/material'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import ReferralBar from './Thailand/Components/ReferralBar/ReferralBar'
import { DefaultReferralTheme } from './Theme/DefaultReferralTheme'
import storeAuth from 'GlobalStores/User/StoreAuth'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { IconSpinner } from 'Components/Elements/Spinner/IconSpinner'
import storeReferral from './Thailand/Stores/StoreReferral'
import ReferralLoginWrapper from './ReferralLoginWrapper'

const ReferralMainPage = observer(() => {
    const params = useParams()

    const conditionToRender = () => {
        return params.customerId || storeAuth.IsAuthorized() || storeReferral.HasReferral() || storeReferral.GetCheckedReferralParam()
    }

    return (
        <ThemeProvider theme={DefaultReferralTheme}>
            <ReferralLoginWrapper>
                <Stack minHeight={'calc(100vh - 137px)'} bgcolor={'#d2d9e1'}>
                    {conditionToRender() ? (
                        <>
                            <ReferralBar />
                            <DynamicCountry />
                        </>
                    ) : (
                        <Stack justifyContent={'center'} alignItems={'center'} minHeight={'calc(100vh - 137px)'}>
                            <IconSpinner />
                        </Stack>
                    )}
                </Stack>
            </ReferralLoginWrapper>
        </ThemeProvider>
    )
})

const DynamicCountry = loadable(() => import(`./${storeCountry.CountryCapitalize()}/MainPage`))

export default ReferralMainPage
