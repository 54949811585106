import styled from '@emotion/styled'
import { Autocomplete, Paper, TextField } from '@mui/material'
import axios from 'axios'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { storeElement } from 'GlobalStores/StoreElement'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'
import HttpHelper from 'Services/HttpHelper'
import DefaultInput from '../DefaultInput'
import { DefaultLabelTypo } from '../InputsStyled'
import { get, isEmpty } from 'lodash'
import specialCountryData from './specialCountryData.json'

const CustomPaper = props => {
    if (props.children[2]) {
        return (
            <Paper
                {...props}
                sx={{
                    marginY: '16px',
                    border: '1px solid #F1F5F8',
                    '.MuiAutocomplete-listbox': {
                        padding: '0px',
                        '.MuiAutocomplete-option': {
                            padding: '16px 16px',
                            fontFamily: 'Poppins, NotoSansThai',
                            fontWeight: 400,
                            fontSize: '12px',
                            color: '#153862'
                        },
                        '.Mui-focused': {
                            backgroundColor: '#ebf2f9 !important'
                        }
                    }
                }}
            />
        )
    }
    return <></>
}

const AutoAddress = observer(({ itemKeys, item = {}, inputCallback, selectedAddressCallback, onBlur }) => {
    const translate = useTranslate()

    const [autoAddressStates, setAutoAddressStates] = useState({
        searchValue: '',
        selectedValue: null,
        options: []
    })

    const autoCompleteInputRef = useRef()

    const GetAddress = async values => {
        if (values.length > 1) {
            setTimeout(() => {}, 1000)
            const country3 = StoreCountry.Country3()
            const isSpecialCountry = ['JPN'].includes(country3)
            const specialCountry = isSpecialCountry ? specialCountryData.find(item => item.key === country3) : null
            let country = !!specialCountry ? get(specialCountry, 'value', country3) : country3

            const url = `https://international-autocomplete.api.smartystreets.com/lookup?key=116245232163030886&search=${values}&country=${country}`
            let temp = []
            try {
                const response = await axios.get(HttpHelper.Proxy({ originalUrl: url }))

                response.data.candidates.map(v => {
                    let string = ''
                    itemKeys.map(key => {
                        const addressDetail = get(v, key, '')
                        string += `${addressDetail} `
                        // string += `${v[key]} `

                        return false
                    })

                    temp.push({
                        value: string.trim(),
                        data: v
                    })

                    return v
                })

                setAutoAddressStates({
                    ...autoAddressStates,
                    options: temp
                })
            } catch (e) {
                if (e.response.data.errors) {
                    inputCallback(values)
                }
            }
        }
    }

    return (
        <AutoAddressStyled>
            <label style={{ display: autoAddressStates.selectedValue === null && !item.value ? 'inherit' : 'none' }}>
                <DefaultLabelTypo variant="label" component="div" style={{ marginBottom: 8, paddingLeft: 16, color: 'rgba(21, 56, 98, 0.5)' }}>
                    <T>{item.label}</T>
                </DefaultLabelTypo>
                <Autocomplete
                    id="custom-input-demo"
                    freeSolo
                    options={autoAddressStates.options}
                    filterOptions={x => x}
                    getOptionLabel={option => (typeof option === 'string' ? option : option.value || '')}
                    renderInput={params => (
                        <TextField
                            fullWidth
                            type="text"
                            inputProps={{
                                ...params.InputProps,
                                'aria-autocomplete': 'none',
                                spellCheck: 'false'
                            }}
                            {...params}
                            onChange={e => {
                                setAutoAddressStates({
                                    ...autoAddressStates,
                                    searchValue: e.target.value
                                })
                                // TEST
                                GetAddress(e.target.value)
                            }}
                            autoCorrect="off"
                            inputRef={autoCompleteInputRef}
                            onBlur={e => {
                                if (autoAddressStates.searchValue.length === 0 && !autoAddressStates.selectedValue) {
                                    setAutoAddressStates({
                                        ...autoAddressStates,
                                        selectedValue: null,
                                        searchValue: ''
                                    })
                                    inputCallback('')
                                } else {
                                    if (autoAddressStates.selectedValue === null && autoAddressStates.searchValue.length > 0) {
                                        inputCallback(e.target.value)
                                    }
                                }
                            }}
                        />
                    )}
                    fullWidth
                    style={{ width: '100%' }}
                    value={item.value}
                    onChange={e => {
                        setAutoAddressStates({
                            ...autoAddressStates,
                            selectedValue: e.target.dataset.optionIndex
                        })
                        selectedAddressCallback(autoAddressStates.options[e.target.dataset.optionIndex])
                        runInAction(() => {
                            storeElement.scrollTo = true
                        })
                    }}
                    isOptionEqualToValue={(option, value) => {
                        // (option && option.value && value ? option.value === value.value : '')
                        if (option && value) {
                            return option.value === value.value
                        } else {
                            return ''
                        }
                    }}
                    disableClearable
                    sx={{
                        '.MuiOutlinedInput-root': {
                            borderRadius: '6px',
                            color: '#153862',
                            fontWeight: 400,
                            fontSize: 14,
                            '&:hover': {
                                border: '0px!important'
                            },
                            '&.Mui-focused': {
                                border: 0,
                                '.MuiOutlinedInput-notchedOutline': {
                                    border: '1px solid rgba(153, 188, 223, 0.25)'
                                }
                            }
                        },
                        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid rgba(153, 188, 223, 0.25)',
                            '&:hover': {
                                border: '0px!important'
                            }
                        },
                        '& .Mui-focused': {
                            border: 0,
                            '.MuiOutlinedInput-notchedOutline': {
                                border: '1px solid rgba(153, 188, 223, 0.25)'
                            }
                        },
                        ' .MuiInputBase-root': {
                            '&:hover': {
                                border: '0px!important',
                                '.MuiOutlinedInput-notchedOutline': {
                                    border: '1px solid rgba(153, 188, 223, 0.25)'
                                }
                            }
                        }
                    }}
                    PaperComponent={CustomPaper}
                />
            </label>
            <div style={{ display: autoAddressStates.selectedValue !== null || item.value ? 'inherit' : 'none' }}>
                <DefaultInput
                    label={translate(item.label)}
                    value={item.value}
                    onChange={e => {
                        console.log()
                        if (e.target.value.length === 0) {
                            setAutoAddressStates({
                                ...autoAddressStates,
                                selectedValue: null,
                                searchValue: ''
                            })
                            inputCallback('')
                            if (autoCompleteInputRef.current) {
                                setTimeout(() => {
                                    autoCompleteInputRef.current.focus()
                                }, 500)
                            }
                        } else {
                            inputCallback(e.target.value)
                        }
                    }}
                />
            </div>
        </AutoAddressStyled>
    )
})

const AutoAddressStyled = styled.div``
export default AutoAddress
