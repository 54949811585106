import axios from 'axios'

const MEMBER_CALLS_HOSTNAME = 'member-calls2'
const MEMBER_CALLS2_HOSTNAME = 'member-calls2'
const UNICITY_DOMAIN = 'unicity.com'
const UNICITY_EASYNET = 'unicity-easynet.com'

const devEnv = ['localhost', 'ushop-dev']

const CURRENT_COUNTRY = window.location.pathname.split('/')[1] === 'express' ? window.location.pathname.split('/')[2] : window.location.pathname.split('/')[1]
// const IsDebug = /debug=true/.test(window.location.search)

const SPECIAL_PATH_START = {
    vietnam: '/ushop'
}

const USE_PROXY = {
    vietnam: false
}

const MEMBER_CALLS_PROXY_HOSTNAME = {
    vietnam: 'vn'
}

const ALWAYS_USE_LIVE_ENV = {
    vietnam: true
}

// const USE_LAGACY_API = [
//     'vietnam',
//     'philippines',
//     'malaysia',
//     'singapore',
//     'indonesia',
//     'hongkong',
//     'taiwan'
// ]

class HttpHelper {
    IsDev = devEnv.includes(window.location.hostname)

    Dev({ forceDev, forceLive } = { forceDev: false, forceLive: false }) {
        return forceLive ? '' : forceDev ? '-dev' : this.IsDev ? '-dev' : ''
    }

    BuildPath({ hostname, path, forceDev, forceLive, queryString }) {
        const defaultOptions = {
            hostname: hostname || '',
            path: path || '',
            forceDev: forceDev || false,
            forceLive: forceLive || false,
            queryString: queryString || ''
        }

        const fullPathName = path => {
            return SPECIAL_PATH_START[CURRENT_COUNTRY] ? `${SPECIAL_PATH_START[CURRENT_COUNTRY]}${path}` : path
        }

        const IsForceLive = CURRENT_COUNTRY === 'vietnam' ? true : defaultOptions.forceLive ? defaultOptions.forceLive : false
        const IsForceDev = IsForceLive ? false : defaultOptions.forceDev

        const DOMAIN = defaultOptions.hostname === MEMBER_CALLS_HOSTNAME ? UNICITY_EASYNET : UNICITY_DOMAIN

        let url = `https://${defaultOptions.hostname}${this.Dev({ forceDev: IsForceDev, forceLive: IsForceLive })}.${DOMAIN}${fullPathName(
            defaultOptions.path
        )}${defaultOptions.queryString.length > 0 ? `?${defaultOptions.queryString}` : ''}`

        return url
    }

    MemberCalls({ path, forceDev, forceLive, queryString }) {
        const defaultOptions = {
            path: path || '',
            forceDev: forceDev || false,
            forceLive: forceLive || false,
            queryString: queryString || ''
        }

        const IsForceLive = defaultOptions.forceLive ? defaultOptions.forceLive : false
        const IsForceDev = IsForceLive ? false : defaultOptions.forceDev

        return this.BuildPath({
            hostname: MEMBER_CALLS_HOSTNAME,
            path: defaultOptions.path,
            forceDev: IsForceDev,
            forceLive: IsForceLive,
            queryString: defaultOptions.queryString
        })
    }

    MemberCalls2({ path, forceDev, forceLive, queryString }) {
        const defaultOptions = {
            path: path || '',
            forceDev: forceDev || false,
            forceLive: forceLive || false,
            queryString: queryString || ''
        }

        const IsForceLive = defaultOptions.forceLive ? defaultOptions.forceLive : false
        const IsForceDev = IsForceLive ? false : defaultOptions.forceDev

        return this.BuildPath({
            hostname: MEMBER_CALLS2_HOSTNAME,
            path: defaultOptions.path,
            forceDev: IsForceDev,
            forceLive: IsForceLive,
            queryString: defaultOptions.queryString
        })
    }

    Proxy({ originalUrl, queryString }) {
        const isUseProxy = USE_PROXY[CURRENT_COUNTRY] || false
        const isAlwaysLiveENV = ALWAYS_USE_LIVE_ENV[CURRENT_COUNTRY] || false

        const makeId = length => {
            var result = ''
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            var charactersLength = characters.length
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength))
            }
            return result
        }

        const defaultOptions = {
            originalUrl: originalUrl || '',
            queryString: queryString || {}
        }

        let newQueryString = ''
        if (Object.keys(defaultOptions.queryString).length > 0) {
            newQueryString = `${new URLSearchParams(defaultOptions.queryString).toString()}`
        }

        const seprator = newQueryString.length > 0 ? (defaultOptions.originalUrl.indexOf('?') > -1 ? '&' : '?') : ''

        let newUrl = `${defaultOptions.originalUrl}${seprator}${newQueryString}`

        if (isUseProxy) {
            const isMemberCalls = /member(-calls|-calls-dev).unicity-easynet\.com/.test(originalUrl)
            const isMemberCalls3 = /member(-calls3|-calls3-dev).unicity\.com/.test(originalUrl)
            const isHydra = /hydra\.unicity/.test(originalUrl)

            let usePath = ''
            if (isMemberCalls) {
                usePath = 'app1'
                if (isAlwaysLiveENV) {
                    newUrl = newUrl.replace('member-calls-dev', 'member-calls')
                }
            } else if (isMemberCalls3) {
                usePath = 'app2'
                if (isAlwaysLiveENV) {
                    newUrl = newUrl.replace('member-calls2-dev', 'member-calls2')
                }
            } else if (isHydra) {
                usePath = 'itx1'
            } else {
                if (/amazonaws\.com/.test(originalUrl)) {
                    usePath = 'app1'
                    if (isAlwaysLiveENV) {
                        newUrl = newUrl.replace('member-calls2-dev', 'member-calls')
                    }
                } else {
                    usePath = 'app1'
                    if (isAlwaysLiveENV) {
                        newUrl = newUrl.replace('member-calls-dev', 'member-calls')
                    }
                }
            }

            const hashOriginalUrl = Buffer.from(newUrl).toString('base64')

            const isDebug = /debug=true/.test(window.location.search)
            const hash = `${makeId(48)}${hashOriginalUrl}${isDebug ? '&debug=true' : ''}`

            const proxyUrl = this.BuildPath({
                hostname: MEMBER_CALLS_PROXY_HOSTNAME[CURRENT_COUNTRY],
                forceLive: true,
                forceDev: false,
                path: `/${usePath}/api`,
                queryString: `q=${hash}`
            })

            return proxyUrl
        } else {
            return newUrl
        }
    }

    ForceLive(forceLive) {
        return forceLive ? forceLive : CURRENT_COUNTRY === 'vietnam' ? true : false
    }

    Post({ url = '', data = {}, config = {} }) {
        return axios.post(this.Proxy({ originalUrl: url }), data, config)
    }

    Get({ url, config = {} }) {
        let queryString = {}
        if (config.params) {
            queryString = config.params
            delete config.params
        }
        const proxyUrl = this.Proxy({ originalUrl: url, queryString: queryString })

        return axios.get(proxyUrl, config)
    }

    Put({ url = '', data = {}, config = {} }) {
        return axios.put(this.Proxy({ originalUrl: url }), data, config)
    }

    Convert(from, to) {
        let converted = from
        if (USE_PROXY[CURRENT_COUNTRY]) {
            converted = this.ConvertToPHPProxy(from, to)
        }

        return converted
    }

    ConvertToPHPProxy(from, to) {
        let converted = from
        if (!to) {
            if (from.indexOf('hydra.unicity.net')) {
                converted = converted.replace('hydra.unicity.net', 'vn.unicity.com')
            } else if (/vn\.unicity\.com/.test(from)) {
                converted = converted.replace('vn.unicity.com', 'hydra.unicity.net')
            }
        } else {
            converted = converted.replace(from, to)
        }
        return converted
    }

    ConvertToNodeProxy(from, to) {
        let converted = from
        if (!to) {
            if (from.indexOf('hydra.unicity.net')) {
                converted = converted.replace('hydra.unicity.net', 'vn.unicity.com')
            } else if (/vn\.unicity\.com/.test(from)) {
                converted = converted.replace('vn.unicity.com', 'hydra.unicity.net')
            }
        } else {
            converted = converted.replace(from, to)
        }
        return converted
    }

    isHTTPProtocol(url) {
        return url.match(/^(http|https):/) != null
    }

    getCurrentDomain() {
        return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
    }

    MemberCallsUrl = `https://${MEMBER_CALLS_HOSTNAME}.${UNICITY_DOMAIN}`
    MemberCalls2Url = `https://${MEMBER_CALLS2_HOSTNAME}.${UNICITY_DOMAIN}`
}

const httpHelper = new HttpHelper()
export default httpHelper
