import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Box, Stack, styled } from '@mui/material'
import PersonalDetailsRoute from './Route'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import UserAccount from 'Components/Pages/UserAccount'
import Breadcrumb from '../UserAccountMenu/Breadcrumb'
import MobileLayoutWrapper from '../UserAccountMenu/MobileLayoutWrapper'

const SettingWrapper = observer(props => {
    const { width } = useSizeWindow()
    const isPdpaSetting = window.location.pathname.includes('setting')

    return width >= 900 ? (
        <UserAccount>
            <DesktopLayout isPdpaSetting={isPdpaSetting}>
                <PersonalDetailsRoute />
            </DesktopLayout>
        </UserAccount>
    ) : (
        <WrapperMobileLayout isPdpaSetting={isPdpaSetting}>
            <Box className="mobile-layout-box">
                <PersonalDetailsRoute />
            </Box>
        </WrapperMobileLayout>
    )
})

export const WrapperMobileLayout = observer(props => {
    return (
        <MobileLayoutWrapper>
            <StackMobileLayout isPdpaSetting={props.isPdpaSetting}>
                <Stack width={'100%'}>
                    <Breadcrumb />
                    {props.children}
                </Stack>
            </StackMobileLayout>
        </MobileLayoutWrapper>
    )
})

const StackMobileLayout = styled(Stack)`
    align-items: center;
    background: #d1d9e2 !important;
    display: flex;
    width: 100%;

    .mobile-layout-box {
        box-sizing: border-box;
        /* width: 300px; */
        width: 100%;
        padding: ${props => (props.isPdpaSetting ? '32px 32px 27px 32px' : '30px 20px 40px 20px')};
        background-color: #fff;
        box-shadow: 0px 1px 2px rgba(14, 35, 56, 0.2);
        border-radius: 12px;
    }

    @media screen and (max-width: 800px) {
        .mobile-layout-box {
            /* width: 384px; */
            padding: ${props => (props.isPdpaSetting ? '32px 16px' : '30px 20px 40px 20px')};
        }
    }
`
const DesktopLayout = styled('div')`
    box-sizing: border-box;
    width: 100%;
    padding: ${props => (props.isPdpaSetting ? '32px' : '60px 40px 40px 40px')};
    background-color: #fff;
    border-radius: 12px;
`

export default SettingWrapper
