import React, { useState } from 'react'
import { Stack, Typography, Box, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import StoreSubScriptions from 'GlobalStores/StoreSubScriptions'
import { DefaultButton } from 'Components/Elements/Forms'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'
import { get } from 'lodash'
import { CreditCardHelper } from 'Helpers/CreditCardHelper'
import { FormSelector } from 'Components/Elements/Forms/FormSelector'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import { getOSName } from 'Helpers/Bowser'
import { runInAction } from 'mobx'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import ShowError from './ShowError'

const ChangePayment = observer(props => {
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState([])
    const [formattedValue, setFormattedValue] = useState('')
    const [helperText, setHelperText] = useState()

    const order = props.products

    const { width } = useSizeWindow()

    const translate = useTranslate()

    const handleSubmit = async () => {
        setLoading(true)
        setErrorMessage([])
        try {
            const productItems = order.lines.items.map(data => {
                return {
                    item_code: data.item.id.unicity,
                    qty: data.quantity,
                    price: data.terms.priceEach
                }
            })

            const creditCard = {
                countryCode: 'TH',
                creditCard: {
                    creditCardNumber: getCardValue('card_number'),
                    payer: getCardValue('name_on_card'),
                    creditCardExpires: getCardValue('exp_date'),
                    creditCardSecurityCode: getCardValue('cvv')
                }
            }

            const newCredit = {
                items: [creditCardEncrypted(creditCard.creditCard)]
            }

            let recurrence = order.recurrence
            if (order.recurrence.dateStarts) {
                recurrence.schedule.dateStarts = order.recurrence.dateStarts
                delete recurrence.dateStarts
            }

            const newData = {
                autoship_href: order.href,
                autoship_number: order.id.unicity.toString(),
                ba_id: order.customer.id.unicity.toString(),
                market: order.market,
                items: JSON.stringify(productItems),
                shippingMethod_href: order.shippingMethod.href,
                shipToAddress: order.shipToAddress,
                shipToName: order.shipToName,
                shipToPhone: order.shipToPhone,
                shipToEmail: order.shipToEmail,
                shipment_options: 'delivery',
                recurrence: recurrence,
                transactions: newCredit,
                medium: 'Internet',
                agent: 'uFeelGreat',
                platform: getOSName()
            }

            const cardValidate = CreditCardHelper.cardValidate(creditCard)

            if (cardValidate.success) {
                let res = await FeelGreatAPI.EditSubscriptions(newData, StoreAuth.GetToken())

                if (res.data.success) {
                    props.handleClose()
                    props.handleGetOrder()
                    setLoading(false)
                } else {
                    setErrorMessage([res.data.error.message])
                }
            } else {
                let cardErrors = []
                cardValidate.error_messages.map(item => {
                    cardErrors.push({
                        english: item,
                        native: item
                    })
                    return item
                })
                runInAction(() => {
                    setErrorMessage(cardErrors)

                    if (width <= 428) {
                        setTimeout(() => {
                            setLoading(false)
                            const objDiv = document.getElementById('change-payment')
                            objDiv.scrollTop = objDiv.scrollHeight // set the scroll position to the bottom
                        }, 500) // wait for 1000 milliseconds before scrolling
                    } else {
                        setLoading(false)
                        const objDiv = document.getElementById('change-payment')
                        objDiv.scrollTop = objDiv.scrollHeight // set the scroll position to the bottom
                    }
                })
            }
        } catch (error) {
            console.log(error)
            setErrorMessage([error.response.data.message.error.message])
            setTimeout(() => {
                setLoading(false)
                const objDiv = document.getElementById('change-payment')
                objDiv.scrollTop = objDiv.scrollHeight // set the scroll position to the bottom
            }, 500) // wait for 1000 milliseconds before scrolling
        }
    }

    const onChange = (item, event) => {
        if (item.key === 'card_number') {
            const TEXT_ERROR = <T>only_accepting_visa_or_mastercard</T>
            const booValidateAcceptCardType = !!CreditCardHelper.checkCardType(event.target.value, false)

            if (canSetFormatValue(item, event.target.value)) {
                setFormattedValue(event.target.value)
            }

            StoreCheckout.SetCreditCard(item, event.target.value.replace(/\s/g, ''))

            if (event.target.value !== '' && event.target.value.length > 1 && booValidateAcceptCardType === false) {
                setHelperText(TEXT_ERROR)
            } else {
                setHelperText('')
            }
        } else {
            StoreCheckout.SetCreditCard(item, event.target.value)
        }
    }

    const onKeyUp = (item, e) => {
        if (item.key !== 'card_number') return
        const key = e.which || e.keyCode || e.charCode
        const field = e.target

        if (key !== 8) {
            setFormattedValue(
                field.value
                    .replace(/[^\dA-Z]/g, '')
                    .replace(/(.{4})/g, '$1 ')
                    .trim()
            )
            return
        }
    }

    const canSetFormatValue = (item, event) => {
        if (item.args) {
            const regx = get(item, 'args.regx', null)
            let regexPass = false

            if (regx) {
                const regex = new RegExp(regx, 'i')
                if (regex.test(event.replace(/\s/g, '')) || event.replace(/\s/g, '').length === 0) {
                    regexPass = true
                }
            } else {
                regexPass = true
            }

            const maxLength = get(item, 'args.maxLength', null)
            let maxLengthPass = false
            if (maxLength) {
                if (event.replace(/\s/g, '').length <= maxLength) {
                    maxLengthPass = true
                }
            } else {
                maxLengthPass = true
            }
            if (regexPass && maxLengthPass) {
                return true
            }
            return false
        }
    }

    const getCardValue = key => {
        const card = StoreCheckout.creditCard.find(item => item.label === key)
        if (card) {
            return card.value
        }
        return ''
    }

    const creditCardEncrypted = CreditCard => {
        let rearrangeExpDate = CreditCard.creditCardExpires.split('/')
        rearrangeExpDate = `${parseInt(rearrangeExpDate[1]) + 2000}-${rearrangeExpDate[0]}`

        return {
            type: 'AuthorizeAndCapture',
            method: 'CreditCard',
            amount: 'this.terms.total',
            billingToAddress: order.shipToAddress,
            methodDetails: {
                payer: CreditCard.payer,
                creditCardNumber: CreditCard.creditCardNumber,
                creditCardExpires: rearrangeExpDate,
                creditCardSecurityCode: CreditCard.creditCardSecurityCode
            }
        }
    }

    return (
        <Stack gap="20px">
            <Typography variant="title2">
                <T>change_payment</T>
            </Typography>

            <Wrapper id="change-payment">
                <Stack gap="15px">
                    <Stack gap="20px">
                        {StoreCheckout.GetCreditCardForm().map(item => {
                            return <Box key={item.key}>{FormSelector(translate, item, onChange, helperText, formattedValue, onKeyUp)}</Box>
                        })}
                    </Stack>
                    {errorMessage.length > 0 && <ShowError list={errorMessage} noTranslate={true} />}
                </Stack>
            </Wrapper>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                <Box onClick={() => props.handleClose()}>
                    <Typography variant="link" color="#000000" fontSize="16px">
                        <T>cancel</T>
                    </Typography>
                </Box>
                <DefaultButton
                    type="submit"
                    fullWidth={true}
                    titleText={translate('save')}
                    width="220px"
                    onClick={() => handleSubmit()}
                    isloading={loading.toString()}
                    fontSize="20px"
                    fontWeight="400"
                    disabled={loading}
                />
            </Box>
        </Stack>
    )
})

const Wrapper = styled(Box)`
    overflow: scroll;
    height: 100%;
    scroll-behavior: smooth;

    @media (max-width: 428px) {
        height: 370px;
    }
`

export default ChangePayment
