import { Box, Stack, ThemeProvider, Typography } from '@mui/material'
import { MainTemplate } from 'Components/Template/MainTemplate'
import StorePaymentStep from 'GlobalStores/Cores/Elements/StorePaymentStep'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { storeElement } from 'GlobalStores/StoreElement'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router-dom'

const Errors = observer(({ list = [], children, toTranslate = true }) => {
    const translate = useTranslate()
    const query = new URLSearchParams(document.location.search)
    const uuid = query.get('uuid')
    const location = useLocation()
    const pathname = location.pathname

    const isShowUuid = pathname.includes('/checkout/review')

    return (
        <ThemeProvider theme={storeElement.theme}>
            <Stack bgcolor={'#FFE7EB'} pt="15px" pb="15px" justifyContent={'center'} borderRadius="12px" mt="4px">
                {children
                    ? children
                    : list.map((item, key) => {
                          const isLast = list.length - 1 === key
                          return (
                              <Box textAlign={'center'} pl="30px" pr="30px" key={key}>
                                  <Box
                                      borderBottom={isLast && !isShowUuid ? '0' : '1px dashed red'}
                                      pb={!isLast || isShowUuid ? '15px' : '0'}
                                      mb={!isLast || isShowUuid ? '15px' : '0'}>
                                      {item.english || item.native ? (
                                          <Typography variant="error">
                                              {(toTranslate === true) & (item.english.indexOf('_') > -1) ? (
                                                  <T>{StoreTranslate.UseLanguage(item)}</T>
                                              ) : (
                                                  StoreTranslate.UseLanguage(item)
                                              )}
                                          </Typography>
                                      ) : /_/.test(item) ? (
                                          <Typography variant="error">
                                              <T>{item}</T>
                                          </Typography>
                                      ) : (
                                          <Typography variant="error" component={'div'} dangerouslySetInnerHTML={{ __html: item }} />
                                      )}
                                  </Box>
                                  {isShowUuid && <Typography variant="error">{uuid}</Typography>}
                              </Box>
                          )
                      })}
            </Stack>
        </ThemeProvider>
    )
})

export default Errors
