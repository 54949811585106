import { Card, Skeleton, Stack, ThemeProvider, Typography, styled } from '@mui/material'
import { DefaultInput } from 'Components/Elements/Forms'
import { MainTemplate } from 'Components/Template/MainTemplate'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { editReferralCode } from './APis'
import storeAuth from 'GlobalStores/User/StoreAuth'
import { get, set, startsWith } from 'lodash'
import { reaction, runInAction } from 'mobx'
import { GetReferralCode } from '../APIs'
import storeReferral from 'Components/Pages/Referral/Thailand/Stores/StoreReferral'
import { IconSpinner } from 'Components/Elements/Spinner/IconSpinner'

const CustomReferral = observer(() => {
    const currentReferralCode = get(storeReferral.GetReferral(), 'referralCode', '')
    const maxChangeReached = get(storeReferral.GetReferral(), 'maxChangesReached', false)

    const [referral, setReferral] = useState()
    const [toggleToChangeMode, setToggleToChangeMode] = useState(false)
    const [helperText, setHelperText] = useState('')
    const [haveBadWord, setHaveBadWord] = useState(false)
    const [loading, setLoading] = useState(false)

    const Filter = require('bad-words'), filterBadWord = new Filter()

    const limitLengthRef = 20

    const translate = useTranslate()

    useEffect(() => {
        const disposer = reaction(() => storeReferral.GetReferral(),
            (newValue, prevValue) => {
                if (newValue !== prevValue) {
                    setReferral(newValue.referralCode)
                }
            })

        return () => disposer()
    }, [])

    const clearState = () => {
        setReferral('')
        setHelperText('')
        setHaveBadWord(false)
    }

    const onChangeEditReferralCode = e => {
        if (e.target.value.length > 20) {
            return
        }

        if (filterBadWord.list.some(badWord => e.target.value.toLowerCase().includes(badWord))) {
            setHelperText('not_allow_bad_word')
            setHaveBadWord(true)
        } else {
            setHelperText('')
            setHaveBadWord(false)
        }

        setReferral(e.target.value)
    }

    const handleConfirmEditRef = async () => {
        if (referral.length === 0) {
            setHelperText('referral_empty')
            return
        } else if (referral.length < 3) {
            setHelperText('referral_too_short')
            return
        }

        if (maxChangeReached === true) {
            setHelperText('max_change_reached')
            return
        }

        if (haveBadWord === false && referral !== currentReferralCode) {
            setLoading(true)
            try {
                await editReferralCode(storeAuth.GetToken(), referral)

                setHelperText('')
                setHaveBadWord(false)
                setToggleToChangeMode(!toggleToChangeMode)

                const { data } = await GetReferralCode(storeAuth.GetToken())

                const referralCode = get(data, 'items[0]', {})

                runInAction(() => {
                    storeReferral.referralData = referralCode
                })
                setLoading(false)
            } catch (err) {
                console.log(err)
                setLoading(false)
                const { data } = err.response

                if (data.error.error_message.startsWith('ReferralCode already assigned Error')) {
                    setHelperText('referral_code_already_assigned')
                }
            }
        }
    }

    const onClickEditRef = () => {
        clearState()
        setToggleToChangeMode(!toggleToChangeMode)
    }

    const onCancelEditRef = () => {
        clearState()
        setReferral(currentReferralCode)
        setToggleToChangeMode(!toggleToChangeMode)
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            <Container>
                {storeReferral.loadingReferCode ? (
                    <SkeletonLoading />
                ) : (
                    <>
                        <Title>
                            <Typography
                                variant="h2"
                                component="h2"
                                sx={{
                                    fontSize: {
                                        xs: '24px',
                                        md: '32px'
                                    },
                                    fontWeight: 700,
                                    textAlign: 'left',
                                    textWrap: ''
                                }}>
                                {translate('referral_code')}
                            </Typography>
                        </Title>

                        <Stack sx={{ maxWidth: '500px' }}>
                            <InputStyled
                                disabled={toggleToChangeMode ? false : true}
                                value={referral}
                                helperText={!!helperText}
                                onChange={onChangeEditReferralCode}
                            />
                            {toggleToChangeMode ? (
                                <>
                                    <ContainerHelperText>
                                        <Typography
                                            variant="subtitle1"
                                            component="span"
                                            fontSize={'14px'}
                                            color="#FE5D7C"
                                            fontWeight={400}
                                        >
                                            {translate(helperText)}
                                        </Typography>
                                        <Typography
                                            onClick={handleConfirmEditRef}
                                            variant="subtitle1"
                                            component="span"
                                            fontSize={'14px'}
                                            sx={{ cursor: 'pointer', color: 'rgb(90, 143, 195)' }}
                                        >
                                            {`${referral.length}/${limitLengthRef}`}
                                        </Typography>
                                    </ContainerHelperText>
                                    <ContainerButton>
                                        <Typography
                                            onClick={onCancelEditRef}
                                            variant="subtitle1"
                                            component="span"
                                            fontSize={'14px'}
                                            sx={{ cursor: 'pointer', color: 'rgb(90, 143, 195)', textDecoration: 'underline' }}
                                        >
                                            {translate('cancel')}
                                        </Typography>

                                        {loading ? (
                                            <Spinner />
                                        ) : (
                                            <Typography
                                                onClick={handleConfirmEditRef}
                                                variant="subtitle1"
                                                component="span"
                                                color="rgb(90, 143, 195)"
                                                fontSize={'14px'}
                                                sx={{ cursor: 'pointer', color: 'rgb(7, 159, 244)', textDecoration: 'underline' }}
                                            >
                                                {translate('save')}
                                            </Typography>
                                        )}
                                    </ContainerButton>
                                </>
                            ) : (
                                <ContainerButton>
                                    <Typography
                                        onClick={onClickEditRef}
                                        variant="subtitle1"
                                        component="span"
                                        color="rgb(90, 143, 195)"
                                        fontSize={'14px'}
                                        sx={{ cursor: 'pointer', color: 'rgb(7, 159, 244)', textDecoration: 'underline' }}
                                    >
                                        {translate('Change_my_referral_code')}
                                    </Typography>
                                </ContainerButton>
                            )}
                        </Stack>
                    </>
                )}
            </Container>
        </ThemeProvider>
    )
})

const SkeletonLoading = observer(() => {
    return (
        <Stack width={'auto'}>
            <Title>
                <Stack width="250px">
                    <Typography variant="h2">
                        <Skeleton animation="wave" />
                    </Typography>
                </Stack>
            </Title>

            <FeelGreatSection>
                <Stack mt={'30px'} mb={'10px'} />
                <Typography variant="subtitle1" component="h3">
                    <Skeleton animation="wave" width={'25%'} />
                </Typography>
                <Typography variant="subtitle1" component="p">
                    <Skeleton animation="wave" width={'70%'} />
                </Typography>
                <Typography variant="h2">
                    <Skeleton animation="wave" width={'90%'} />
                </Typography>
                <Stack mb={'20px'} />
            </FeelGreatSection>
        </Stack>
    )
})

const Spinner = styled(IconSpinner)`
    width: 25px;
    height: 25px;
`

const Container = styled(Card)`
    padding: 25px 15px;

    @media screen and (min-width: 768px) {
        padding: 25px 30px;
    }
`
const Title = styled(Stack)`
    color: #003b6f;
    /* padding: 0px 15px; */
`

const ContainerButton = styled(Stack)`
    flex-direction: row;
    justify-content: space-between;
    margin-top: 25px;
`

const ContainerHelperText = styled(Stack)`
    flex-direction: row;
    width: 100%;
    gap: 50px;
    justify-content: space-between;
    margin-top: 12px;
`

const InputStyled = styled(DefaultInput)`
    .MuiInputBase-input.Mui-disabled {
        -webkit-text-fill-color: rgb(11, 59, 111);
        background: #f6f9fd;
    }

    .MuiInputBase-input {
        border: ${props => props.helperText ? '1px solid #FE5D7C' : '1px solid #bdd2e7'};
        border-radius: 8px;
        color: rgb(11, 59, 111) !important;

        &:focus {
            outline: none;
            border: ${props => props.helperText ? '2px solid #FE5D7C' : '2px solid #bdd2e7'};
        }
    }
`

const FeelGreatSection = styled(Stack)`
    padding: 0px 15px;

    @media screen and (min-width: 768px) {
        padding: 0px 30px;
    }
    @media screen and (max-width: 900px) {
        position: relative;
    }
`

export default CustomReferral