import { IconButton, Stack, Typography } from '@mui/material'
import { styled } from '@mui/system'
import StoreUser from 'GlobalStores/User/StoreUser'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as ProfileIcon } from './Assets/profile.svg'
import { reaction, toJS } from 'mobx'
import storeAuth from 'GlobalStores/User/StoreAuth'

const ProfileButton = observer(props => {
    const navigate = useNavigate()
    const [imageFaild, setImageFaild] = useState(false)
    const [trigger, setTrigger] = useState()

    useEffect(() => {
        const disposer = reaction(
            () => storeAuth.IsAuthorized(),
            (n, p) => {
                if (n !== p) {
                    setTrigger(new Date().getTime())
                }
            }
        )

        return () => {
            disposer()
        }
    }, [])

    useEffect(() => {
        let profileImg = toJS(StoreUser.CustomerData().ProfilePicture('original'))
        if (profileImg === '' || typeof profileImg === 'undefined') {
            setImageFaild(true)
        } else {
            setImageFaild(false)
        }
    }, [StoreUser.CustomerData().ProfilePicture('original')])

    const isEnglishInitialsFullname = (initialsName = '') => {
        // return /^[a-zA-Zก-ฮ๐-๙]{2}$/.test(initialsName) && initialsName.length > 0
    }

    return (
        <>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => {
                    if (!StoreUser.userData) {
                        navigate('login')
                    } else {
                        navigate('user', { replace: true })
                    }
                }}
                color="inherit"
                style={{ padding: 0 }}
                key={trigger}>
                {storeAuth.IsAuthorized() ? (
                    StoreUser.CustomerData().ProfilePicture() ? (
                        <>
                            {!imageFaild ? (
                                <UserImage src={StoreUser.CustomerData().ProfilePicture('original')} />
                            ) : storeAuth.IsAuthorized() ? (
                                StoreUser.CustomerData().InitialsFullName() ? (
                                    <FLProfileIcon>
                                        <Typography variant="caption7" color="#fffff" fontSize={'10px'} sx={{ opacity: 1, color: '#ffffff!important' }}>
                                            {StoreUser.CustomerData().InitialsFullName()}
                                        </Typography>
                                    </FLProfileIcon>
                                ) : (
                                    <FLProfileIcon>
                                        <ProfileIcon />
                                    </FLProfileIcon>
                                )
                            ) : (
                                <ProfileIcon />
                            )}
                        </>
                    ) : (
                        <Stack>
                            {StoreUser.CustomerData().InitialsFullName() ? (
                                <FLProfileIcon>
                                    <Typography variant="caption7" color="#fffff" fontSize={'10px'} sx={{ opacity: 1, color: '#ffffff!important' }}>
                                        {StoreUser.CustomerData().InitialsFullName()}
                                    </Typography>
                                </FLProfileIcon>
                            ) : (
                                <FLProfileIcon>
                                    <ProfileIcon />
                                </FLProfileIcon>
                            )}
                        </Stack>
                    )
                ) : (
                    <ProfileIcon />
                )}
            </IconButton>
        </>
    )
})

const FLProfileIcon = styled(Stack)`
    width: 24px;
    height: 24px;
    background-color: #75a4d3;
    border-radius: 50%;
    color: #fff; /* Set your desired text color */
    font-size: 10px; /* Set your desired font size */
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
`

const UserImage = styled('img')`
    width: 24px;
    height: 24px;
    background-color: #e5e5e5;
    border-radius: 50%;
    cursor: pointer;
`

export default ProfileButton
