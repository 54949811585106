import { observer } from 'mobx-react-lite'
import React from 'react'
import SettingCard from '../UserAccountMenu/SettingCard'
import SubscriptionsRoute from './Route'
import { Stack, styled } from '@mui/material'
import Breadcrumb from '../UserAccountMenu/Breadcrumb'
import MobileLayoutWrapper from '../UserAccountMenu/MobileLayoutWrapper'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { storeSettingsElement } from 'GlobalStores/Settings/StoreSettingsElement'

const SubscriptionsWrapper = observer(props => {

    const { width } = useSizeWindow()

    return (
        <>
            {width > 834 ? (
                <SettingCard
                    title={storeSettingsElement.settingCard.title || ''}
                    titleAlign={storeSettingsElement.settingCard.titleAlign || 'left'}
                    padding={storeSettingsElement.settingCard.padding || '50px 40px 20px 40px'}
                    display="block">
                    <SubscriptionsRoute />
                </SettingCard>
            ) : (
                <MobileLayoutWrapper>
                    <Stack width="100%">
                        <Breadcrumb />
                        <SettingCard title={storeSettingsElement.settingCard.title || ''} titleAlign={storeSettingsElement.settingCard.titleAlign || 'center'} display="block">
                            <SubscriptionsRoute />
                        </SettingCard>
                    </Stack>
                </MobileLayoutWrapper>
            )}
        </>
    )
})

const StackMobileLayout = styled(Stack)`
    align-items: center;
    background: #d1d9e2 !important;
`

export default SubscriptionsWrapper
