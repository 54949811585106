import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Stack, ThemeProvider, Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { observer } from 'mobx-react-lite'
import { customTheme } from './PersonalTheme'
import { DefaultButton, DefaultTextFields } from '../Forms'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isEmail, isUnicityID } from 'Helpers/StringHelpers'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import SuccessPaper from './SuccessPaper'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import storeReferral from 'Components/Pages/Referral/Thailand/Stores/StoreReferral'
import DefaultCheckboxSVG from '../Forms/DefaultCheckboxSVG'
import { ReferralThailandAPIs } from 'Components/Pages/Referral/Thailand/Services/ReferralThailandAPIs'
import storeAuth from 'GlobalStores/User/StoreAuth'
import storeReferralCheckout from 'Components/Pages/Referral/Thailand/Stores/StoreReferralCheckout'
import { IconSpinner } from '../Spinner/IconSpinner'
import { DefaultReferralTheme } from 'Components/Pages/Referral/Theme/DefaultReferralTheme'
import { runInAction } from 'mobx'
import { storeSettingsElement } from 'GlobalStores/Settings/StoreSettingsElement'
import PdpaCheckboxSVG from '../Forms/PdpaCheckboxSVG'

const validationEmailSchema = yup.object({
    emailAddress: yup.string('Enter your emailAddress').test('test-name', 'Enter Valid Email or UnicityID', value => {
        if (!isEmail(value) && !isUnicityID(value)) return false
        return true
    })
})

const PdpaSetting = observer(props => {
    const { width } = useSizeWindow()
    const translate = useTranslate()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [updateSuccess, setUpdateSuccess] = useState(false)
    const { userData } = props

    useEffect(() => {
        getDefaultIsAcceptPdpa()
    }, [])

    const getDefaultIsAcceptPdpa = async () => {
        setLoading(true)
        try {
            const response = await ReferralThailandAPIs.getPdpaStatus(storeAuth.GetId())
            if (response.data?.success) {
                storeReferral.SetIsAcceptPdpa(response.data.data.is_allow_contact_by_unicity_or_sponsor)
            }
        } catch (e) {
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        // if (updateSuccess) {
        //     setTimeout(() => {
        //         goBack()
        //     }, 3000)
        // }
    }, [updateSuccess])

    const goBack = () => {
        navigate(-1)
    }

    const handleCheckChange = async event => {
        try {
            setLoading(true)
            await storeReferralCheckout.UpdatePDPAAllowContact(!storeReferral.GetIsAcceptPdpa())
            storeReferral.SetIsAcceptPdpa(!storeReferral.GetIsAcceptPdpa())
        } catch (e) {
            console.log('update pdpa error', e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        runInAction(() => {
            storeSettingsElement.cardTitle.titleStep2 = <T>settings</T>
        })

        return () => {
            runInAction(() => {
                storeSettingsElement.cardTitle.titleStep2 = ''
            })
        }
    }, [])

    return !updateSuccess ? (
        <ThemeProvider theme={customTheme}>
            <div>
                <Typography variant="h3" fontWeight={600} textAlign="left" mb={'0px'} sx={{ fontSize: '24px !important' }}>
                    <T>pdpa_agreement</T>
                </Typography>
                <Box sx={{ mt: '30px', display: 'flex', alignItems: 'center', alignItems: 'flex-start' }}>
                    <FormGroup>
                        <FormControlLabel
                            sx={{ marginLeft: 0, display: 'flex', alignItems: 'flex-start', marginBottom: 0 }}
                            control={
                                <PdpaCheckboxSVG
                                    onChange={handleCheckChange}
                                    defaultChecked
                                    disableFocusRipple
                                    disableRipple
                                    disableTouchRipple
                                    style={{
                                        padding: '4px 20px 0 0'
                                    }}
                                    checked={storeReferral.GetIsAcceptPdpa()}
                                />
                            }
                            label={
                                <Typography variant="body2" sx={{ marginBottom: 0, color: '#003B6F', fontSize: '16px !important', maxWidth: '600px' }}>
                                    <T>pdpa_consent_description</T>
                                </Typography>
                            }
                        />
                    </FormGroup>
                </Box>
                <Box
                    item
                    xs={6}
                    sm={6}
                    mt={'20px'}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    <Typography
                        variant="action"
                        fontSize={16}
                        fontFamily={500}
                        onClick={() => {
                            if (!loading) {
                                goBack()
                            }
                        }}
                        sx={{ cursor: 'pointer' }}>
                        <T>back</T>
                    </Typography>
                </Box>
                <Stack
                    minHeight={'calc(100vh)'}
                    minWidth={'100vw'}
                    bgcolor={loading ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.0)'}
                    sx={{ position: 'fixed', left: 0, top: 0, transition: 'all 0.3s ease-in-out', zIndex: loading ? 1000 : -1 }}
                    justifyContent={'center'}
                    alignItems={'center'}>
                    {loading && <IconSpinner />}
                </Stack>
            </div>
        </ThemeProvider>
    ) : (
        <div style={{ margin: '0 60px' }}>
            <SuccessPaper title={translate('success')} desc={translate('pdpa_setting_description')} />
        </div>
    )
})

export default PdpaSetting
