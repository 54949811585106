import { Backdrop, CircularProgress, Stack, ThemeProvider, Typography } from '@mui/material'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { get } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import StoreReferral from '../../Stores/StoreReferral'
import PopperPopup from './PopperPopup/PopperPopup'
import { Customers as CustomersApi } from 'Services/Cores/Customers/Customers'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'

import StoreAuth from 'GlobalStores/User/StoreAuth'
import { reaction } from 'mobx'
import { storeElement } from 'GlobalStores/StoreElement'
import ReferralInput from './ReferralInput'
import storeAuth from 'GlobalStores/User/StoreAuth'

const ReferralBar = observer(props => {
    const translate = useTranslate()
    const params = useParams()

    const [openBackDrop, setOpenBackDrop] = useState(false)
    const [currentBAId, setCurrentBAId] = useState('')
    const [readyToRender, setReadyToRender] = useState(false)

    const location = useLocation()
    const navigate = useNavigate()

    const GetCustomerData = async (customerId = null) => {
        if (Boolean(customerId) === false) return

        setOpenBackDrop(true)

        try {
            const referralResponse = await CustomersApi.getAllowReferrer(customerId)

            const item = {
                ...referralResponse.data,
                unicity: referralResponse.data.baId,
                href: '',
                customerSite: {
                    href: ''
                },
                id: {
                    unicity: referralResponse.data.baId
                },
                profilePicture: {
                    href: ''
                },
                joinDate: '',
                status: '',
                type: '',
                entryPeriod: ''
            }

            const isAllow = get(referralResponse.data, 'isAllow')
            const invalidType = get(referralResponse.data, 'reason.type')
            const baId = get(referralResponse.data, 'baId')
            setCurrentBAId(baId)

            if (isAllow) {
                if (referralResponse?.data?.reason?.message) {
                    if (invalidType === 'baIdMinLength') {
                        throw new Error('invalid_code_entered')
                    }

                    throw new Error(referralResponse?.data?.reason?.message)
                }
                localStorage.removeItem('from-logout')
                StoreReferral.SetReferralData(item, get(item, 'baStatus', ''))
                StoreReferral.SetCheckedReferralParam(true)
            } else {
                localStorage.removeItem('from-logout')
                if (invalidType === 'invalidId') {
                    throw new Error('invalid_code_entered')
                } else {
                    throw new Error(referralResponse?.data?.reason?.message)
                }
            }
        } catch (error) {
            console.error('error', error)
            localStorage.removeItem('from-logout')
            StoreReferral.SetCheckedReferralParam(true)
            StoreReferral.SetReferralCheckError(error?.message)
        }
        setReadyToRender(true)
        setOpenBackDrop(false)
    }

    // const isShowBox = () => {
    //     let path = last(location.pathname.split('/'))

    //     if (path !== 'referral' || !isNaN(path)) {
    //         path = ''
    //     }
    //     return includes(pageShowReferralBox, path) === true && readyToRender && !StoreAuth.IsAuthorized()
    // }

    const isShowBox = () => {
        let path = location.pathname.split('/')[1]

        if (path.length > 0) {
            return false
        }

        if (StoreReferral.GetReferralHistory()) return true

        return readyToRender && !StoreAuth.IsAuthorized()
    }

    useEffect(() => {
        if (params.customerId && !StoreAuth.IsAuthorized()) {
            GetCustomerData(params.customerId)
        }
    }, [params.customerId])

    useEffect(() => {
        const id = get(StoreReferral.GetReferral(), 'id', '')

        if (StoreReferral.HasReferral() === false && Boolean(StoreAuth.IsAuthorized()) === true && Boolean(id) === true) {
            GetCustomerData(id)
        }

        reaction(
            () => StoreReferral.HasReferral(),
            (newVal, oldVal, disposer) => {
                if (newVal !== false && oldVal === false) {
                    isShowBox()
                    disposer.dispose()
                }
            }
        )

        reaction(
            () => StoreReferral.GetCheckedReferralParam(),
            (newValue, oldValue, disposer) => {
                if (newValue === true) {
                    navigate('/')
                    disposer.dispose()
                }
            }
        )

        reaction(
            () => storeAuth.IsAuthorized(),
            (newValue, oldValue, disposer) => {
                if (newValue === true) {
                    StoreReferral.reset()
                    StoreReferral.referralHistory = null
                    setReadyToRender(false)
                    disposer.dispose()
                }
            }
        )
    }, [])

    return (
        <ThemeProvider theme={storeElement.theme}>
            <Stack>
                {isShowBox() && (
                    <Stack bgcolor={'#153862'} p="18px 25px">
                        <Stack color={'#ffffff'} justifyContent="center">
                            {StoreReferral.HasReferral() || StoreReferral.HasReferralHistory() ? (
                                <Stack justifyContent={'center'} flexDirection={'row'}>
                                    <Typography variant="caption" color="#ffffff" alignSelf="center" fontSize={'16px'}>
                                        <T>referred_by</T>{' '}
                                        <span style={{ fontWeight: 'bold' }}>
                                            {StoreTranslate.UseLanguage(
                                                get(StoreReferral.GetReferral(), 'displayName', '') ||
                                                    get(StoreReferral.GetReferralHistory(), 'displayName', '')
                                            ) ||
                                                get(StoreReferral.GetReferral(), 'displayName.english', '') ||
                                                get(StoreReferral.GetReferral(), 'displayName.native', '') ||
                                                get(StoreReferral.GetReferralHistory(), 'displayName.english', '') ||
                                                get(StoreReferral.GetReferralHistory(), 'displayName.native', '')}
                                        </span>
                                    </Typography>
                                    <div div style={{ display: 'flex', marginLeft: '10px', height: '36px' }}>
                                        <PopperPopup
                                            title={translate('subtitle_invited_you_to_member').replace(
                                                '{{referral_name}}',
                                                StoreTranslate.UseLanguage(
                                                    get(StoreReferral.GetReferral(), 'displayName', '') ||
                                                        get(StoreReferral.GetReferralHistory(), 'displayName', '')
                                                )
                                            )}
                                            caption={translate('member_invite_code_caption')}
                                        />
                                    </div>
                                </Stack>
                            ) : null}

                            {StoreReferral.HasReferral() === false && StoreReferral.HasReferralHistory() === false ? (
                                <Stack justifyContent={'center'} flexDirection={'row'}>
                                    <ReferralInput currentBAId={currentBAId} onSubmit={GetCustomerData} />
                                    <div div style={{ display: 'flex', marginLeft: '10px', height: '36px' }}>
                                        <PopperPopup title={translate('what_is_member_code')} caption={translate('member_invite_code_caption')} />
                                    </div>
                                </Stack>
                            ) : null}

                            {Boolean(StoreReferral.GetReferralCheckError()) && (
                                <Stack mt="10px">
                                    <Typography variant="caption" color="#FFD058" alignSelf="center" fontWeight={'bold'} lineHeight={'15px'}>
                                        {translate(StoreReferral.GetReferralCheckError())
                                            ? `${translate(StoreReferral.GetReferralCheckError())}: ${currentBAId}`
                                            : StoreReferral.GetReferralCheckError()}
                                    </Typography>
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                )}
            </Stack>
            <BackdropShow isOpen={openBackDrop} />
        </ThemeProvider>
    )
})

const BackdropShow = ({ isOpen }) => {
    return (
        <Backdrop
            sx={{
                color: '#fff',
                zIndex: theme => theme.zIndex.drawer + 1 + 1300 // 1300 from  Login modal's zIndex
            }}
            open={isOpen}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default ReferralBar
