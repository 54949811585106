import { Box, Dialog, DialogContent, Stack, ThemeProvider, Typography } from '@mui/material'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'

import { observer } from 'mobx-react-lite'
import { ReactComponent as Exclamation } from './../../../Assets/exclamation.svg'
import { storeElement } from 'GlobalStores/StoreElement'

const ModalNoReferral = observer(({ openModal, setOpenModal }) => {
    return (
        <Dialog open={openModal} onClose={setOpenModal} keepMounted>
            <DialogContent sx={{ background: '#FFF1DC' }}>
                <NoReferralContact />
            </DialogContent>
        </Dialog>
    )
})

export const NoReferralContact = observer(() => {
    return (
        <ThemeProvider theme={storeElement.theme}>
            <Stack flexDirection={'row'} padding={'20px'} justifyContent={'center'}>
                <Stack justifyContent={'center'} paddingRight={'20px'}>
                    <Exclamation />
                </Stack>

                <Stack flexDirection={'column'} justifyContent={'flex-start'}>
                    <Typography variant="h4" fontWeight={500} sx={{ textWrap: 'wrap' }}>
                        <T>footer_login_description</T>
                    </Typography>

                    <Typography mt="5px" variant="h5" sx={{ color: '#33628C', lineHeight: '18px', textWrap: 'wrap' }}>
                        <T>footer_login_sub_description</T>
                    </Typography>
                </Stack>
            </Stack>
        </ThemeProvider>
    )
})

export default ModalNoReferral
