import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Stack, styled, Typography, Box, ThemeProvider } from '@mui/material'
import { runInAction } from 'mobx'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import StoreProducts from 'GlobalStores/StoreProducts'
import StoreUser from 'GlobalStores/User/StoreUser'
import { ReactComponent as RefreshIcon } from './Assets/RefreshIcon.svg'
import ItemSubscriptions from './ItemSubscriptions'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as RefreshSVG } from './Assets/iconRefreshDesktop.svg'
import { DefaultButton } from '../Forms'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import { storeSettingsElement } from 'GlobalStores/Settings/StoreSettingsElement'
import { MainTemplate } from 'Components/Template/MainTemplate'

const Subscriptions = observer(props => {
    const [paymentData, setPaymentData] = useState([])
    const [loading, setLoading] = useState(false)
    const [orderData, setOrderData] = useState([])

    useEffect(() => {
        runInAction(() => {
            storeSettingsElement.cardTitle.titleStep1 = <T>subscriptions</T>
            storeSettingsElement.settingCard.padding = '0px'
        })

        if (orderData.length === 0) {
            getOrder()
        }

        return () => {
            runInAction(() => {
                storeSettingsElement.cardTitle.titleStep1 = ''
                storeSettingsElement.settingCard.padding = ''
            })
        }
    }, [])

    const getOrder = async () => {
        setLoading(true)
        try {
            const response = await FeelGreatAPI.GetSubscriptions(StoreAuth.GetToken())

            if (response) {
                const filterData = await Promise.all(
                    response.data.data
                        .filter(item => item.market === StoreCountry.Country2())
                        .map(async data => {
                            let useData = { ...data }

                            let jpStyle = null
                            if (useData.market === 'JP') {
                                jpStyle = await FeelGreatAPI.GetSubscriptionsItems(StoreAuth.GetToken(), data.href)
                                useData = {
                                    ...useData,
                                    lines: jpStyle?.data?.lines,
                                    recurrence: jpStyle?.data?.recurrence,
                                    shipToAddress: jpStyle?.data?.shipToAddress,
                                    transactions: jpStyle?.data?.transactions,
                                    terms: jpStyle?.data?.terms,
                                    shippingMethod: jpStyle?.data?.shippingMethod,
                                    shipToEmail: jpStyle?.data?.shipToEmail
                                }
                            }

                            return {
                                ...useData,
                                productItem: useData.lines.items
                                    .map(lineItem => {
                                        let createdItem = {
                                            ...StoreProducts.GetProductByItemCode(lineItem.item.id.unicity),
                                            quantity: lineItem.quantity
                                        }

                                        return createdItem
                                    })
                                    .filter(createdItem => Object.keys(createdItem).length > 1)
                            }
                        })
                )

                setOrderData(filterData)

                const responsePayment = await FeelGreatAPI.Creditcardaliases(StoreAuth.GetToken(), StoreUser.CustomerData().Country())
                if (responsePayment) {
                    setPaymentData(responsePayment.data)
                    setLoading(false)
                }
            }
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    return (
        <ThemeProvider theme={MainTemplate}>
            <SubscriptionsStyled loading={loading ? 1 : 0}>
                {loading && <IconSpinner />}
                {!loading && orderData.length === 0 && <NoSubscriptions />}
                {!loading && orderData.length !== 0 && <ItemSubscriptions order={orderData} payment={paymentData} getOrder={getOrder} />}
            </SubscriptionsStyled>
        </ThemeProvider>
    )
})

const NoSubscriptions = observer(() => {
    const translate = useTranslate()
    const navigate = useNavigate()

    return (
        <Wrapper>
            <Stack textAlign="center" justifyContent="center" alignItems="center" height="100%" width="390px">
                <RefreshSVG />

                <Box padding="0px 50px" mt="40px" mb="40px">
                    <Box mb="20px">
                        <Typography variant="title2" mt="30px" mb="20px">
                            <T>no_subscriptions</T>
                        </Typography>
                    </Box>

                    <Box width="390px">
                        <Typography variant="caption5" textAlign={'center'} mb="40px" color="#023764">
                            <T>no_subscription_description</T>
                        </Typography>
                    </Box>
                </Box>

                <DefaultButton
                    type="submit"
                    fullWidth={true}
                    titleText={translate('buy_now')}
                    width={'344px'}
                    backgroundcolor="#003B6F"
                    fontSize="20px"
                    fontWeight="400"
                    onClick={() => navigate('/products')}
                />
            </Stack>
        </Wrapper>
    )
})

const Wrapper = styled(Box)`
    height: 766px;
    width: 100%;
    background: #ffffff;
    border-radius: 12px;
    display: flex;
    justify-content: center;
`

const SubscriptionsStyled = styled(Stack)`
    display: flex;
    justify-content: ${props => (props.loading ? 'center' : 'flex-start')};
    align-items: center;
    height: 100%;
    background: ${props => (props.loading ? 'transparent' : '#d1d9e2')};
`

const IconSpinner = styled(RefreshIcon)`
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    width: 65px;
    height: 65px;

    circle {
        stroke: #e2e8ed;
    }
    path {
        stroke: #e2e8f2;
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`

export default Subscriptions
