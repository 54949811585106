import { capitalize, get, lowerCase, upperCase, startCase, camelCase, has, isEmpty } from 'lodash'
import { makeAutoObservable, reaction, runInAction } from 'mobx'
import StoreTranslate from '../Translator/StoreTranslate'
import feelGreateAPI from './APIs'
import { AllCountry } from './CountryData'
import { liveMarkets, countriesLanguage, languageTranslations } from './Helper'
import storeAuth from 'GlobalStores/User/StoreAuth'

class StoreCountry {
    data = []
    fetching = false

    currency = {}
    // currency = {
    //     currency_code: {},
    //     currency_symbol: {}
    // }

    byRegion = {}
    countryList = []
    RawCountriesData = []
    isLoading = true
    currentCountry = {}
    bgImg = {
        desktop: '',
        mobile: ''
    }
    maintenance = false
    country = {
        country: '',
        live: '',
        maintenance_mode: false,
        native_name: '',
        roman_name: '',
        short: '',
        shorter: '',
        test: '',
        config: {
            currency: {
                currency_code: {
                    english: '',
                    native: ''
                },
                currency_symbol: {
                    english: '',
                    native: ''
                },
                full_currency_code: ''
            },
            languageSettings: {
                default: '',
                items: []
            }
        }
    }
    icon = []
    logo = {
        color: '',
        logo_url: ''
    }
    title = {
        text: '',
        color: ''
    }

    settings = {
        fsb: [],
        orderHistoryDownline: [],
        cpv: [],
        apv: [],
        avatarNeedApproval: []
    }

    genealogyFilters = {
        hide_inactive_legs: false,
        name_in_left_column: false,
        show_active_leg: true,
        show_apv: true,
        show_cpv: true,
        show_fsb: true,
        sort_by_ov: true,
        view: 'dashboard'
    }

    GA4MeasurementID = {
        allmarket: 'G-PFCKK34ZXR',
        australia: 'G-QN1DTB9V57',
        singapore: 'G-CNV3BQTGS2',
        saudiarabia: 'G-VSXJF4L6X4',
        philippines: 'G-J9LSVHHY1K',
        thailand: 'G-1YTH1BD763'
    }

    CountryLowerCase() {
        return lowerCase(get(this.country, 'country', '')).replace(/\s/g, '')
    }

    CountryCapitalize() {
        return capitalize(get(this.country, 'country', '')).replace(/\s/g, '')
    }

    CountryUpperCase() {
        return upperCase(get(this.country, 'country', '')).replace(/\s/g, '')
    }

    Country2() {
        return this.country.shorter
    }

    Country3() {
        return this.country.short
    }

    CountryNative() {
        return this.country.native_name
    }

    CountryList() {
        return this.countryList
    }

    CountryByRegion() {
        return this.byRegion
    }

    CountryRomanName() {
        return lowerCase(get(this.country, 'roman_name', '')).replace(/\s/g, '')
    }

    SetCountryList(value) {
        let countries = []
        Object.keys(value).map(key => {
            countries = countries.concat(value[key])
        })

        runInAction(() => {
            this.byRegion = value
            this.countryList = countries
        })
    }

    SetCountry(value) {
        runInAction(() => {
            this.country = value

            if (has(value.config, 'currency_v2')) {
                this.currency = value.config.currency_v2
            } else {
                this.currency = value.config.currency
            }
        })
    }

    init(data) {
        this.countryList = data.data
        this.bgImg = data.bg_img
        this.logo = data.logo
        this.icon = data.icon
        this.title = data.title
        this.settings = data.setting
        this.genealogyFilters = data.genealogy ? data.genealogy[[this.Country3()]] : this.genealogyFilters
    }

    GACode = {
        singapore: 'UA-119346265-1',
        thailand: 'UA-119346265-2',
        japan: 'UA-119346265-4',
        india: 'UA-119346265-1',
        philippines: 'UA-119346265-3',
        united_states: 'UA-119346265-1',
        bahrain: 'UA-119346265-1',
        vietnam: 'UA-119346265-6',
        indonesia: 'UA-119346265-5',
        malaysia: '',
        hongkong: '',
        australia: '',
        newzealand: '',
        taiwan: ''
    }

    GACode2021 = {
        singapore: 'UA-188539463-8',
        thailand: 'UA-188539463-2',
        japan: 'UA-188539463-9',
        india: '',
        philippines: 'UA-188539463-4',
        united_states: '',
        bahrain: '',
        vietnam: '',
        indonesia: 'UA-188539463-3',
        malaysia: 'UA-188539463-7',
        hongkong: 'UA-188539463-5',
        australia: '',
        newzealand: '',
        taiwan: 'UA-188539463-6'
    }

    FBPixelCode = {
        all: '416242290341774',
        singapore: '388467111504630',
        thailand: '301116716951336',
        japan: '388467111504630',
        india: '388467111504630',
        philippines: '388467111504630',
        united_states: '388467111504630',
        bahrain: '388467111504630',
        vietnam: '388467111504630',
        indonesia: '388467111504630',
        malaysia: '',
        australia: '',
        hongkong: '',
        newzealand: '',
        taiwan: ''
    }

    GAAdwordsCode = {
        singapore: 'AW-869476933',
        thailand: 'AW-869476933',
        japan: 'AW-869476933',
        india: 'AW-869476933',
        philippines: 'AW-869476933',
        united_states: 'AW-869476933',
        bahrain: 'AW-869476933',
        vietnam: 'AW-869476933',
        indonesia: 'AW-869476933',
        malaysia: '',
        australia: '',
        newzealand: '',
        taiwan: ''
    }

    AllowDiscountStatus = {
        AUS: ['M', 'A', 'B'],
        SGP: ['M', 'A', 'B'],
        PHL: ['M', 'A', 'B'],
        JPN: ['M', 'A', 'B', 'H'],
        HKG: ['M', 'A', 'B'],
        NZL: ['M', 'A', 'B'],
        TWN: ['M', 'A', 'B'],
        MYS: ['M', 'A', 'B'],
        THA: ['M', 'A', 'B'],
        MAR: ['M', 'A', 'B'],
        TUR: ['M', 'A', 'B'],
        JOR: ['M', 'A', 'B'],
        EGY: ['M', 'A', 'B']
    }

    inArray(needle, array) {
        return array.indexOf(needle) !== -1
    }

    ShouldShowOrderDetail(baCountry) {
        return this.inArray(baCountry, this.settings.orderHistoryDownline)
    }

    ShouldShowCpv(baCountry, baId) {
        return this.inArray(baCountry, this.settings.cpv)
    }

    ShouldShowApv(baCountry) {
        return this.inArray(baCountry, this.settings.apv)
    }

    ShouldApproveAvatar(baCountry) {
        return this.inArray(baCountry, this.settings.avatarNeedApproval)
    }

    GetSettings() {
        return this.settings
    }

    GetSetting(key) {
        return get(this.settings, key, null)
    }

    GetDefaultGenealogyFilters() {
        return this.genealogyFilters
    }

    ShouldHideTVCompressed(baCountry) {
        return this.inArray(baCountry, this.GetSetting('hideTVcompressed'))
    }

    RawCountries() {
        return AllCountry
    }

    SupportedMarketData() {
        return [
            // 'CAN',
            // 'PRI',
            // 'GBR',
            // 'USA',
            // 'IRL',
            // 'DEU',
            // 'AUT',
            // 'BEL',
            // 'CHE',
            // 'DNK',
            // 'ESP',
            // 'FRA',
            // 'HUN',
            // 'IND',
            // 'ITA',
            // 'NLD',
            // 'LUX',
            // 'NOR',
            // 'POL',
            // 'SWE',
            // 'UAE',
            'AUS',
            'NZL',
            'PHL',
            'SAU',
            'SGP',
            'TWN'
        ]
    }

    RawSupportedMarketData() {
        const keys = Object.keys(liveMarkets)

        // Raw countries with language
        const newRawCountries = this.RawCountries().map(e => {
            const langArr = countriesLanguage[e.alpha2] || []
            const language = langArr.map(e => {
                return { title: languageTranslations[e], code: e }
            })
            return {
                ...e,
                language: language
            }
        })

        // Countries data in modal format
        const rawData = keys.reduce((acc, current) => {
            const countriesKeys = Object.keys(liveMarkets[current])
            const countryData = newRawCountries.filter(e => countriesKeys.includes(e.alpha2))
            const result = {
                continent: startCase(camelCase(current)).replace('And', '&'),
                list: [...countryData]
            }
            return [...acc, result]
        }, [])
        this.RawCountriesData = rawData
    }

    async RawSupportedMarketData2() {
        try {
            const countries = this.CountryByRegion()
            const keys = Object.keys(countries)

            const rawData2 = keys.reduce((acc, current) => {
                const result = {
                    continent: current,
                    list: [...countries[current]]
                }
                return [...acc, result]
            }, [])

            this.RawCountriesData = rawData2
        } catch (error) {
            console.log(error)
        }
    }

    async GetCountriesData() {
        try {
            const response = await feelGreateAPI.getFeelGreatCountries()
            return get(response, 'data.data', {})
        } catch (error) {
            console.log('Get Countries error =>', error)
        }
    }

    GetCurrencyCode() {
        let isEng = StoreTranslate.IsEnglish() ? 'english' : 'native'
        return this.currency.currency_code[isEng]
    }

    GetCurrencySymbol() {
        let isEng = StoreTranslate.IsEnglish() ? 'english' : 'native'
        if (this.currency.currency_symbol[isEng]) {
            return this.currency.currency_symbol[isEng]
        } else {
            return false
        }
    }

    GetCountryFlag() {
        const countryData = this.CountryList().find(country => country.short === this.Country3())
        return get(countryData, 'flagUrl', '')
    }

    GetPaymentOptions() {
        return get(this.country, 'payment_options', [])
    }

    GetAllowDiscountStatus() {
        return this.AllowDiscountStatus[this.Country3()]
    }

    constructor() {
        makeAutoObservable(this)
    }
}
const storeCountry = new StoreCountry()
export default storeCountry

reaction(
    () => storeCountry.Country3(),
    (country, prev, disposer) => {
        if (!isEmpty(country.country)) {
            storeAuth.country = country
            disposer.dispose()
        }
    }
)
