import { Modal, Stack, Typography } from "@mui/material"
import { DefaultButton } from "Components/Elements/Forms"
import { T, useTranslate } from "GlobalStores/Cores/Translator/useTranslate"
import { storeElement } from "GlobalStores/StoreElement"
import { observer } from "mobx-react-lite"
import ModalConfirmDeleteAccount from "./ModalConfirmDeleteAccount"

const DeleteAccount = observer(() => {


    return (
        <Stack sx={{ pt: "10px" }}>
            <Stack sx={{ pb: "10px" }}>
                <Typography variant="h6" sx={{ color: "#5A8FC4", fontSize: '16px', fontWeight: '700', }}>
                    <T>delete_account</T>
                </Typography>
            </Stack>

            <Stack sx={{ pb: "20px" }}>
                <Typography variant="caption" component="p" sx={{ color: "#5A8FC4", fontSize: '14px', fontWeight: '400', textAlign: "start" }}>
                    <T>delete_account_description</T>
                </Typography>
            </Stack>

            <Stack>
                <ModalConfirmDeleteAccount />
            </Stack>
        </Stack>
    )
}


)
export default DeleteAccount