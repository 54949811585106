import { Backdrop, Box, Fade, Paper, Popper, styled, ThemeProvider, Typography } from '@mui/material'
import { storeElement } from 'GlobalStores/StoreElement'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { observer } from 'mobx-react-lite'
import { ReactComponent as CloseIcon } from './../Assets/close.svg'
import { ReactComponent as Explanation } from './../Assets/explanation.svg'
import React from 'react'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'

const ModalPassword = observer(({ paragraph = `` }) => {
    const [arrowRef, setArrowRef] = React.useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const poperRef = React.useRef(null)
    const [open, setOpen] = React.useState(false)

    const { width } = useSizeWindow()

    const handleClick = event => {
        setOpen(!open)
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setOpen(false)
    }

    return (
        <ThemeProvider theme={storeElement.theme}>
            <Explanation onClick={handleClick} width={'18px'} height={'18px'} style={{ cursor: 'pointer' }} />
            <Popper
                sx={{
                    bottom: '20px !important',
                    borderRadius: '8px !important',
                    border: 'none !important',
                    zIndex: 2,
                    padding: width < 600 ? '0 30px 0 0' : ''
                }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement={'top'}
                transition
                keepMounted
                modifiers={[
                    {
                        name: 'flip',
                        enabled: false,
                        options: {
                            altBoundary: true,
                            rootBoundary: 'viewport'
                        }
                    },
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            altAxis: false,
                            altBoundary: storeElement.dynamicWidth < 325 ? false : true,
                            tether: storeElement.dynamicWidth < 325 ? false : true,
                            rootBoundary: 'viewport'
                        }
                    },
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowRef
                        }
                    }
                ]}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper
                            sx={{
                                borderRadius: '8px !important',
                                width: storeElement.dynamicWidth < 550 ? 'auto' : '506px',
                                padding: '20px 30px 30px 30px',
                                backgroundColor: '#fff',
                                boxShadow: '0px 4px 18px rgba(0, 0, 0, 0.05)',
                                boxSizing: 'border-box',
                            }}>

                            <Arrow ref={setArrowRef}>
                                <Triangle />
                            </Arrow>

                            <Box
                                sx={{
                                    position: 'absolute',
                                    cursor: 'pointer',
                                    right: width <= 550 ? '40px' : '20px',
                                }}>
                                <CloseIcon onClick={() => handleClose()}/>
                            </Box>

                            <Content>
                                {/* <Box sx={{ marginBottom: '20px' }}>
                                    <Typography
                                        variant="h3"
                                        color="#0B3B61"
                                        sx={{
                                            textAlign: 'center',
                                            width: '100%',
                                            fontWeight: 600,
                                            fontSize: '20px',
                                            lineHeight: '30px'
                                        }}>
                                        After your successful payment, our system will automatically create a unique Customer ID for you.
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        variant="h3"
                                        color="#0B3B61"
                                        sx={{
                                            textAlign: 'center',
                                            width: '100%',
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            lineHeight: '24px'
                                        }}>
                                        You will use this Customer ID along with the password you set in the password field to log in to our platform. 
                                        This will enable you to track your orders, manage your subscription, and access many more features designed to enhance your shopping experience. 
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        variant="h3"
                                        color="#0B3B61"
                                        sx={{
                                            textAlign: 'center',
                                            width: '100%',
                                            fontWeight: 700,
                                            fontSize: '16px',
                                            lineHeight: '24px'
                                        }}>
                                        Please ensure you remember your password
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        variant="h3"
                                        color="#0B3B61"
                                        sx={{
                                            textAlign: 'center',
                                            width: '100%',
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            lineHeight: '24px'
                                        }}>
                                        for future logins. If you have any questions or need further assistance, feel free to contact our customer support team.
                                    </Typography>
                                </Box> */}
                                <Box>
                                    <Typography
                                        variant="h3"
                                        color="#0B3B61"
                                        sx={{
                                            textAlign: 'center',
                                            width: '100%',
                                            fontWeight: 400,
                                            fontSize: '16px',
                                            lineHeight: '24px'
                                        }}>
                                        <T>{paragraph}</T>
                                    </Typography>
                                </Box>
                            </Content>
                        </Paper>
                    </Fade>
                )}
            </Popper>

            <Backdrop sx={{ color: '#fff', zIndex: 1, cursor: 'auto !important', background: 'rgba(56, 66, 80, 0.2)' }} open={open} onClick={() => handleClose()} />
        </ThemeProvider>
    )
})

const Arrow = styled('span')`
    bottom: 5px;
    position: absolute;
    width: 20px;
    height: 10px;
`

const Triangle = styled('div')`
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 19px;
    height: 19px;
    background: #fff;
    transform: rotate(45deg);
`

const Content = styled('div')`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    font-family: 'Poppins, NotoSansThai';
    font-style: normal;
    span {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #003764;
    }

    .content-title {
        display: flex;
        flex-direction: row;
        width: '100%';
    }
`

export default ModalPassword
