import { observer } from 'mobx-react-lite'
import SettingCard from './../UserAccountMenu/SettingCard'
import OrderHistories from './OrderHistories'

import Breadcrumb from '../UserAccountMenu/Breadcrumb'
import MobileLayoutWrapper from '../UserAccountMenu/MobileLayoutWrapper'
import { storeSettingsElement } from 'GlobalStores/Settings/StoreSettingsElement';
import { Stack, ThemeProvider, styled } from '@mui/material'
import { MainTemplate } from 'Components/Template/MainTemplate'

const OrderHistoryWrapper = observer(props => {
    return (

        <ThemeProvider theme={MainTemplate}>
            <SettingCard title={storeSettingsElement.settingCard.title || ''} titleAlign={storeSettingsElement.settingCard.titleAlign || 'left'} display="block">
                <OrderHistories />
            </SettingCard>
        </ThemeProvider>
    )
})

export const OrderHistoryWrapperMobileLayout = observer(props => {
    return (
        <MobileLayoutWrapper>
            <StackMobileLayout>
                <Breadcrumb />
                {props.children}
            </StackMobileLayout>
        </MobileLayoutWrapper>
    )
})

const StackMobileLayout = styled(Stack)`
    background: #d1d9e2 !important;
    width: 100%;

`

export default OrderHistoryWrapper
