import Header from 'Components/Elements/Header/Header'
import Footer from 'Components/Elements/Footer/Footer'

import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import { storeElement } from 'GlobalStores/StoreElement'
import { reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import ResizeObserver from 'react-resize-observer'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

import { useSizeWindow } from './../Helpers/GlobalHelpers'
import UserAccount from '../Components/Pages/UserAccount'

import { useEffect, useState } from 'react'

import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { get, upperCase } from 'lodash'

import { PathHelper } from 'GlobalStores/Cores/Helpers/PathHelper'
import { MenuList } from 'Components/Elements/UserAccountMenu/CardMenu'
import storeUser from 'GlobalStores/User/StoreUser'
import StoreCheckout from 'GlobalStores/Checkout/StoreCheckout'

import axios from 'axios'
import Poc from 'Components/Pages/Poc'
import DirectIntegrationWP from 'Components/Pages/DirectIntegrateWP'
import TriggerAlwaysToTop from './TriggerAlwaysToTop'
import { UserAccoutRoutes } from './UserAccountRoutes'
import { ThaiReferralRouter } from 'Components/Pages/Referral/Thailand/ThaiReferralRouter'
import { usePeriods } from 'Services/Cores/usePeriods'

const AppRouter = observer(() => {
    const { width } = useSizeWindow()
    const [userPath, setUserPath] = useState('/user')
    const [bypassPath, setBypassPath] = useState({ isBypass: false, path: '/' })

    usePeriods()

    useEffect(() => {
        const qs = new URLSearchParams(window.location.search)
        if (qs.get('lang')) {
            StoreTranslate.SetCurrentLanguage(upperCase(qs.get('lang')))
        }
        // Bypass index page
        const isBypass = get(StoreCheckout.CheckOutInstance().GetBypassPage(), 'isBypass')
        const to = get(StoreCheckout.CheckOutInstance().GetBypassPage(), 'to')

        if (isBypass) {
            setBypassPath({ ...bypassPath, isBypass: true, path: to })
        }

        axios.defaults.headers.common['Accept-Language'] = `${StoreTranslate.CurrentLanguage().toLowerCase()}-${StoreCountry.Country2().toUpperCase()}`

        setFontFamily(StoreTranslate.CurrentLanguage())

        const disposerLanguageAxios = reaction(
            () => StoreTranslate.CurrentLanguage(),
            (n, p) => {
                if (n !== p) {
                    axios.defaults.headers.common['Accept-Language'] = `${n.toLowerCase()}-${StoreCountry.Country2().toUpperCase()}`
                    if (StoreCountry.Country3() === 'THA') {
                        setFontFamily(n)
                    }
                }
            }
        )

        return () => {
            disposerLanguageAxios()
        }
    }, [])

    const setFontFamily = currentLang => {
        const fontFamilyThai = 'NotoSansThai, Poppins'
        const fontFamilyEng = 'Poppins, NotoSansThai'
        storeElement.theme.typography.fontFamily = currentLang === 'EN' ? fontFamilyEng : fontFamilyThai
        Object.keys(storeElement.theme.typography).map((key, index) => {
            // storeElement.theme.typography[key].fontFamily = n === 'EN' ? 'Poppins' : 'NotoSansThai'

            if (storeElement.theme.typography[key].fontFamily) {
                storeElement.theme.typography[key].fontFamily = currentLang === 'EN' ? fontFamilyEng : fontFamilyThai
            }
        })
        if (storeElement.theme.overrides) {
            storeElement.theme.overrides.MuiCssBaseline['@global'].body.fontFamily = currentLang === 'EN' ? fontFamilyEng : fontFamilyThai
        } else {
            storeElement.theme.overrides = {
                MuiCssBaseline: {
                    '@global': {
                        body: {}
                    }
                }
            }
            storeElement.theme.overrides.MuiCssBaseline['@global'].body.fontFamily = currentLang === 'EN' ? fontFamilyEng : fontFamilyThai
        }
    }

    useEffect(() => {
        const baStatus = storeUser.GetBaStatus()
        if (!baStatus) return

        let JPMenuList = [MenuList[2], MenuList[1], MenuList[3]]
        const DefaultMenuList = StoreCountry.Country3() === 'JPN' ? JPMenuList : MenuList

        const filteredMenuList = DefaultMenuList.filter(item => {
            const isInvite = item.key === 'invite'
            if (baStatus !== 'H') return true
            return !isInvite
        })
        setUserPath(get(filteredMenuList, '[0].key', '/user'))
    }, [storeUser.GetBaStatus()])

    return (
        <BrowserRouter basename={`${PathHelper.BuildBasename(StoreCountry.CountryLowerCase())}`}>
            <ResizeObserver
                onResize={size => {
                    if (size.width !== storeElement.dynamicWidth) {
                        runInAction(() => {
                            storeElement.dynamicWidth = size.width
                        })
                    }
                }}
            />
            <Header />
            <Routes>
                {/* Thai Referral */}
                <Route key={'thai-referral-routes'}>
                    {ThaiReferralRouter.map(root => {
                        return (
                            <Route key={root.path}>
                                <Route path={root.path} index element={root.element} />
                                {root.children &&
                                    root.children.map(child => {
                                        return <Route key={child.path} path={`/${root.path}/${child.path}`} element={child.element} />
                                    })}
                            </Route>
                        )
                    })}
                </Route>
                {/* user menu */}
                <Route key={'user-account-routes'}>
                    <Route path={UserAccoutRoutes.path} index element={width <= 834 ? <UserAccount /> : <Navigate to={userPath} replace />} />
                    {UserAccoutRoutes.children.map(child => {
                        return <Route key={child.path} path={`/${UserAccoutRoutes.path}/${child.path}`} element={child.element} />
                    })}
                </Route>

                {/* Prove of concept */}
                <Route path={`/poc`} element={<Poc />} />
                <Route path={`/direct-wp-poc`} element={<DirectIntegrationWP />} />
                {/* user menu */}
            </Routes>
            <Footer />
            <TriggerAlwaysToTop />
        </BrowserRouter>
    )
})

export default AppRouter
