import React, { useEffect, useState } from 'react'
import { Stack, Typography, Box, FormControl, Select, MenuItem } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import StoreSubScriptions from 'GlobalStores/StoreSubScriptions'
import { DefaultButton } from 'Components/Elements/Forms'
import ShowError from './ShowError'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import { getOSName } from 'Helpers/Bowser'

const ChangeDate = observer(props => {
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState([])
    const [date, setDate] = useState('')
    const [listDate, setListDate] = useState([])

    const translate = useTranslate()

    const order = props.products

    useEffect(() => {
        setDate(props.dateOfmonth)
        setListDate(generateDaysList())
    }, [])

    const generateDaysList = () => {
        const daysInCurrentMonth = 31

        return Array.from({ length: daysInCurrentMonth }, (_, i) => {
            const day = i + 1
            let suffix

            switch (day) {
                case 1:
                    suffix = 'st'
                    break
                case 2:
                    suffix = 'nd'
                    break
                case 3:
                    suffix = 'rd'
                    break
                default:
                    suffix = 'th'
            }

            return { text: `${day}${suffix} ${translate('of_th_month')}`, value: day }
        })
    }

    const handleChange = event => {
        setDate(event.target.value)
    }

    const handleSubmit = async () => {
        setLoading(true)
        setErrorMessage([])
        try {
            const productItems = order.lines.items.map(data => {
                return {
                    item_code: data.item.id.unicity,
                    qty: data.quantity,
                    price: data.terms.priceEach
                }
            })

            const dateOfmonth = {
                schedule: {
                    dateStarts: order.recurrence.dateStarts,
                    dayOfMonth: date,
                    month: order.recurrence.schedule.month
                }
            }

            let recurrence = order.recurrence
            if (order.recurrence.dateStarts) {
                recurrence.schedule = dateOfmonth.schedule
            }

            const newData = {
                autoship_href: order.href,
                autoship_number: order.id.unicity.toString(),
                ba_id: order.customer.id.unicity.toString(),
                market: order.market,
                items: JSON.stringify(productItems),
                shippingMethod_href: order.shippingMethod.href,
                shipToAddress: order.shipToAddress,
                shipToName: order.shipToName,
                shipToPhone: order.shipToPhone,
                shipToEmail: order.shipToEmail,
                shipment_options: 'delivery',
                recurrence: recurrence,
                transactions: order.transactions,
                medium: 'Internet',
                agent: 'uFeelGreat',
                platform: getOSName(),
                isUpdateDate: true
            }

            let res = await FeelGreatAPI.EditSubscriptions(newData, StoreAuth.GetToken())

            if (res.data.success) {
                props.handleClose()
                props.handleGetOrder()
                setLoading(false)
            }
        } catch (error) {
            console.error(error)
            setLoading(false)
            setErrorMessage([error.response.data.message.error.message])
        }
    }

    const ITEM_HEIGHT = 64
    const ITEM_PADDING_TOP = 8
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    }

    const SelectCustom = {
        display: 'flex',
        height: '50px',
        '.MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E5EEF7'
        },
        '.MuiOutlinedInput-input.MuiSelect-select': {
            minHeight: '63px',
            display: 'flex',
            alignItems: 'center'
        },
        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E5EEF7'
        },
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E5EEF7'
        },

        '@media (max-width: 428px)': {
            '.MuiOutlinedInput-input.MuiSelect-select': {
                minHeight: '39px'
            }
        }
    }

    return (
        <Stack gap="30px">
            <Typography variant="title2">
                <T>change_ship_date</T>
            </Typography>

            <Stack >
                <Typography variant="caption4" color="#003B6F" fontWeight={400}>
                    <T>day_of_month</T>
                </Typography>
                <FormControl sx={{ width: '100%', marginTop: '30px', marginBottom: '20px' }}>
                    <Select displayEmpty value={date} onChange={handleChange} MenuProps={MenuProps} sx={SelectCustom}>
                        {listDate.map((item, index) => (
                            <MenuItem key={index} value={item.value}>
                                <Typography variant="caption4" color="#003B6F" fontWeight={400}>
                                    {item.text}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Typography variant="label" color="#5A8FC4" fontWeight={300}>
                    <T>day_of_month_caption</T>
                </Typography>
            </Stack>

            {errorMessage.length > 0 && <ShowError list={errorMessage} noTranslate={true} />}

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                <Box onClick={() => props.handleClose()}>
                    <Typography variant="link" color="#000000" fontSize="16px">
                        <T>cancel</T>
                    </Typography>
                </Box>
                <DefaultButton
                    type="submit"
                    fullWidth={true}
                    titleText={translate('save')}
                    width="220px"
                    onClick={() => handleSubmit()}
                    isloading={loading.toString()}
                    fontSize="20px"
                    fontWeight="400"
                    disabled={loading}
                />
            </Box>
        </Stack>
    )
})

export default ChangeDate
