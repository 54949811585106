import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import OrderHistoryLists from './OrderHistoryList'
import OrderHistoryDetails from './OrderHistoryDetails'
import { Box, Skeleton, Stack, styled, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { runInAction } from 'mobx'
import { useOrderHistory } from 'Services/FeelGreat/useOrderHistory'
import { ReactComponent as RefreshIcon } from './Assets/RefreshIcon.svg'
import { ReactComponent as OrderEmptyIcon } from './Assets/OrderEmptyIconOrange.svg'
import { DefaultButton } from '../Forms'
import { T, useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import { useSizeWindow } from 'Helpers/GlobalHelpers'
import { storeSettingsElement } from 'GlobalStores/Settings/StoreSettingsElement'

const OrderHistories = observer(() => {
    const { order_id } = useParams()
    const [data, error, loading] = useOrderHistory()
    // const [loading] = useState(true)
    const [totalListData, setTotalListData] = useState(4)
    const { width } = useSizeWindow()
    const [showEmptyDataHistory, setShowEmptyDataHistory] = useState(false)

    const dataSlice = data.slice(0, totalListData)
    const dataOrderRef = data.find(item => item.id.unicity.split('-')[1] === order_id)

    useEffect(() => {
        runInAction(() => {
            storeSettingsElement.settingCard.title = <T>order_history</T>
            storeSettingsElement.cardTitle.titleStep1 = <T>order_history</T>
            storeSettingsElement.settingCard.titleAlign = 'left'
            storeSettingsElement.settingCard.padding = width > 834 ? '50px 30px 20px 30px' : '30px 20px 40px 20px'
        })

        return () => {
            runInAction(() => {
                storeSettingsElement.settingCard.title = ''
                storeSettingsElement.cardTitle.titleStep1 = ''
                storeSettingsElement.settingCard.padding = ''
            })
        }
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setShowEmptyDataHistory(true)
        }, 1000)
    }, [])

    return (
        <OrderHistoriesStyled hasdata={Object.keys(data).length > 0 ? 1 : 0} loading={loading ? 1 : 0}>
            {/* loading */}
            {loading && order_id === undefined && <SkeletonLoading />}

            {/* no order */}
            {!loading && Object.keys(data).length === 0 && showEmptyDataHistory && <ErrorEmptyOrderHistoryList />}

            {/* order list */}
            {!loading && order_id === undefined && (
                <Stack width={'100%'}>
                    {dataSlice.map(item => {
                        return <OrderHistoryLists key={item.id.unicity.split('-')[1]} item={item} />
                    })}
                    {Object.keys(data).length !== 0 && (
                        <Typography
                            variant="link"
                            mt="20px"
                            sx={{ color: '#003764', opacity: '0.7', textAlign: 'center', cursor: 'pointer', lineHeight: '24px' }}
                            onClick={() => setTotalListData(totalListData + totalListData)}>
                            <T>load_previous</T>
                        </Typography>
                    )}
                </Stack>
            )}

            {/* order detail */}
            {!loading && order_id !== undefined && (
                <Stack width={'100%'} height="100%">
                    <OrderHistoryDetails dataOrderRef={dataOrderRef} />
                </Stack>
            )}
        </OrderHistoriesStyled>
    )
})

const ErrorEmptyOrderHistoryList = observer(() => {
    const translate = useTranslate()
    const navigate = useNavigate()
    const { width } = useSizeWindow()

    useEffect(() => {
        runInAction(() => {
            storeSettingsElement.settingCard.titleAlign = 'center'
        })

        return () => {
            runInAction(() => {
                storeSettingsElement.settingCard.titleAlign = ''
            })
        }
    })

    return (
        <Stack alignItems={'center'} mb={'50px'} padding={width > 520 ? '0 50px' : ''}>
            <Box mb="30px" mt="80px">
                <OrderEmptyIcon />
            </Box>
            <Stack>
                <Typography variant="h5" fontSize="24px" fontWeight={600} color="#023764" textAlign="center" mb="20px">
                    <T>Order_history_empty</T>
                </Typography>
                <Typography variant="caption" fontSize="18px" color="#023764" textAlign="center" mb="40px">
                    <T>orderhistory_subscriptions_empty</T>
                </Typography>
            </Stack>
            <DefaultButton titleText={translate('buy_now')} onClick={() => navigate('/products')} width="324px" backgroundcolor="#003B6F" />
        </Stack>
    )
})

const SkeletonLoading = observer(() => {
    return (
        <Stack width={'100%'} gap={'20px'}>
            <Skeleton variant="rounded" width={'auto'} height={89} />
            <Skeleton variant="rounded" width={'auto'} height={89} />
            <Skeleton variant="rounded" width={'auto'} height={89} />
            <Skeleton variant="rounded" width={'auto'} height={89} />
            <Skeleton variant="text" sx={{ fontSize: '30px', width: '50%', margin: '0 auto' }} />
        </Stack>
    )
})

const OrderHistoriesStyled = styled(Stack)`
    display: flex;
`

export default OrderHistories
