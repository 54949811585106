/* Customer MODEL */

import { get, isEmpty, last } from 'lodash'
import { makeAutoObservable, toJS } from 'mobx'
import StoreAuth from './StoreAuth'
import { getNativeName } from 'Helpers/GlobalHelpers'

import StoreUser from './StoreUser'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import { isThaiAlphabet, isThaiWord } from 'Helpers/StringHelpers'

const inArray = (needle, arrayList) => {
    let inArray = arrayList.filter(arrKey => needle === arrKey)
    return inArray.length ? true : false
}

class Customer {
    userData = null
    enrollData = {}
    sponsorData = {}

    enrollerName = ''
    sponsorName = ''

    constructor(userData) {
        this.userData = userData

        if (this.userData) {
            if (this.userData.id.unicity !== StoreAuth.GetId()) {
                this.TriggerSetSelfToEnrollerSponsor()
            }
        }

        makeAutoObservable(this)
    }

    GetNativeName(obj, nameType) {
        var fullName = ''
        var nativeName = ''

        let checkNative = /@/
        Object.keys(obj).forEach(function (key) {
            var value = obj[key]

            if (checkNative.test(key)) {
                nativeName = value
            } else {
                nativeName = false
                if (key === 'firstName' || key === 'lastName') {
                    fullName += value
                } else if (key === 'fullName') {
                    fullName = value
                }
            }
        })

        if (nameType === 'fullName') {
            return fullName
        } else if (nameType === 'nativeName') {
            if (nativeName === false || nativeName === '') {
                return fullName
            } else {
                return nativeName
            }
        } else {
            if (nativeName === '') {
                return fullName
            } else {
                if (nativeName === false || nativeName === '') {
                    return fullName
                } else {
                    return nativeName
                }
            }
        }
    }

    Rank() {
        const rank = get(this.userData, 'metricsProfileHistory.aggregate.cumulativeMetricsProfile.highestRankShort', '').toLowerCase()

        return rank === 'pc' ? 'dst' : rank
    }

    IsVip() {}

    get CustomerId() {
        return get(this.userData, 'id.unicity', null)
    }

    Email() {
        return get(this.userData, 'email', null)
    }

    FullName() {
        return this.userData ? this.GetNativeName(this.userData.humanName, 'fullName') : null
    }

    NativeName() {
        return this.userData ? this.GetNativeName(this.userData.humanName, 'nativeName') : null
    }

    Name() {
        return StoreTranslate.CurrentLanguage() === 'EN' ? this.FullName() : this.NativeName()
    }

    PreferredName() {
        return get(this.userData, 'preferredName', null)
    }

    MainAddress() {
        return get(this.userData, 'mainAddress', null)
    }

    MobilePhone() {
        return get(this.userData, 'mobilePhone', null)
    }

    AchievementsHistory() {
        return get(this.userData, 'achievementsHistory', null)
    }

    Sponsor() {
        return get(this.userData, 'sponsor', null)
    }

    Enroller() {
        return get(this.userData, 'enroller', null)
    }

    EnrollerId() {
        const enroller = this.Enroller()

        return enroller ? enroller.id.unicity : null
    }
    SponsorId() {
        const sponsor = this.Sponsor()

        return sponsor ? sponsor.id.unicity : null
    }

    MetricProfile() {}

    IsCPWE() {
        const fullAccess = ['Associate', 'Pending']
        //['Customer', 'WholesaleCustomer', 'PreferredCustomer', 'Employee']
        if (inArray(this.Type(), fullAccess)) {
            return false
        } else {
            return true
        }
    }

    Type() {
        let t = get(this.userData, 'type', null)
        if (t) {
            if (t === 'Employee') {
                if (get(this.userData, 'employmentDetails.employerName', null)) {
                    t = get(this.userData, 'employmentDetails.employerName', null)
                }
            }
        }

        return t
    }

    Status() {
        return get(this.userData, 'status', null)
    }

    Country() {
        return get(this.userData, 'mainAddress.country', null)
    }

    get status() {
        return this.Status()
    }

    ProfilePicture(type) {
        if (type) {
            let media = ''
            let picture = get(this.userData, 'profilePicture', null)
            if (picture) {
                media = picture.sizes.find(item => item.size === type)
                if (media) {
                    return media.media
                } else {
                    return ''
                }
            }
        } else {
            return get(this.userData, 'profilePicture', null)
        }
    }

    JoinDate() {
        let joinDate = get(this.userData, 'joinDate', null)

        if (joinDate) {
            return joinDate.split('T')[0]
        } else {
            return null
        }
    }

    JoinPeriod() {
        return this.JoinDate().slice(0, -3)
    }

    DisplayName() {
        const preferredName = this.PreferredName()
        if (preferredName && preferredName.firstName && preferredName.lastName) {
            return `${preferredName?.firstName} ${preferredName?.lastName}`
        } else {
            return this.Name()
        }
    }

    HumanName() {
        return get(this.userData, 'humanName', null)
    }

    Href() {
        return get(this.userData, 'href', '')
    }

    InitialsFullName() {
        // do the initials of fullname
        let initials = ''

        let firstName = get(this.userData, 'humanName.firstName', '')
        let lastName = get(this.userData, 'humanName.lastName', '')

        let firstCharOfFirstName = ''
        let firstCharOfLastName = ''

        const isFirstNameThai = isThaiWord(firstName)
        const isLastNameThai = isThaiWord(lastName)

        if (isFirstNameThai && isLastNameThai) {
            firstCharOfFirstName = firstName[this.GetPositionOfFirstThaiAlphabet(firstName)]
            firstCharOfLastName = lastName[this.GetPositionOfFirstThaiAlphabet(lastName, firstCharOfFirstName)]
        } else if (isFirstNameThai && !isLastNameThai) {
            firstCharOfFirstName = firstName[this.GetPositionOfFirstThaiAlphabet(firstName)]
            firstCharOfLastName = firstName[this.GetPositionOfFirstThaiAlphabet(firstName, firstCharOfFirstName)]
        } else if (!isFirstNameThai && isLastNameThai) {
            firstCharOfFirstName = firstName.charAt(0)
            firstCharOfLastName = firstName.charAt(1)
        } else {
            firstCharOfFirstName = firstName.charAt(0)
            firstCharOfLastName = lastName.charAt(0)
        }

        if (firstName.length === 0 && lastName.length === 0) {
            if (this.FullName()) {
                let fullname = toJS(this.FullName())
                if (isThaiWord(fullname)) {
                    let firstCharFromFullName = fullname[this.GetPositionOfFirstThaiAlphabet(fullname)]
                    let nextCharFromFirstFullName = ''
                    if (isThaiAlphabet(fullname[this.GetPositionOfFirstThaiAlphabet(fullname)])) {
                        nextCharFromFirstFullName = fullname[this.GetPositionOfFirstThaiAlphabet(fullname, firstCharFromFullName)]
                    }
                    initials = `${firstCharFromFullName}${nextCharFromFirstFullName}`
                } else {
                    initials = `${fullname.charAt(0)}${fullname.charAt(1)}`
                }
            }
        } else {
            initials = `${firstCharOfFirstName}${firstCharOfLastName}`
        }

        return initials
    }

    GetPositionOfFirstThaiAlphabet(word, charBefore) {
        let position = -1
        for (let i = 0; i < word.length; i++) {
            if (isThaiAlphabet(word[i])) {
                if (!charBefore) {
                    position = i
                    break
                } else {
                    if (charBefore !== word[i]) {
                        position = i
                        break
                    }
                }
            }
        }

        return position
    }

    // async FetchEnrollerData() {
    //     if (this.EnrollerId() !== StoreAuth.GetId()) {
    //         if (this.EnrollerName(false) === null) {
    //             const response = await GetTinyDataCustomer(this.EnrollerId())

    //             this.enrollData = get(response.data, 'items[0]')
    //             const humanName = get(response.data, 'items[0].humanName')

    //             this.SetToSession(this.EnrollerId(), humanName)
    //         }
    //     }
    // }

    // async FetchSponsorData() {
    //     if (this.SponsorId() !== StoreAuth.GetId()) {
    //         if (this.SponsorName(false) === null) {
    //             const response = await GetTinyDataCustomer(this.SponsorId())
    //             this.sponsorData = get(response.data, 'items[0]')
    //             const humanName = get(response.data, 'items[0].humanName', null)
    //             this.SetToSession(this.SponsorId(), humanName)
    //         }
    //     }
    // }

    SetToSession(childId, value) {
        const storageKey = `${StoreAuth.GetId()}-enroller-sponsor`
        let sessionData = this.GetEnrollerSponsorStorage()
        if (sessionData) {
            if (sessionData[childId] === undefined) {
                sessionData[childId] = {
                    enroller: JSON.stringify(value),
                    sponsor: JSON.stringify(value)
                }
            }

            sessionStorage.setItem(storageKey, JSON.stringify(sessionData))
        } else {
            sessionData = {
                [`${childId}`]: {
                    enroller: JSON.stringify(value),
                    sponsor: JSON.stringify(value)
                }
            }
            sessionStorage.setItem(storageKey, JSON.stringify(sessionData))
        }
    }

    EnrollerName(native = false) {
        if (this.EnrollerId() === StoreAuth.GetId()) {
            return StoreUser.CustomerData().GetNativeName(StoreUser.CustomerData().HumanName(), native ? 'fullName' : 'nativeName')
        } else {
            let sessionData = this.GetEnrollerSponsorStorage()
            if (sessionData && get(sessionData[this.EnrollerId()], 'enroller', null)) {
                let data = JSON.parse(sessionData[this.EnrollerId()]['enroller'])
                if (data) {
                    return getNativeName(data, native ? 'nativeName' : 'fullName')
                }
            }
        }

        return null
    }

    SponsorName(native = false) {
        if (this.SponsorId() === StoreAuth.GetId()) {
            return StoreUser.CustomerData().GetNativeName(StoreUser.CustomerData().HumanName(), native ? 'fullName' : 'nativeName')
        } else {
            let sessionData = this.GetEnrollerSponsorStorage()
            if (sessionData && get(sessionData[this.SponsorId()], 'sponsor', null)) {
                let data = JSON.parse(sessionData[this.SponsorId()]['sponsor'])
                if (data) {
                    return getNativeName(data, native ? 'nativeName' : 'fullName')
                }
            }
        }

        return null
    }

    GetEnrollerSponsorStorage() {
        const storageKey = `${StoreAuth.GetId()}-enroller-sponsor`
        let sessionData = sessionStorage.getItem(storageKey)
        if (sessionData) {
            sessionData = JSON.parse(sessionData)
        }

        return sessionData
    }

    TriggerSetSelfToEnrollerSponsor() {
        let sessionData = this.GetEnrollerSponsorStorage()
        if (sessionData) {
            if (sessionData[this.CustomerId] === undefined) {
                sessionData[this.CustomerId] = {
                    enroller: JSON.stringify(this.HumanName()),
                    sponsor: JSON.stringify(this.HumanName())
                }
            }

            sessionStorage.setItem(`${StoreAuth.GetId()}-enroller-sponsor`, JSON.stringify(sessionData))
        } else {
            sessionData = {
                [`${this.CustomerId}`]: {
                    enroller: JSON.stringify(this.HumanName()),
                    sponsor: JSON.stringify(this.HumanName())
                }
            }
            sessionStorage.setItem(`${StoreAuth.GetId()}-enroller-sponsor`, JSON.stringify(sessionData))
        }
    }

    GetUserStatus() {
        let ushopDistStatus = ''
        if (this.userData) {
            let status = get(this.userData, 'status')
            let type = get(this.userData, 'type')

            Object.keys(UserDescriptions).map(userStatus => {
                if (UserDescriptions[userStatus].api.type === type && UserDescriptions[userStatus].api.status === status) {
                    ushopDistStatus = userStatus
                }
                return ''
            })
        }

        return ushopDistStatus
    }
}

const UserDescriptions = {
    A: {
        description: 'Active',
        api: {
            type: 'Associate',
            status: 'Active',
            priceLevel: 'wholesale_price'
        }
    },
    B: {
        description: 'Pending',
        api: {
            type: 'Associate',
            status: 'Pending',
            priceLevel: 'wholesale_price'
        }
    },
    C: {
        description: 'Customer',
        api: {
            type: 'Customer',
            status: 'Active',
            priceLevel: 'retail_price'
        }
    },
    D: {
        description: 'Balance (CHE, DEU), Defective (JP)',
        api: {
            type: 'Associate',
            status: 'Defective',
            priceLevel: null
        }
    },
    E: {
        description: 'Employee',
        api: {
            type: 'Employee',
            status: 'Active',
            priceLevel: 'employee_price'
        }
    },
    F: {
        description: 'Family Pricing',
        api: {
            type: 'Family',
            status: 'Active',
            priceLevel: 'employee_price'
        }
    },
    H: {
        description: 'Wholesale Customer',
        api: {
            type: 'WholesaleCustomer',
            status: 'Active',
            priceLevel: 'wholesale_price'
        }
    },
    L: {
        description: 'Performance Labs',
        api: {
            type: 'Performance Labs',
            status: 'Active',
            priceLevel: 'wholesale_price'
        }
    },
    P: {
        description: 'Preferred Customer',
        api: {
            type: 'PreferredCustomer',
            status: 'Active',
            priceLevel: 'preferred_price'
        }
    },
    R: {
        description: 'Retired',
        api: {
            type: 'Associate',
            status: 'Retired',
            priceLevel: null
        }
    },
    S: {
        description: 'Suspended',
        api: {
            type: 'Associate',
            status: 'Suspended',
            priceLevel: null
        }
    },
    T: {
        description: 'Terminated',
        api: {
            type: 'Associate',
            status: 'Terminated',
            priceLevel: null
        }
    }
}

export default Customer
