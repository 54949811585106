import {  ThemeProvider } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { useEffect } from 'react'
import { runInAction } from 'mobx'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'

import './Invite.scss'
import { storeSettingsElement } from 'GlobalStores/Settings/StoreSettingsElement'
import { storeElement } from 'GlobalStores/StoreElement'
import ReferralLinks from './ReferralLinks/ReferralLinks'

const Invite = observer(props => {
    useEffect(() => {
        runInAction(() => {
            storeSettingsElement.cardTitle.titleStep1 = <T>invite</T>
            storeSettingsElement.settingCard.title = ''
            storeSettingsElement.settingCard.padding = ''
        })

        return () => {
            runInAction(() => {
                storeSettingsElement.cardTitle.titleStep1 = ''
                storeSettingsElement.settingCard.padding = ''
            })
        }
    }, [])

    return (
        <ThemeProvider theme={storeElement.theme}>
            <ReferralLinks />
        </ThemeProvider>
    )
})

export default Invite
